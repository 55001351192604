<template>
  <div class="select-filter">
    <autocomplete-selector
      v-model="selectOption"
      :label="placeholder"
      endpoint="products"
      filter-name="full_name"
      item-text="full_name"
      item-value="id"
    />
  </div>
</template>

<script>

import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'

export default {
  name: 'ProductsFilter',
  components: {
    AutocompleteSelector
  },
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      selectOption: ''
    }
  },
  watch: {
    selectOption () {
      const option = this.selectOption || ''
      this.$emit('filter', option, this.filterName)
    }
  }
}
</script>


<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>

