<template>
  <div class="select-filter">
    <v-select
      v-model="selectOption"
      :items="allOptions"
      :label="placeholder"
      class="filter-select"
      clearable
      dense
      hide-details
      item-text="text"
      item-value="value"
      outlined
      single-line
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'SimpleSelectFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    },
    options: {
      required: true,
      type: [Array, Function]
    }
  },
  data () {
    return {
      selectOption: ''
    }
  },
  computed:{
    allOptions(){
      if(Array.isArray(this.options)){
        return this.options;
      }

      if(typeof this.options === 'function'){
        return this.options();
      }

    }
  },
  watch: {
    selectOption () {

      const value = this.selectOption || ''
      this.$emit('filter', value, this.filterName)

    }
  }
}
</script>

<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>

