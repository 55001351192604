<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Agent implicit end-user</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-select
          v-model="default_public_agent"
          :items="agents"
          item-text="full_name"
          item-value="id"
          outlined
          dense
          hide-details
          label="Selecteaza din lista agentul implicit pentru distributie"
          single-line
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Agent implicit distributie</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-select
          v-model="default_reseller_agent"
          :items="agents"
          item-text="full_name"
          outlined
          dense
          hide-details
          item-value="id"
          label="Selecteaza din lista agentul implicit pentru B2B"
          single-line
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'defaultAgent',
  data () {
    return {
      default_public_agent: 0,
      default_reseller_agent: 0
    }
  },
  computed: {
    agents () {
      return this.$store.getters['agents/data']
    }
  },
  methods: {
    getAgentSettings () {
      this.$http.get('settings?settings[]=default_public_agent&settings[]=default_reseller_agent')
        .then(({data}) => {
          this.default_public_agent = parseInt(data.default_public_agent)
          this.default_reseller_agent = parseInt(data.default_reseller_agent)
        })
    },
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'default_public_agent',
            value: this.default_public_agent
          },
          {
            key: 'default_reseller_agent',
            value: this.default_reseller_agent
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(() => {
          this.$store.dispatch('notify', {
            title: 'Eroare!',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          }, {root: true})
        })
    }
  },
  mounted () {
    if (!this.$store.getters['agents/isLoaded']) {
      this.$store.dispatch('agents/loadItems')
    }
    this.getAgentSettings()
  }
}
</script>
