<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(filterType, index) in filterTypes" :key="index">
      <v-expansion-panel-header>
        {{ filterType.label }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <component :is="`${filterType.type}-filter`" :filter-type="filterType" :store-name="'featuredSpecsFilters/addNewFilter'"></component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import BaseProductFilter from '@/views/ProductManagement/Categories/components/Filters/BaseProductFilter'
import ComponentFilter from '@/views/ProductManagement/Categories/components/Filters/ComponentFilter'
import AttributeFilter from '@/views/ProductManagement/Categories/components/Filters/AttributeFilter'

export default {
  name: 'FeaturedSpecsFiltersComponentTypeSelect',
  components: {
    AttributeFilter,
    BaseProductFilter,
    ComponentFilter
  },
  data () {
    return {
      filterTypes: []
    }
  },
  props: {
    categoryId: {
      required: true
    }
  },
  mounted () {
    this.$http.get(`product-categories/${this.categoryId}/featured-specs/types`)
      .then(({data}) => {
        console.log(data)
        this.filterTypes = data
      })
  }
}
</script>

<style scoped>

</style>
