<template>
  <action-add-new action-url="clients/private-clients" button-label="Adauga Persoana fizica">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.first_name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.first_name"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.last_name" dense hide-details label="Prenume" outlined/>
        <error-messages :errors="errors.last_name"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.phone" dense hide-details label="Nr. Telefon" outlined/>
        <error-messages :errors="errors.phone"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.email" dense hide-details label="Email" outlined type="email"/>
        <error-messages :errors="errors.email"/>
      </div>

      <div class="mb-4">
        <autocomplete-selector v-model="form.zone_id" endpoint="location/zones" label="Zona"/>
        <error-messages :errors="errors.zone_id"/>
      </div>

      <div class="mb-4">
        <county-selector v-model="form.county_id" :include-all="true" hide-details label="Judetul" outlined/>
        <error-messages :errors="errors.county_id"/>
      </div>

      <div class="mb-4">
        <city-selector v-model="form.city_id" :county="form.county_id" dense hide-details label="Localitatea" outlined/>
        <error-messages :errors="errors.city_id"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ZoneSelector from '@/components/autocompletes/ZoneSelector'
import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'
import CountySelector from '@/components/autocompletes/CountySelector'
import CitySelector from '@/components/autocompletes/CitySelector'

export default {
  components: {
    CitySelector,
    CountySelector,
    AutocompleteSelector,
    ZoneSelector,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
