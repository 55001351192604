<template>
 <span class="button-wrapper">
   <vs-tooltip text="Valori">
    <vs-button
      v-on="listeners" :to="to"
      color="dark" icon="fa-calculator-alt"
      icon-pack="far"
      type="flat"
    >
      <slot></slot>
    </vs-button>
   </vs-tooltip>
  </span>
</template>

<script>
export default {
  name: 'AttributeButton',
  props: {
    to: {
      default: false,
      type: String | Object
    }
  },
  methods: {},
  computed: {
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>


<style scoped>
.button-wrapper {
  @apply inline-block
}
</style>

