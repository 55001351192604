<template>
  <v-autocomplete
    v-model="selectOption"
    :disabled="disabled"
    :items="componentTypes"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    cache-items
    class="filter-select text-sm"
    clearable
    dense
    hide-details
    item-text="heading_one"
    item-value="id"
    outlined
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {},
    label: {
      default: 'Categorie'
    },
    disabled: {
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paginatedData: [],
      loading: false,
      search: ''
    }
  },
  computed: {
    componentTypes () {
      return this.paginatedData
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (this.multiple) {
          val = val || []
        } else {
          val = val || ''
        }
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/product-categories?filter[name]=${search}&page_size=all`)
        .then(({data}) => {
          this.paginatedData = data.data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
