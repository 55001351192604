<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="['customer']"
      resource-path="order-vouchers"
    >

      <template v-slot:actions>
        <create-form/>
      </template>

      <template v-slot:item.actions="{item}">

        <action-delete :action-url="`order-vouchers/${item.id}`"/>

        <edit-form :item="item"/>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import EditForm from './Edit'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'COD',
          value: 'code',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Nume Voucher',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Valoare',
          value: 'value',
          sortable: false
        },
        {
          text: 'Este suma fixa?',
          value: 'is_fixed_value',
          sortable: false,
          filterType: 'boolean',
          display: 'boolean',
          width: '120',
          align: 'center'
        },
        {
          text: 'Nominal',
          value: 'customer.client_display_name',
          sortable: false
        },
        {
          text: 'Data creare',
          value: 'created_at',
          sortable: true,
          display: 'date'
        }

      ]
    }
  }
}
</script>
