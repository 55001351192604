<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="reseller-websites"
    >

      <template v-slot:actions>
        <create-form/>
      </template>


      <template v-slot:item.actions="{item}">

        <action-delete :action-url="`reseller-websites/${item.id}`" v-if="item.is_deletable"/>

        <edit-form :item="item"/>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import EditForm from './Edit'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'image_url',
          display: 'image',
          sortable: true
        },
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Pret',
          value: 'price',
          sortable: false,
          width: '150'
        },
        {
          text: 'Valabil din',
          value: 'start_at',
          sortable: true,
          display: 'date-no-time',
          width: '150'
        },
        {
          text: 'Valabil pana la',
          value: 'end_at',
          sortable: true,
          display: 'date-no-time',
          width: '150'
        },
        {
          text: 'Activ la vanzare',
          value: 'is_active',
          filterType: 'boolean',
          display: 'boolean',
          width: '150'
        }
      ]
    }
  }
}
</script>
