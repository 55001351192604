<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <div class="mb-5">

          <v-text-field v-model="form.name" label="Nume"/>
          <error-messages :errors="errors.name"></error-messages>
        </div>
      </div>
      <div class="mb-5">
        <attribute-group-selector v-model="form.group_id"/>
        <error-messages :errors="errors.group_id"></error-messages>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import lodash from 'lodash'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'
import AttributeGroupSelector from '@/components/autocompletes/AttributeGroupSelector'

export default {
  name: 'EditBaseProductAttribute',
  components: {
    ProductCategorySelector,
    AttributeGroupSelector,
    ErrorMessages
  },
  data () {
    return {
      form: {
        name: '',
        product_category_id: '',
        group_id: ''
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('attributes/updateItem', {
        id: this.form.id,
        form: this.form
      })
        .catch(({response}) => {
          this.errors = response.data.errors
        })
    }
  },
  mounted () {
    const item = this.$store.getters['attributes/getItemForEdit']
    this.form = lodash.cloneDeep(item)
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
