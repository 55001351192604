<template>
  <div>
    <div class="my-6">
      <vs-input
        v-model="newComponent.name"
        class="w-full"
        label="Nume"
      />
      <error-messages :errors="errors.name"/>

      <vs-select v-model="newComponent.dependency" autocomplete label="Componenta">
        <vs-select-item v-for="(item,index) in components" :key="index"
                        :text="item.name + ' (stock: ' + item.stock + ', ' + item.price + ' Lei)'" :value="item"/>
      </vs-select>
      <error-messages :errors="errors.dependency"/>

      <vs-input
        v-model="newComponent.quantity"
        class="w-full"
        label="Numar de componente"
      />
      <error-messages :errors="errors.price"/>
      <vs-input
        v-model="newComponent.price"
        class="w-full"
        label="Pret vanzare"
      />
      <error-messages :errors="errors.price"/>
    </div>
    <div class="mb-6">
      <div><strong>Rezumat:</strong></div>
      <ul>
        <li><strong>Nume:</strong> {{ finalNewComponent.name }}</li>
        <li><strong>Pret Vanzare:</strong> {{ finalNewComponent.price }}</li>
        <li><strong>Pret Achizitie:</strong> {{ finalNewComponent.buying_price }}</li>
        <li><strong>Stoc:</strong> {{ finalNewComponent.stock }}</li>
      </ul>
    </div>
    <div>
      <vs-button class="mr-6" @click="createComponent" :disabled="loading">Salveaza</vs-button>
      <vs-button color="danger" type="border" @click="cancel" :disabled="loading">Anuleaza</vs-button>
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddNewMultipleComponent',
  components: {ErrorMessages},
  props: {
    componentType: {
      required: true
    },
    components: {
      required: true
    }
  },
  data () {
    return {
      loading:false,
      newComponent: {
        name: '',
        price: '',
        component_type_id: '',
        dependency: '',
        quantity: 1
      },
      errors: {}
    }
  },
  computed: {
    finalNewComponent () {
      return {
        name: this.newComponent.name,
        price: this.price,
        buying_price: this.buyingPrice,
        stock: this.stock,
        component_type_id: this.componentType.id,
        dependency: this.dependency,
        quantity: this.newComponent.quantity
      }
    },
    dependency () {
      if (typeof this.newComponent.dependency.id !== 'undefined') {
        return this.newComponent.dependency.id
      }
      return ''
    },
    quantity () {
      if (typeof this.newComponent.quantity !== 'undefined' && this.newComponent.quantity > 0) {
        return parseInt(this.newComponent.quantity)
      }
      return 1

    },
    stock () {
      if (typeof this.newComponent.dependency.stock !== 'undefined') {
        return parseInt(this.newComponent.dependency.stock / this.quantity)
      }
      return 0
    },
    buyingPrice () {
      if (typeof this.newComponent.dependency.buying_price !== 'undefined') {
        return this.newComponent.dependency.buying_price * this.quantity
      }
      return 0
    },
    price () {
      if (this.newComponent.price) {
        return this.newComponent.price
      }
      if (typeof this.newComponent.dependency.price !== 'undefined') {
        const firstBP = parseFloat(this.newComponent.dependency.price)
        return (firstBP * this.quantity)
      }
      return 0
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
      this.newComponent = {
        name: '',
        price: '',
        buying_price: '',
        dependencies: []
      }
    },
    createComponent () {
      this.loading = true
      this.$http.post('product-components/multiple', this.finalNewComponent)
        .then(({data}) => {
          this.$emit('created', data)
          this.cancel()
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: error.message,
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })

    }
  }
}
</script>
