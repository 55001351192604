<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :resource-path="`activity/customer/${customerId}`"
      disable-actions
      hide-filters
    >

      <template v-slot:item.values="{item}">

        <div v-if="item.type === 'customer_finished_order'">
          <customer-finished-order :values="item.values"/>
        </div>
        <div v-if="item.type === 'customer_was_contacted'">
          <customer-was-contacted :values="item.values"/>
        </div>
        <div v-if="item.type === 'customer_type_changed'">
          <customer-type-changed :values="item.values"/>
        </div>
        <div v-if="item.type === 'customer_purchased_theme'">
          <customer-purchased-theme :values="item.values"/>
        </div>

      </template>
    </cit-data-table>
  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import CustomerFinishedOrder from '@/views/SingleClient/components/ActivityHistory/CustomerFinishedOrder'
import CustomerWasContacted from '@/views/SingleClient/components/ActivityHistory/CustomerWasContacted'
import CustomerTypeChanged from '@/views/SingleClient/components/ActivityHistory/CustomerTypeChanged'
import CustomerPurchasedTheme from '@/views/SingleClient/components/ActivityHistory/CustomerPurchasedTheme'

export default {
  components: {
    CustomerPurchasedTheme,
    CustomerTypeChanged,
    CustomerFinishedOrder,
    CustomerWasContacted,
    CitDataTable
  },
  props: {
    customerId: {
      required: true
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Tip Activitate',
          value: 'label',
          width: '200',
          sortable: false
        },
        {
          text: 'Info',
          value: 'values',
          sortable: false
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          width: '150'
        }
      ]
    }
  }
}
</script>
