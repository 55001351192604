<template>
  <v-dialog v-model="dialog" width="500">

    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" large outlined>
        <v-icon>fal fa-plus</v-icon>
        <span class="ml-2">Adauga Pachet produse</span>
      </v-btn>
    </template>


    <v-card>

      <loader :loading="loading"/>

      <v-card-title>
        Adauga un pachet de produse
      </v-card-title>

      <v-card-text>

        <v-text-field v-model="form.name" class="w-full" label="Nume" placeholder="eg. Pachet 10 x Dell E403"/>
        <error-messages :errors="errors.name"/>

        <v-text-field v-model="form.price" class="w-full" label="Pret" placeholder="eg. 1400" type="number"/>
        <error-messages :errors="errors.price"/>


        <v-row v-for="(item, index) in form.items" :key="index">
          <v-col cols="3">
            <v-text-field v-model="form.items[index].quantity" class="w-full" label="Cantitate" placeholder="eg. 1" type="number"/>
          </v-col>
          <v-col cols="7">
            <product-select v-model="form.items[index].product_id"/>
          </v-col>
          <v-col align-self="center" cols="2">
            <v-btn color="error" icon @click="deleteItem(index)">
              <v-icon>
                fal fa-minus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <error-messages :errors="errors['items.' + index + '.quantity']"/>
            <error-messages :errors="errors['items.' + index + '.product_id']"/>
          </v-col>
        </v-row>

        <v-row align="end">
          <v-btn color="success" icon @click="newItem">
            <v-icon>fal fa-plus</v-icon>
          </v-btn>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-btn text @click="closeDialog">
          Anulare
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">
          Creaza
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import Loader from '@/components/Loader'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductSelect from '@/views/ProductManagement/Categories/components/ProductSelect'

export default {
  name: 'ProductBundleCreator',
  components: {
    ProductSelect,
    ErrorMessages,
    Loader
  },
  data () {
    return {
      dialog: false,
      loading: false,
      form: {
        name: '',
        price: '',
        items: [
          {
            product_id: '',
            quantity: ''
          }
        ]
      },
      errors: {}
    }
  },
  methods: {
    save () {
      this.errors = {}
      this.$http.post('product-bundles', this.form)
        .then(() => {
          this.$vs.notify({
            'title': 'Succes',
            'text': 'Produsul pachet a for adaugat cu succes!',
            'color': 'success'
          })
          this.$emit('created')
          this.closeDialog()
        }).catch(({response}) => {
        if (response.status === 422) {
          this.errors = response.data.errors
          this.$vs.notify({
            'title': 'Eroare',
            'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
            'color': 'danger'
          })
        } else {
          this.$vs.notify({
            'title': 'Eroare',
            'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
            'color': 'danger'
          })
        }
      })
    },
    newItem () {
      this.form.items.push({
        product_id: '',
        quantity: ''
      })
    },
    deleteItem (index) {
      this.form.items.splice(index, 1)
    },
    closeDialog () {
      this.reInitForm()
      this.dialog = false
    },
    reInitForm () {
      this.form = {
        name: '',
        price: '',
        items: [
          {
            product_id: '',
            quantity: ''
          }
        ]
      }
    }
  }
}
</script>
