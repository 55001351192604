var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"appends":[

    ],"headers":_vm.headers,"includes":[
      'customer',
      'theme'
    ],"disable-actions":"","resource-path":"reseller-websites/purchases"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('status-changer',{attrs:{"item":item}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }