<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      min-width="290px"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="text-filter">
          <v-text-field
            v-model="dates"
            v-bind="attrs"
            v-on="on"
            class="text-sm date-select"
            clearable
            dense
            hide-details
            label="Filtreaza"
            outlined
            readonly
            single-line
            @click:clear.prevent="clearAndFilter"
          ></v-text-field>
        </div>
      </template>
      <v-date-picker v-model="dates" color="#60a629" no-title range scrollable>
        <v-btn text color="red" @click="clearAndFilter">Sterge</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="disableFilter" color="primary" @click="filterRange">Filtreaza</v-btn>
      </v-date-picker>
    </v-menu>

  </div>
</template>

<script>
export default {
  name: 'DateRangeFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      menu: false,
      dates: []
    }
  },
  methods: {
    clearAndFilter () {
      this.dates = []
      this.filter()
    },
    filterRange () {
      this.filter()
    },
    filter () {
      this.$emit('filter', this.dateRangeText, this.filterName)
      this.menu = false
    }
  },
  computed: {
    dateRangeText () {
      if (this.disableFilter) {
        return ''
      }
      return this.dates.join('|')
    },
    disableFilter () {
      if (this.dates) {
        return this.dates.length !== 2
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.text-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-input__append-inner {
      display: none !important;
    }

    .v-text-field__slot {
      font-weight: 400 !important;
      font-size: 10px !important;

      .v-label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
      margin-top: -5px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
