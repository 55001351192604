<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Cod AWB</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="awb_code"
                dense
                hide-details="auto"
                label="Introdu codul AWB"
                outlined
              ></v-text-field>
              <error-messages :errors="errors.awb_code"/>
            </v-col>
            <v-col>
              <v-btn color="primary" elevation="2" @click="saveAwb">Salveaza</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="order.awb_code">
            <v-col>
              <a :href="order.awb_url" target="_blank">Link tracking</a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ErrorMessages from '../../../components/general-form/ErrorMessages'

export default {
  name: 'OrderAwbCode',
  components: {ErrorMessages},
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      awb_code: '',
      errors: {}
    }
  },
  methods: {
    saveAwb () {
      this.errors = {}
      this.$store.dispatch('startLoading')
      this.$http.patch(`orders/${this.order.id}/awb-code`, {awb_code: this.awb_code})
        .then(() => {
          this.$vs.notify({
            title: 'Succes',
            text: 'Codul AWB a fost adaugat cu succes',
            color: 'success'
          })
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    }
  },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value.awb_code) {
          this.awb_code = JSON.parse(JSON.stringify(value.awb_code))
        }
      }
    }
  }
}
</script>
