import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/',
                active: true
              }
            ],
            pageTitle: 'Dashboard'
          }
        },
        {
          path: '/products/bundles',
          name: 'products-bundles',
          component: () => import('./views/ProductBundles/ProductBundles'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Oferte pachet',
                active: true
              }
            ],
            pageTitle: 'Oferte pachet'
          }
        },
        {
          path: '/category/:categoryId',
          name: 'products-category',
          component: () => import('./views/Products/ListProduct'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Produse',
                active: true
              }
            ],
            pageTitle: 'Produse'
          }
        },
        {
          path: '/category/:categoryId/configurators',
          name: 'products-category-configurators',
          component: () => import('./views/Products/ProductConfigurators'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Configuratoare',
                active: true
              }
            ],
            pageTitle: 'Configuratoare'
          }
        },
        {
          path: '/category/:categoryId/generate',
          name: 'generate-product',
          component: () => import('./views/ProductGenerator/ProductsGenerator'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Generator de Produse',
                active: true
              }
            ],
            pageTitle: 'Generator de Produse'
          }
        },
        {
          path: '/category/:categoryId/:productConfigId',
          name: 'edit-generate-product',
          component: () => import('./views/ProductGenerator/ProductsGeneratorEdit'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Editare - Generator de Produse',
                active: true
              }
            ],
            pageTitle: 'Editare - Generator de Produse'
          }
        },
        {
          path: '/product-management/brands',
          name: 'pm-brands',
          component: () => import('./views/ProductManagement/Brands/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Branduri',
                active: true
              }
            ],
            pageTitle: 'Branduri'
          }
        },
        {
          path: '/product-management/product-reviews',
          name: 'pm-product-reviews',
          component: () => import('./views/ProductManagement/ProductReviews/ProductReviewsList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Reviews',
                active: true
              }
            ],
            pageTitle: 'Reviews'
          }
        },
        {
          path: '/product-management/feeds',
          name: 'pm-feeds',
          component: () => import('./views/ProductManagement/Feeds/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed-uri',
                active: true
              }
            ],
            pageTitle: 'Feed-uri'
          }
        },
        {
          path: '/product-management/qualities',
          name: 'pm-qualities',
          component: () => import('./views/ProductManagement/Qualities/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Calitati',
                active: true
              }
            ],
            pageTitle: 'Calitati'
          }
        },
        {
          path: '/product-management/categories',
          name: 'pm-categories',
          component: () => import('./views/ProductManagement/Categories/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Categorii',
                active: true
              }
            ],
            pageTitle: 'Categorii'
          }
        },
        {
          path: '/product-management/categories/:id/filters',
          name: 'pm-categories-category-filters',
          component: () => import('./views/ProductManagement/Categories/CategoryFilters/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Categorii',
                url: '/product-management/categories'
              },
              {
                title: 'Filtre categorii',
                active: true
              }
            ],
            pageTitle: 'Categorii'
          }
        },

        {
          path: '/product-management/categories/:id/banners',
          name: 'pm-category-banners',
          component: () => import('./views/ProductManagement/Categories/Banners/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Categorii',
                url: '/product-management/categories'
              },
              {
                title: 'Bannere',
                active: true
              }
            ],
            pageTitle: 'Bannere'
          }
        },

        {
          path: '/product-management/categories/:id/featured-specs',
          name: 'pm-featured-specs-filters',
          component: () => import('./views/ProductManagement/Categories/FeaturedSpecsFilters'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Categorii',
                url: '/product-management/categories'
              },
              {
                title: 'Filtre specs',
                active: true
              }
            ],
            pageTitle: 'Categorii'
          }
        },
        {
          path: '/product-management/extra-warranties',
          name: 'pm-extra-warranties',
          component: () => import('./views/ProductManagement/ExtraWarranties/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Extra Garantii',
                active: true
              }
            ],
            pageTitle: 'Extra Garantii'
          }
        },
        {
          path: '/product-management/product-attribute-groups',
          name: 'pm-product-attribute-groups',
          component: () => import('./views/ProductManagement/AttributeGroups/AttributeGroupList')
        },
        {
          path: '/product-management/base-products',
          name: 'pm-base-products',
          component: () => import('./views/ProductManagement/BaseProduct/BaseProducts'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Produse de baza',
                active: true
              }
            ],
            pageTitle: 'Produse de baza'
          }
        },
        {
          path: '/product-management/component-types',
          name: 'pm-component-types',
          component: () => import('./views/ProductManagement/ComponentTypes/ListComponentType'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Tipuri de componente',
                active: true
              }
            ],
            pageTitle: 'Tipuri de componente'
          }
        },
        {
          path: '/product-management/components',
          name: 'pm-components',
          component: () => import('./views/ProductManagement/Components/ListComponent'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Componente',
                active: true
              }
            ],
            pageTitle: 'Componente'
          }
        },
        {
          path: '/product-management/components/:id/attributes',
          name: 'pm-component-attributes',
          component: () => import('./views/ProductManagement/Components/ComponentAttributes'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Componente',
                url: '/product-management/components'
              },
              {
                title: 'Atribute',
                active: true
              }
            ],
            pageTitle: 'Atribute'
          }
        },
        {
          path: '/product-management/product-attributes',
          name: 'pm-product-attributes',
          component: () => import('./views/ProductManagement/Attributes/AttributeList')
        },
        {
          path: '/product-management/attributes/:id/values',
          name: 'pm-attributes-values',
          component: () => import('./views/ProductManagement/Attributes/AttributeValues/AttributeValuesList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Atribute',
                active: true
              }
            ],
            pageTitle: 'Atribute'
          }
        },


        {
          path: '/feed-connectors/nod',
          name: 'feed-connectors-nod',
          component: () => import('./views/FeedConnectors/Nod/NodConnector'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                active: true
              }
            ],
            pageTitle: 'Feed Nod'
          }
        },
        {
          path: '/feed-connectors/nod/integration/:categoryId',
          name: 'feed-connectors-nod-integration',
          component: () => import('./views/FeedConnectors/Nod/NodIntegrations/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Integrari',
                active: true
              }
            ],
            pageTitle: 'Integrari'
          }
        },
        {
          path: '/feed-connectors/nod/attributes/:categoryId',
          name: 'feed-connectors-nod-attributes',
          component: () => import('./views/FeedConnectors/Nod/NodAttributes/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Attribute',
                active: true
              }
            ],
            pageTitle: 'Attribute'
          }
        },
        {
          path: '/feed-connectors/nod/attributes/:categoryId/:attributeId',
          name: 'feed-connectors-nod-attributes-values',
          component: () => import('./views/FeedConnectors/Nod/NodAttributesValues/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Attribute',
                active: true
              }
            ],
            pageTitle: 'Attribute'
          }
        },
        {
          path: '/feed-connectors/nod/categories',
          name: 'feed-connectors-nod-categories',
          component: () => import('./views/FeedConnectors/Nod/NodCategories/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Categorii',
                active: true
              }
            ],
            pageTitle: 'Categorii'
          }
        },
        {
          path: '/feed-connectors/nod/brands',
          name: 'feed-connectors-nod-brands',
          component: () => import('./views/FeedConnectors/Nod/NodBrands/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Branduri',
                active: true
              }
            ],
            pageTitle: 'Branduri'
          }
        },
        {
          path: '/feed-connectors/nod/logs',
          name: 'feed-connectors-nod-logs',
          component: () => import('./views/FeedConnectors/Nod/NodLogs/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Loguri',
                active: true
              }
            ],
            pageTitle: 'Loguri'
          }
        },
        {
          path: '/feed-connectors/nod/faults',
          name: 'feed-connectors-nod-faults',
          component: () => import('./views/FeedConnectors/Nod/NodFaults/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feed Nod',
                url: '/feed-connectors/nod'
              },
              {
                title: 'Defecte',
                active: true
              }
            ],
            pageTitle: 'Defecte'
          }
        },

        {
          path: '/feed-connectors/generals',
          name: 'feed-connectors-generals',
          component: () => import('./views/FeedConnectors/Generals/SplashPage'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feeduri generale',
                active: true
              }
            ],
            pageTitle: 'Feeduri generale'
          }
        },
        {
          path: '/feed-connectors/generals/:categoryId',
          name: 'feed-connectors-generals-category',
          component: () => import('./views/FeedConnectors/Generals/Integrations/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feeduri generale',
                active: true
              }
            ],
            pageTitle: 'Feeduri generale'
          }
        },

        {
          path: '/feed-connectors/generals/:categoryId/:integrationId',
          name: 'feed-connectors-generals-category-integration',
          component: () => import('./views/FeedConnectors/Generals/Integrations/Rows/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Feeduri generale',
                active: 'feed-connectors/generals/:categoryId'
              },
              {
                title: 'Produs',
                active: true
              }
            ],
            pageTitle: 'Feeduri generale'
          }
        },

        {
          path: '/reports/sales',
          name: 'reports-sales',
          component: () => import('./views/Reports/Sales/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Rapoarte',
                active: true
              }
            ],
            pageTitle: 'Vanzari'
          }
        },
        {
          path: '/reports/customer-types',
          name: 'reports-customer-types',
          component: () => import('./views/Reports/CustomerType/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Rapoarte',
                active: true
              }
            ],
            pageTitle: 'Grupe de clienti'
          }
        },
        {
          path: '/reports/products',
          name: 'reports-products',
          component: () => import('./views/Reports/Products/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Rapoarte',
                active: true
              }
            ],
            pageTitle: 'Produse'
          }
        },
        {
          path: '/reports/product-categories',
          name: 'reports-product-categories',
          component: () => import('./views/Reports/ProductCategories/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Rapoarte',
                active: true
              }
            ],
            pageTitle: 'Categorii de produse'
          }
        },
        {
          path: '/reports/zones',
          name: 'reports-zones',
          component: () => import('./views/Reports/Zones/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Rapoarte',
                active: true
              }
            ],
            pageTitle: 'Zone'
          }
        },
        {
          path: '/reports/wishlist',
          name: 'reports-wishlist',
          component: () => import('./views/Reports/Wishlist/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Rapoarte',
                active: true
              }
            ],
            pageTitle: 'Wishlist'
          }
        },

        {
          path: '/users/',
          name: 'users',
          component: () => import('./views/Users/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Users',
                active: true
              }
            ],
            pageTitle: 'Utilizatori'
          }
        },
        {
          path: '/users/:id',
          name: 'user-activity',
          component: () => import('./views/Users/SingleUser/ActivityHistory'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Users',
                active: true
              }
            ],
            pageTitle: 'Activitate User/Agent'
          }
        },
        {
          path: '/settings/locations/county-zones/',
          name: 'settings-locations-county-zones',
          component: () => import('./views/Settings/LocationsManagement/CountyZones/List')
        },
        {
          path: '/settings/locations/countries/',
          name: 'settings-locations-countries',
          component: () => import('./views/Settings/LocationsManagement/Countries/List')
        },
        {
          path: '/settings/locations/counties/',
          name: 'settings-locations-counties',
          component: () => import('./views/Settings/LocationsManagement/Counties/List')
        },
        {
          path: '/settings/locations/cities/',
          name: 'settings-locations-cities',
          component: () => import('./views/Settings/LocationsManagement/Cities/List')
        },
        {
          path: '/settings/portfolio-customers/',
          name: 'settings-portfolio-customers',
          component: () => import('./views/Settings/PortfolioCustomers/PortfolioCustomersList')
        },
        {
          path: '/settings/website-reseller-themes',
          name: 'settings-website-reseller-themes',
          component: () => import('./views/ResellerWebsiteThemeManagement/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Management de teme',
                active: true
              }
            ],
            pageTitle: 'Management de teme'
          }
        },
        {
          path: '/settings/customer-interest-list/',
          name: 'settings-customer-interest-list',
          component: () => import('./views/Settings/ClientInterestList/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Liste de interes',
                active: true
              }
            ],
            pageTitle: 'Liste de interes'
          }
        },
        {
          path: '/settings/homepage/',
          name: 'homepage-settings',
          component: () => import('./views/Settings/HomepageSettings/index'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Homepage',
                active: true
              }
            ],
            pageTitle: 'Setari Homepage'
          }
        },
        {
          path: '/settings/page-contact',
          name: 'page-contact-settings',
          component: () => import('./views/Settings/PageContactSettings/index'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Pagina Contact',
                active: true
              }
            ],
            pageTitle: 'Setari Pagina Contact'
          }
        },
        {
          path: '/settings/page-account',
          name: 'page-account-settings',
          component: () => import('./views/Settings/PageAccountSettings/index'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Contul meu',
                active: true
              }
            ],
            pageTitle: 'Setari Contul meu'
          }
        },
        {
          path: '/settings/page-reseller',
          name: 'page-reseller-settings',
          component: () => import('./views/Settings/PageResellerSettings/index'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Pagina distributie',
                active: true
              }
            ],
            pageTitle: 'Setari Pagina distributie'
          }
        },
        {
          path: '/settings/page-it',
          name: 'page-it-settings',
          component: () => import('./views/Settings/PageItSettings/index'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Pagina IT-ist',
                active: true
              }
            ],
            pageTitle: 'Setari Pagina IT-ist'
          }
        },
        {
          path: '/settings/banner-management/',
          name: 'banner-management',
          component: () => import('./views/Settings/BannerManagement/bannerList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Bannere',
                active: true
              }
            ],
            pageTitle: 'Management Bannere'
          }
        },
        {
          path: '/settings/banner-management/addNewBanner',
          name: 'addNewBanner',
          component: () => import('./views/Settings/BannerManagement/addBanner'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Management Bannere',
                url: '/settings/banner-management'
              },
              {
                title: 'Adauga banner',
                active: true
              }
            ],
            pageTitle: 'Adauga banner'
          }
        },
        {
          path: '/settings/banner-management/:id',
          name: 'banner-management-edit',
          component: () => import('./views/Settings/BannerManagement/editBanner'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Management Bannere',
                url: '/settings/banner-management'
              },
              {
                title: 'Editare banner',
                active: true
              }
            ],
            pageTitle: 'Editare Banner'
          }
        },
        {
          path: '/settings/client-group-management/',
          name: 'settings-client-group-management',
          component: () => import('./views/Settings/ClientTypeManagement/ClientTypeList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Grupuri de clienti',
                active: true
              }
            ],
            pageTitle: 'Grupuri de clienti'
          }
        },

        {
          path: '/settings/settings/',
          name: 'settings-general-settings',
          component: () => import('./views/Settings/GeneralSettings/index'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Setari Rapide',
                active: true
              }
            ],
            pageTitle: 'Setari Rapide'
          }
        },

        {
          path: '/settings/form-submissions-statuses/',
          name: 'settings-form-submissions-statuses',
          component: () => import('./views/Settings/FormSubmissionsStatuses/ListFormSubmissionsStatus'),
          meta: {
            pageTitle: 'Status formulare'
          }
        },
        {
          path: '/settings/reseller-discounts/',
          name: 'settings-reseller-discounts',
          component: () => import('./views/Settings/ResellerDiscounts/List'),
          meta: {
            pageTitle: 'Praguri de discount'
          }
        },
        {
          path: '/settings/customer-benefits/',
          name: 'settings-customer-benefits',
          component: () => import('./views/Settings/CustomerBenefits/List'),
          meta: {
            pageTitle: 'Bonusuri / Scoring'
          }
        },
        {
          path: '/settings/order-vouchers/',
          name: 'settings-order-vouchers',
          component: () => import('./views/Settings/OrderVouchers/List'),
          meta: {
            pageTitle: 'Voucher'
          }
        },
        {
          path: '/settings/page-management/',
          name: 'settings-page-management',
          component: () => import('./views/PageManagement/PageList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Pagini',
                active: true
              }
            ],
            pageTitle: 'Pagini'
          }
        },
        {
          path: '/settings/page-management/new',
          name: 'settings-page-management-new',
          component: () => import('./views/PageManagement/AddPage'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Pagini',
                url: '/settings/page-management'
              },
              {
                title: 'Adauga pagina noua',
                active: true
              }
            ],
            pageTitle: 'Adauga pagina noua'
          }
        },
        {
          path: '/settings/page-management/:page',
          name: 'settings-page-management-edit',
          component: () => import('./views/PageManagement/EditPage'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Pagini',
                url: '/settings/page-management'
              },
              {
                title: 'Editeaza pagina',
                active: true
              }
            ],
            pageTitle: 'Editeaza pagina'
          }
        },
        {
          path: '/clients/end-users/private',
          name: 'clients-end-users-private',
          component: () => import('./views/Clients/PrivateClients/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Clienti End-User',
                active: true
              }
            ],
            pageTitle: 'Persoane Fizice'
          }
        },
        {
          path: '/loggin/:clientType',
          name: 'loggin',
          component: () => import('./views/Loggin/LogginList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Toate Loginurile',
                url: '/loggin/all'
              },
              {
                title: 'Loginuri',
                active: true
              }
            ],
            pageTitle: 'Loginuri'
          }
        },
        {
          path: '/carts/:clientType',
          name: 'carts',
          component: () => import('./views/Carts/CartList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Toate Cosurile',
                url: '/carts/all'
              },
              {
                title: 'Cosuri',
                active: true
              }
            ],
            pageTitle: 'Cosuri'
          }
        },
        {
          path: '/clients/end-users/company',
          name: 'clients-end-users-company',
          component: () => import('./views/Clients/CompanyClients/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Clienti Juridice',
                active: true
              }
            ],
            pageTitle: 'Persoane Juridice'
          }
        },
        {
          path: '/clients/resellers',
          name: 'clients',
          component: () => import('./views/Clients/ResellerClients/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Distributie',
                active: true
              }
            ],
            pageTitle: 'Distributie'
          }
        },
        {
          path: '/clients/it',
          name: 'clients-it',
          component: () => import('./views/Clients/ItClients/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Clienti It',
                active: true
              }
            ],
            pageTitle: 'IT-st'
          }
        },
        {
          path: '/clients/public',
          name: 'clients-public',
          component: () => import('./views/Clients/PublicClients/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Institutii publice',
                active: true
              }
            ],
            pageTitle: 'Institutii publice'
          }
        },

        {
          path: '/clients/old-cit-resellers',
          name: 'old-cit-clients-resellers',
          component: () => import('./views/ClientsOldResellers/OldCitResellersList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Clienti vechi de Distributie',
                active: true
              }
            ],
            pageTitle: 'Clienti vechi de Distributie'
          }
        },
        {
          path: '/clients/old-cit-resellers/:id',
          name: 'old-cit-clients-resellers-show',
          component: () => import('./views/ClientsOldResellers/OldCitResellersShow'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Clienti vechi de Distributie',
                url: '/clients/old-cit-resellers'
              },
              {
                title: 'Detalii cient',
                active: true
              }
            ],
            pageTitle: 'Clienti vechi de Distributie'
          }
        },
        {
          path: '/job-batches',
          name: 'job-batches',
          component: () => import('./views/Jobs/ListJobs'),
          meta: {
            pageTitle: 'Joburi'
          }
        },
        {
          path: '/clients/:id',
          name: 'view-client',
          component: () => import('./views/SingleClient/View')
        },
        {
          path: '/ClientsCompany/:id',
          name: 'ClientsCompany-page',
          component: () => import('./views/Page')
        },

        {
          path: '/orders/reseller-website-themes',
          name: 'orders-reseller-website-themes',
          component: () => import('./views/Orders/ThemeOrders/List'),
          meta: {
            pageTitle: 'Comenzi Achizitii teme site reseller'
          }
        },

        {
          path: '/orders/resellers/:status',
          name: 'orders-resellers',
          component: () => import('./views/Orders/OrderListResellers'),
          meta: {
            pageTitle: 'Comenzi Distributie'
          }
        },
        {
          path: '/orders/end-users/:status',
          name: 'orders-end-users',
          component: () => import('./views/Orders/OrderListEndUsers'),
          meta: {
            pageTitle: 'Comenzi End Users'
          }
        },
        {
          path: '/orders/end-users-company/:status',
          name: 'orders-end-users-company',
          component: () => import('./views/Orders/OrderListEndUsersCompany'),
          meta: {
            pageTitle: 'Comenzi Companie'
          }
        },
        {
          path: '/orders/it/:status',
          name: 'orders-it',
          component: () => import('./views/Orders/OrderListIt'),
          meta: {
            pageTitle: 'Comenzi IT-st'
          }
        },
        {
          path: '/orders/public/:status',
          name: 'orders-public',
          component: () => import('./views/Orders/OrderListPublic'),
          meta: {
            pageTitle: 'Comenzi Institutii publice'
          }
        },
        {
          path: '/orders/orders-all/:status',
          name: 'orders-all',
          component: () => import('./views/Orders/AllOrders'),
          meta: {
            pageTitle: 'Toate Comenzile'
          }
        },

        {
          path: '/orders/testimonials',
          name: 'orders-testimonials',
          component: () => import('./views/Testimonials/TestimonialsList')
        },
        {
          path: '/orders',
          redirect: {name: 'orders-resellers'}
        },
        {
          path: '/orders/:id',
          name: 'orders-page',
          component: () => import('./views/Orders/OrderPage'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Detalii comanda',
                active: true
              }
            ],
            pageTitle: 'Comanda'
          }
        },
        {
          path: '/voucher/requests/',
          name: 'voucher-requests',
          component: () => import('./views/Voucher/PaymentRequestList')
        },
        {
          path: '/voucher/log/',
          name: 'voucher-log',
          component: () => import('./views/Voucher/VoucherLogList')
        },
        {
          path: '/activity',
          name: 'activity',
          component: () => import('./views/Activity/ActivityList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Activitati',
                active: true
              }
            ],
            pageTitle: 'Activitati'
          }
        },
        {
          path: '/activity/forms/:form',
          name: 'activity-forms',
          component: () => import('./views/ActivityForms/ActivityFormsList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Activitati',
                url: '/activity'
              },
              {
                title: 'Formulare',
                active: true
              }
            ],
            pageTitle: 'Activitati'
          }
        },
        {
          path: '/roles',
          name: 'roles',
          component: () => import('./views/Roles/RolesList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Roluri',
                active: true
              }
            ],
            pageTitle: 'Roluri'
          }
        },
        {
          path: '/permissions',
          name: 'permissions',
          component: () => import('./views/Roles/PermissionsList'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Permisiuni',
                active: true
              }
            ],
            pageTitle: 'Permisiuni'
          }
        },
        {
          path: '/task-manager/:filter',
          name: 'task-manager',
          component: () => import('./views/TaskManagement/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Taskuri',
                active: true
              }
            ],
            pageTitle: 'Taskuri'
          }
        },


        {
          path: '/newsletter',
          name: 'newsletter',
          component: () => import('./views/Newsletter/Newsletter/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Newsletter',
                active: true
              }
            ],
            pageTitle: 'Newsletter'
          }
        },
        {
          path: '/newsletter/send',
          name: 'send-newsletter',
          component: () => import('./views/Newsletter/Newsletter/Create'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Newsletter',
                url: '/newsletter'
              },
              {
                title: 'Trimite newsletter',
                active: true
              }
            ],
            pageTitle: 'Trimite Newsletter'
          }
        },
        {
          path: '/newsletter-templates',
          name: 'newsletter-templates',
          component: () => import('./views/Newsletter/TemplateManagement/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Newsletter',
                url: '/newsletter'
              },
              {
                title: 'Sabloane',
                active: true
              }
            ],
            pageTitle: 'Sabloane'
          }
        },

        {
          path: '/newsletter-subscribers',
          name: 'newsletter-subscribers',
          component: () => import('./views/Newsletter/Subscribers/List'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Newsletter',
                url: '/newsletter'
              },
              {
                title: 'Inscrisi newsletter',
                active: true
              }
            ],
            pageTitle: 'Inscrisi newsletter'
          }
        },
        {
          path: '/chat',
          name: 'chat',
          component: () => import('./views/Chat/List'),
          meta: {
            pageTitle: 'Chat'
          }
        },
        // User Guide

        {
          path: '/guide/product-management',
          name: 'user-guide-product-management',
          component: () => import('./views/UserGuide/ProductManagement/index'),
          meta: {
            pageTitle: 'Manual Utilizare - Management produse'
          }
        },
        {
          path: '/guide/settings',
          name: 'user-guide-settings',
          component: () => import('./views/UserGuide/Settings/index'),
          meta: {
            pageTitle: 'Manual Utilizare - Setari'
          }
        },
        {
          path: '/guide/newsletter',
          name: 'user-guide-newsletter',
          component: () => import('./views/UserGuide/Newsletter/index'),
          meta: {
            pageTitle: 'Manual Utilizare - Newsletter'
          }
        },

        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        }
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/app/auth/set-password/:user',
          name: 'welcome',
          component: () => import('@/views/pages/SetPassword.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})


router.beforeEach((to, from, next) => {
  // redirect to login page if user is not logged in and trying to access a restricted page
  const publicPages = ['login', 'welcome']
  const authRequired = !publicPages.includes(to.name)
  const loggedIn = localStorage.getItem('user-token')

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})

export default router
