<template>
 <span>
 <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="green"
          icon
          @click="markAsComplete"
        >
            <v-icon x-small>fal fa-check-square</v-icon>
        </v-btn>
      </template>
      <span>Marcheaza task ca finalizat</span>
    </v-tooltip>

  </span>
</template>

<script>
export default {
  inject: ['table'],
  name: 'Approve',
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  methods: {
    markAsComplete () {
      this.table.startLoading()
      this.$http.patch(`task-management/tasks/${this.task.id}/mark-as-complete`)
        .then(() => {
          this.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            'title': 'Eroare',
            'text': 'Eroare la marcare, va rugam reimprospatati pagina sau incercati mai tarziu',
            'color': 'danger'
          })
          this.table.stopLoading()
        })
    }
  }
}
</script>
