<template>
  <action-add-new action-url="reseller-websites" button-label="Adauga tema noua" @cancel="clearFile" @saved="clearFile">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.price" dense hide-details label="Pret" outlined type="number"/>
        <error-messages :errors="errors.price"/>
      </div>

      <div class="mb-4">
        <date-picker v-model="form.start_at" dense hide-details label="Valabil din data" outlined/>
        <error-messages :errors="errors.start_at"/>
      </div>

      <div class="mb-4">
        <date-picker v-model="form.end_at" dense hide-details label="Valabil pana in data" outlined/>
        <error-messages :errors="errors.end_at"/>
      </div>

      <div class="mb-4">
        <v-switch v-model="form.is_active" :false-value="false" :true-value="true" dense hide-details
                  label="Tema este activa"
                  outlined/>
        <error-messages :errors="errors.is_active"/>
      </div>

      <div class="mb-4">
        <base64-file-uploader
          ref="image"
          v-model="form.image"
          label="Imagine demo"
        />
        <error-messages :errors="errors.image"/>
      </div>

      <div class="mb-4">
        <base64-file-uploader
          ref="archive"
          v-model="form.archive"
          accept=".zip"
          label="Arhiva cu tema"
          prepend-inner-icon="mdi-folder-zip-outline"
        />
        <error-messages :errors="errors.archive"/>
      </div>


    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    Base64FileUploader,
    ErrorMessages,
    ActionAddNew
  },

  methods: {
    clearFile () {
      this.$refs.image.clearFile()
      this.$refs.archive.clearFile()
    }
  }
}
</script>
