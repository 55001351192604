<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="form-submissions/statuses"
    >

      <template v-slot:actions>
        <create-form-submissions-status/>
      </template>

      <template v-slot:item.actions="{item}">
        <action-delete :action-url="`form-submissions/statuses/${item.id}`"/>
        <edit-form-submissions-status :item="item"/>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import CreateFormSubmissionsStatus from '@/views/Settings/FormSubmissionsStatuses/CreateFormSubmissionsStatus'
import EditFormSubmissionsStatus from '@/views/Settings/FormSubmissionsStatuses/EditFormSubmissionsStatus'

export default {
  name: 'ListFormSubmissionsStatus',
  components: {
    EditFormSubmissionsStatus,
    CreateFormSubmissionsStatus,
    ActionAddNew,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          filterType: 'text',
          width: '100',
          sortable: true
        },
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          align: 'center',
          width: '140',
          sortable: true
        }
      ]
    }
  }

}
</script>
