<template>
 <span>
   <v-dialog v-model="dialogIsOpened" max-width="350px">
      <v-card>
        <v-card-title class="text-center">
          Esti sigur ca doresti sa reactivezi acest element?
        </v-card-title>

        <v-card-actions>
          <v-btn text @click="dialogIsOpened=false">Anuleaza</v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="confirmRestore">Reactiveaza</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="green"
          icon
          @click="actionButtonClicked"
        >
            <v-icon x-small>fal fa-trash-restore-alt</v-icon>
        </v-btn>
      </template>
      <span>Reactiveaza</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'ActionRestore',
  inject: ['table'],
  props: {
    withoutConfirmation: {
      default: false,
      type: Boolean
    },
    actionUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialogIsOpened: false
    }
  },
  methods: {
    actionButtonClicked () {
      if (this.withoutConfirmation) {
        this.confirmRestore()
      } else {
        this.dialogIsOpened = true
      }
    },
    confirmRestore () {
      this.dialogIsOpened = false
      this.table.startLoading()

      this.$http.post(this.actionUrl)
        .then(() => {
          this.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            'title': 'Eroare',
            'text': 'Eroare la restaurare, va rugam reimprospatati pagina sau incercati mai tarziu',
            'color': 'danger'
          })
          this.table.stopLoading()
        })
    }
  }
}
</script>
