<template>
  <v-edit-dialog large @open="open" @save="save">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          {{ customer.meta_data.wished_products_from_cit }}
          <div v-show="customer.meta_data.wished_products_from_cit">
            <v-icon x-small>fal fa-edit</v-icon>
          </div>
          <div v-show="!customer.meta_data.wished_products_from_cit">
            <v-icon small>fal fa-plus-square</v-icon>
          </div>
        </div>
      </template>
      <span>
        <template v-if="customer.meta_data.wished_products_from_cit">Editeaza</template>
        <template v-else>Adauga</template>
      </span>
    </v-tooltip>

    <template v-slot:input>
      <v-textarea
        v-model="metaValue"
        label="Ce produse doreste de la cit"
      ></v-textarea>
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'ClientMetaWishedProductsFromCit',
  data () {
    return {
      metaValue: ''
    }
  },
  props: {
    customer: {
      required: true
    }
  },
  methods: {
    open () {
      this.metaValue = this.customer.meta_data.wished_products_from_cit
    },
    save () {

      this.$store.dispatch('startLoading')
      this.$http.patch(`/clients/${this.customer.id}/change-meta`, {
        meta: 'wished_products_from_cit',
        value: this.metaValue
      })
        .then(() => {
          this.$emit('change')
          this.$vs.notify({
            title: 'Succes!',
            text: 'Produsele salvate cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la salvare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => this.$store.dispatch('stopLoading'))
    }
  }
}
</script>
