<template>
  <div class="p-8">
    <div class="mb-4">Maxim 5 produse vor fi afisate.</div>

    <v-card class="mb-4">
      <v-card-title>Import nou</v-card-title>
      <v-card-text>
        <product-list v-model="lists.most_popular_new_import"/>
      </v-card-text>
    </v-card>
    <v-card class="mb-4">
      <v-card-title>De receptionat</v-card-title>
      <v-card-text>
        <product-list v-model="lists.most_popular_to_be_received" status="to-be-received"/>
      </v-card-text>
    </v-card>
    <v-card class="mb-4">
      <v-card-title>In Stoc</v-card-title>
      <v-card-text>
        <product-list v-model="lists.most_popular_in_stock" status="in-stock"/>
      </v-card-text>
    </v-card>

    <div>
      <v-btn color="primary" @click="save">Salveaza</v-btn>
    </div>
  </div>
</template>

<script>

import ProductList from '@/views/Settings/HomepageSettings/components/mostPopular/productList'

export default {
  name: 'mostPopular',
  components: {ProductList},
  data () {
    return {
      lists: {
        most_popular_new_import: [],
        most_popular_to_be_received: [],
        most_popular_in_stock: []
      }
    }
  },
  methods: {
    save () {
      this.$store.dispatch('startLoading')
      this.$http.post('settings/homepage/most-popular', {
        list: {
          most_popular_new_import: JSON.stringify(this.lists.most_popular_new_import.map((item) => {
            return item.id
          })),
          most_popular_to_be_received: JSON.stringify(this.lists.most_popular_to_be_received.map((item) => {
            return item.id
          })),
          most_popular_in_stock: JSON.stringify(this.lists.most_popular_in_stock.map((item) => {
            return item.id
          }))
        }
      })
        .then((data) => {
          console.log(data)
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              'color': 'danger'
            })
          } else {
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              'color': 'danger'
            })
          }
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    }
  },
  created () {
    this.$http.get('settings/homepage/most-popular')
      .then(({data}) => {
        this.lists.most_popular_new_import = data.most_popular_new_import
        this.lists.most_popular_to_be_received = data.most_popular_to_be_received
        this.lists.most_popular_in_stock = data.most_popular_in_stock
      })
      .catch(() => {
        this.$vs.notify({
          'title': 'Eroare',
          'text': 'Eroare la preluarea celor mai populare, va rugam reimprospatati pagina sau incercati mai tarziu',
          'color': 'danger'
        })
      })
  }
}
</script>

<style scoped>

</style>
