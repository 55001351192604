<template>
  <v-sheet>

    <v-row>
      <v-col cols="8">
        <v-data-table
          :expanded.sync="openedPanels"
          :headers="[
        {
          text: 'Ordinea',
          value: 'order',
          width:'100px',
          sortable:false
        },
        {
          text: 'Activ',
          value: 'active',
          width:'100px',
          sortable:false
        },
        {
          text: 'Zona',
          value: 'label',
          sortable:false
        },
      ]"
          :items="orderedGroups"
          :items-per-page="10000"
          :page="1"
          :single-expand="false"
          dense
          hide-default-footer
          item-key="prefix"
          show-expand
        >

          <template v-slot:[`item.order`]="{ item, value }">
            <display-order-changer
              :group="item"
              :max="groups.length"
              @move-up="moveUp"
              @move-down="moveDown"
            />
          </template>

          <template v-slot:[`item.active`]="{ item, value }">
            <v-switch
              v-model="item.show_section"
              :disabled="!item.allow_hide"
              :false-value="false"
              :true-value="true"
              dense
            ></v-switch>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pt-6">
              <div v-if="item.fields.length">
                <div v-for="(field, indexF) in item.fields" :key="indexF">
                  <template v-if="field.type === 'text'">
                    <v-text-field v-model="field.value" :label="field.label" dense hide-details outlined/>
                  </template>
                  <template v-else-if="field.type === 'url'">
                    <v-text-field v-model="field.value" :label="field.label" dense hide-details outlined type="url"/>
                  </template>
                  <template v-else-if="field.type === 'textarea'">
                    <v-textarea v-model="field.value" :label="field.label" dense hide-details outlined/>
                  </template>
                  <template v-else-if="field.type === 'wysiwyg'">
                    <v-textarea v-model="field.value" :label="field.label" dense hide-details outlined/>
                  </template>
                  <template v-else-if="field.type === 'image'">
                    <base64-file-uploader v-model="field.value" :label="field.label"/>
                  </template>
                  <template v-else-if="field.type === 'boolean' || field.type === 'bool'">
                    <v-switch v-model="field.value" :false-value="false" :label="field.label"
                              :true-value="true" dense outlined></v-switch>
                  </template>
                  <template v-else>
                    {{ field }}
                  </template>
                  <div class="mb-4 font-light font-italic text-xs">
                    {{ field.help }}
                  </div>
                </div>
              </div>
              <div v-else>
                Nu exista campuri in aceasta sectiune
              </div>
            </td>
          </template>

        </v-data-table>
      </v-col>
      <v-col cols="4">
        <v-card class="my-8">
          <v-card-title>Culori CIT Grup</v-card-title>
          <v-card-text>
            <table>
              <tr>
                <td style="width: 30%">Albastru:</td>
                <td>
                  <v-chip color="#00A4E1" x-small>#00A4E1</v-chip>
                </td>
              </tr>
              <tr>
                <td>Verde:</td>
                <td>
                  <v-chip color="#60A629" x-small>#60A629</v-chip>
                </td>
              </tr>
              <tr>
                <td>Galben:</td>
                <td>
                  <v-chip color="#FCC129" x-small>#FCC129</v-chip>
                </td>
              </tr>
              <tr>
                <td>Rosu:</td>
                <td>
                  <v-chip color="#FB4739" x-small>#FB4739</v-chip>
                </td>
              </tr>
              <tr>
                <td>Negru:</td>
                <td>
                  <v-chip color="#000000" dark x-small>#000000</v-chip>
                </td>
              </tr>
              <tr>
                <td>Gri:</td>
                <td>
                  <v-chip color="#707070" dark x-small>#707070</v-chip>
                </td>
              </tr>

            </table>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>Campuri dinamice</v-card-title>
          <v-card-text>
            <p>Pentru utilizarea informatiilor dinamice se pot folosi "tag-urile" de mai jos. Ele sunt inlocuite cu valoarea lor la trimiterea newsletterului.</p>
            <table class="w-full">
              <tr>
                <td>Tag</td>
                <td>Descriere</td>
              </tr>
              <tr>
                <td>{total_reviews}</td>
                <td>Afiseaza numarul de testimoniale adaugate pe site</td>
              </tr>
              <tr>
                <td>{reviews_score_10}</td>
                <td>Afiseaza media testimonialelor (de la 0 la 10)</td>
              </tr>
              <tr>
                <td>{reviews_score_5}</td>
                <td>Afiseaza media testimonialelor (de la 0 la 5)</td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-sheet>
</template>

<script>
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import DisplayOrderChanger from './DisplayOrderChanger'
import {findIndex} from 'lodash'

export default {
  components: {
    Base64FileUploader,
    DisplayOrderChanger
  },
  props: {
    value: {}
  },
  data () {
    return {
      openedPanels: [],
      template: {
        fields: {
          groups: []
        }
      }
    }
  },
  computed: {
    orderedGroups () {
      return this.groups.sort((a, b) => {
        return a.order - b.order
      })
    },
    groups: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    loadTemplate () {
      this.$http('newsletter/templates/show')
        .then(({data}) => {
          this.template = data
          let order = 0
          this.groups = this.template.fields.groups.map((group) => {
            order++
            return {
              label: group.label,
              prefix: group.prefix,
              allow_hide: group.allow_hide,
              show_section: true,
              order: order,
              fields: group.fields.map((field) => {
                return {
                  name: field.name,
                  help: field.help,
                  value: field.default,
                  type: field.type,
                  label: field.label
                }
              })
            }
          })
        })
    },
    moveUp (group) {
      const index = findIndex(this.groups, (item) => {
        return item.prefix === group.prefix
      })

      this.groups[index - 1].order++
      this.groups[index].order--
    },
    moveDown (group) {
      const index = findIndex(this.groups, (item) => {
        return item.prefix === group.prefix
      })

      this.groups[index + 1].order--
      this.groups[index].order++
    }
  },
  created () {
    this.loadTemplate()
  }
}
</script>
