var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{ref:"table",attrs:{"appends":['status_details'],"fixed-filters":_vm.extraFilters,"headers":_vm.headers,"includes":[
      'voucherLog',
      'items',
      'products' ],"resource-path":_vm.resourcePath},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('view-button',{attrs:{"to":{name:'orders-page', params:{id:item.id}},"target":"_self"}})]}},{key:"item.product_categories_in_order",fn:function(ref){
      var item = ref.item;
return [_c('order-category-list',{attrs:{"order":item}})]}},{key:"item.products",fn:function(ref){
      var item = ref.item;
return _vm._l((item.products),function(product,index){return _c('div',{key:index,staticClass:"product-name mb-2"},[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(product.product_id)+" :")]),_vm._v(" "+_vm._s(product.full_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(product.product_id)+" : "+_vm._s(product.full_name))])])],1)})}},{key:"item.status_name",fn:function(ref){
      var item = ref.item;
return [(item.status === 'paid')?[_c('inline-confirm-payment-button',{attrs:{"order":item}})]:[_c('v-chip',{attrs:{"color":item.status_details.color}},[_vm._v(_vm._s(item.status_details.name))]),(item.status === 'payment_confirmed')?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.confirmed_at,'DD-MM-YYYY HH:mm:ss'))+" ")]):_vm._e()]]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }