<template>
  <v-sheet>
    <cit-data-table
      :fixed-filters="{customer_id:customerId}"
      :headers="headers"
      :includes="['category']"
      resource-path="analytics/search"
    >

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  name: 'SearchLogDisplay',
  components: {CitDataTable},
  data () {
    return {
      headers: [
        {
          text: 'Categorie',
          value: 'category.name',
          sortable: false,
          width: '100'
        },
        {
          text: 'Cautare',
          value: 'search_term',
          sortable: false
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          width: '150'
        }
      ]
    }
  },
  props: {
    customerId: {
      default: false
    }
  }
}
</script>

