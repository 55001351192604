<template>
  <div>
    Clientul <strong>
    <router-link :to="clientRouteLink">{{ values.customer.full_name }}</router-link>
  </strong> a plasat o comanda <strong>
    <router-link :to="orderRouteLink">#{{ values.order.id }}</router-link>
  </strong>
  </div>
</template>

<script>
export default {
  name: 'OrderPlaced',
  props: {
    values: {}
  },
  computed: {
    clientRouteLink () {
      return {
        name: 'view-client',
        params: {
          id: this.values.customer.id
        }
      }
    },
    orderRouteLink () {
      return {
        name: 'orders-page',
        params: {
          id: this.values.order.id
        }
      }
    }
  }
}
</script>
