<template>
  <section class="data-list-container">

    <div class="mb-5">
      <new-button class="mr-5" @click="openAddNew">Adauga Grupa noua</new-button>
    </div>

    <vx-card>
      <v-data-table
        :headers="headers"
        :hide-default-footer="true"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType"
      >

        <template v-slot:header="{props}">

          <tr>
            <th v-for="header in props.headers" :key="header.text">
              <template v-if="header.filterable === true">
                <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter"/>
              </template>
            </th>
          </tr>
        </template>

        <template v-slot:item.actions="{item}">
          <edit-button @click="editItem(item)"></edit-button>
        </template>

        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>

      </v-data-table>
    </vx-card>


  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FilterInput from '@/components/general-form/FilterInput'

export default {
  name: 'ClientTypeList',
  components: {FilterInput, DeleteButton, EditButton, NewButton},
  data () {
    return {
      headers: [
        {text: 'Denumire', value: 'name', filterable: true},
        {text: '', value: 'actions', width: '150', sortable: false}
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['clientType/paginatedData']
    },
    currentPage () {
      return this.$store.getters['clientType/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-new-client-type',
        title: 'Adauga o grupa noua de clienti'
      })
    },
    editItem (item) {
      this.$store.dispatch('clientType/editItem', item)
    },
    changePage (newPage) {
      this.$store.dispatch('clientType/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('clientType/deleteFilter', filterName)
      } else {
        this.$store.dispatch('clientType/addFilter', {filterName, searchValue})
      }
    },
    loadItems () {
      this.$store.dispatch('clientType/loadItems')
    },
    deleteItem (item) {
      this.$store.dispatch('clientType/deleteItem', item.id)
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('clientType/changeSort', newValue)
      }
    }
  },
  created () {
    this.loadItems()
  }
}
</script>
