<template>
  <action-edit
    :action-url="`customer-benefit/${item.id}`"
    :item="item"
    button-label="Editeaza Beneficiul"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.required_points" dense hide-details label="Puncte necesare" outlined type="number"/>
        <error-messages :errors="errors.required_points"/>
      </div>
      <div class="mb-4">
        <v-textarea v-model="form.description" dense hide-details label="Descriere" outlined></v-textarea>
        <error-messages :errors="errors.description"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume Premiu" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <div v-if="form.image">
          <v-img
            :src="form.image"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>
        <base64-file-uploader
          v-model="form.image_file"
          label="Imagine Premiu"
        />
        <error-messages :errors="errors.image_file"/>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    ActionEdit,
    ErrorMessages,
    Base64FileUploader
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
