<template>
  <sp-wrapper title="Administrarea Sabloanelor de newsletter">

    <sp-group title="Crearea unui sablon">
      <sp-item step="1">
        Se acceseaza Panoul de admin
      </sp-item>

      <sp-item step="2">
        Se acceaseaza din meniul <strong>"Setari"</strong> -> submeniul
        <router-link :to="{name:'user-guide-newsletter'}">Sabloane newsletter</router-link>
      </sp-item>

      <sp-item step="3">
        Se Descarca sablonul demo. acest sablon este sablonul utilizat in luna noiembrie 2021.
      </sp-item>
      <sp-item step="4">
        Se realizeaza noul sablon utilizand fisierele si functiile prezentate mai jos
      </sp-item>
      <sp-item step="5">
        Se apasa butonul <strong>Sablon nou</strong> , se introduce o denumire si arhiva creata la pasul anterior
      </sp-item>
      <sp-item step="6">
        Se apasa butonul Creaza. <i>Actiunea poate dura cateva secunde.</i>
      </sp-item>
      <sp-item step="6">
        Gata! Se pot trimite newslettere folosind noul sablon
      </sp-item>
      <sp-item step="Fisiere existente">
        Sabloneul demo contine urmatoarele:
        <ul>
          <li>1 x fisier <strong>settings.json</strong> - Acest fisier defineste campurile (utilizate) si tipul lor</li>
          <li>1 x fisier <strong>index.blade.php</strong> - Acest fisier este codul php care va fi utilizat in generarea
            newsletterului. Se foloseste Blade ca template engine
          </li>
          <li>1 x folder <strong>images</strong> - Acest folder contine toate imaginile statice utilizate in sablon.
          </li>
        </ul>
      </sp-item>
      <sp-item step="Functii utilizabile">
        Sablonul utilizeaza practicile Laravel pentru a fi construit. In plus au fost create o serie de functii
        "helpers" pentru o dezvoltare cat mai usoara:
        <ul>
          <li>
            <strong>@emailTracking</strong> - Directiva blade utilizata pentru a injecta elementele de tracking a
            utilizatorilor care au citit newsletter-ul
          </li>

          <li>
            <strong>cit_newsletter_tracked_link(string $url)</strong> - Functie utilizata pentru a urmari comenziile
            utilizatorilor care au dat click pe linkul acesta
          </li>

          <li>
            <strong>cit_newsletter_unsubscribe_link(Customer $customer)</strong> - Functie utilizata pentru generarea
            linkului de unsubscribe from newsletter
          </li>

          <li>
            <strong>cit_newsletter_local_image(string $relative_path)</strong> - Functie utilizata a afisa corect
            imaginile si resursele utilizate in newsletter
          </li>

        </ul>
      </sp-item>
    </sp-group>
    <sp-group title="Sablon predefinit">
      <sp-item step="1">
        Un sablon predefinit este acel sablon ce se va utiliza la trimiterea unui newsletter
      </sp-item>

      <sp-item step="2">
        Se acceaseaza din meniul <strong>"Setari"</strong> -> submeniul
        <router-link :to="{name:'user-guide-newsletter'}">Sabloane newsletter</router-link>
      </sp-item>

      <sp-item step="3">
        Pentru a seta un sablond ca predefinit se apasa <i>steluta</i> din dreptul acestuia

        <expandable-image image="images/user-guide/newsletter/mark-newsletter-template-as-default.png"/>
      </sp-item>
    </sp-group>

  </sp-wrapper>
</template>

<script>
import ExpandableImage from '@/views/UserGuide/components/ExpandableImage'

export default {
  components: {ExpandableImage}
}
</script>
