<template>
  <div>
    <vs-tooltip text="Produse Cadou">
      <v-btn class="ml-2" icon x-small @click="openDialog">
        <v-icon x-small>fal fa-gifts</v-icon>
      </v-btn>
    </vs-tooltip>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          Produse Cadou pentru {{ baseProduct.name }}
        </v-card-title>

        <v-card-text>
          <template v-if="gifts.length">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in gifts" :key="index">
                  <td>{{ item.name }}</td>
                  <td>
                    <delete-button without-confirmation @delete="deleteGift(index)"/>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template v-else>
            <p class="text-center my-5 text-xl font-weight-bold">Nu exista produse cadou.</p>
          </template>

          <base-product-gifts-product-selector @selected="selectGift"/>

        </v-card-text>

        <v-card-actions>
          <v-btn text @click="closeDialog ">
            Anuleaza
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">
            Salvare
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import DeleteButton from '@/components/general-form/DeleteButton'
import BaseProductGiftsProductSelector
  from '@/views/ProductManagement/BaseProductManager/BaseProductGiftsProductSelector'

export default {
  name: 'BaseProductGifts',
  components: {
    BaseProductGiftsProductSelector,
    DeleteButton,
    ErrorMessages
  },
  props: {
    baseProduct: {
      required: true
    }
  },
  computed: {
    baseProductId () {
      return this.baseProduct.id
    },
    selectGiftsIds () {
      return this.gifts.map(item => item.id)
    }
  },
  data () {
    return {
      dialog: false,
      gifts: []
    }
  },
  methods: {
    getGifts () {
      this.$store.dispatch('startLoading')
      this.$http.get(`base-products/${this.baseProductId}/gifts`)
        .then(({data}) => {
          this.gifts = data
          this.$store.dispatch('stopLoading')
        })
    },
    openDialog () {
      this.dialog = true
      this.getGifts()
    },
    save () {
      this.$store.dispatch('startLoading')
      this.$http.post(`base-products/${this.baseProductId}/gifts`, {gifts: this.selectGiftsIds})
        .finally(() => {
          this.closeDialog()
          this.$store.dispatch('stopLoading')
        })
    },
    closeDialog () {
      this.gifts = []
      this.dialog = false
    },
    deleteGift (index) {
      this.gifts.splice(index, 1)
    },
    selectGift (product) {
      this.gifts.push(product)
    }
  }
}
</script>
