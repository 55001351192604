<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>Newsletter - Sabloane</v-toolbar-title>
    </v-toolbar>
    <v-tabs class="" left vertical>

      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Sabloane</span>
      </v-tab>


      <v-tab-item class="ml-5">
        <template-guide/>
      </v-tab-item>

    </v-tabs>
  </v-card>


</template>

<script>
import TemplateGuide from './sections/Template'

export default {
  name: 'index',
  components: {
    TemplateGuide
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
