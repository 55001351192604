<template>
  <product-category-selector v-model="selectOption" :label="placeholder"/>
</template>

<script>
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'

export default {
  name: 'CategoryFilter',
  components: {ProductCategorySelector},
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      selectOption: ''
    }
  },
  watch: {
    selectOption () {
      if (typeof this.selectOption === 'undefined') {
        this.$emit('filter', '', this.filterName)
      } else {
        this.$emit('filter', this.selectOption, this.filterName)
      }
    }
  }
}
</script>

<style scoped>

</style>
