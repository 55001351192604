<template>
 <span class="button-wrapper">
   <v-dialog v-model="dialogDisplay" max-width="350" persistent>
      <v-card>
        <v-card-title class="justify-center">Confirmi plata?</v-card-title>
        <v-card-actions>
          <v-btn :disabled="loading" color="blue darken-1" text @click="dialogDisplay=false">Anuleaza</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" text @click="triggerAction">Confirma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   <vs-tooltip v-if="displayButton" position="right" text="Confirma Plata si inchide comanda">
    <v-btn color="success" @click="dialogDisplay = true">
      <i class="fal fa-box-check"></i> <span class="ml-2">Confirma Plata</span>
    </v-btn>
   </vs-tooltip>
  </span>
</template>

<script>
export default {
  name: 'ConfirmPaymentButton',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      dialogDisplay: false,
      loading: false
    }
  },
  computed: {
    displayButton () {
      return (this.order.status === 'paid')
    }
  },
  methods: {
    triggerAction () {
      this.loading = true
      this.$http.patch(`orders/${this.order.id}/confirm-payment`)
        .then(() => {
          this.$router.push({
            name: 'orders-all',
            params: {status: 'unprocessed'}
          })
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.dialogDisplay = false
        })
    }
  }
}
</script>


<style scoped>
.button-wrapper {
  @apply inline-block;
}
</style>

