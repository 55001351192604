<template>
  <div class="flex justify-between">
    <label>
      <input v-model="isPublishedToCit" :false-value="0" :true-value="1" type="checkbox">
      Publicarea pe site-ul Cit Grup
    </label>

    <label>
      <input v-model="isPublishedToResellers" :false-value="0" :true-value="1" type="checkbox">
      Publicarea pe site-urile distribuitorilor
    </label>

    <label>
      <input v-model="isPublishedToFeed" :false-value="0" :true-value="1" type="checkbox">
      Publicarea feed
    </label>
  </div>
</template>

<script>
export default {
  name: 'PublishLocations',
  computed: {
    isPublishedToCit: {
      get () {
        return this.$store.getters['productGenerator/isPublishedToCit']
      },
      set (value) {
        this.$store.commit('productGenerator/SET_IS_PUBLISHED_TO_CIT', value)
      }
    },
    isPublishedToResellers: {
      get () {
        return this.$store.getters['productGenerator/isPublishedToResellers']
      },
      set (value) {
        this.$store.commit('productGenerator/SET_IS_PUBLISHED_TO_RESELLERS', value)
      }
    },
    isPublishedToFeed: {
      get () {
        return this.$store.getters['productGenerator/isPublishedToFeed']
      },
      set (value) {
        this.$store.commit('productGenerator/SET_IS_PUBLISHED_TO_FEED', value)
      }
    }
  }
}
</script>
