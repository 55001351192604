import axios from '@/axios'
import router from '@/router'

export default {
  login ({commit}, credentials) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/login', credentials)
        .then((response) => {
          commit('SET_USER', response.data.user)
          commit('AUTH_LOGIN', response.data.access_token)
          router.push('/')
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  fetchAccessToken () {
    return axios.post('/auth/refresh', {accessToken: localStorage.getItem('access_token')})
  },
  setToken ({commit}, token) {
    commit('AUTH_LOGIN', token)
  },
  logout ({commit}) {
    commit('AUTH_LOGOUT')
    commit('SET_USER', {})
    router.push('/login')
  }
}
