<template>
  <v-sheet>
    <base-product-editor/>
    <cit-data-table
      :appends="[
        'stock_value_color',
        'is_deleted',
        'absolute_permalink'
      ]"
      :fixedFilters="{
        category: currentCategoryId
      }"
      :headers="headers"
      height="55vh"
      resource-path="products"
      show-select
      show-before
    >

      <template v-slot:actions="{}">

        <new-button :to="{name:'generate-product'}" v-if="$store.getters['auth/currentUser'].is_admin">Adauga Produs</new-button>

        <v-spacer></v-spacer>

        <export-products/>
      </template>

      <template v-slot:before_table="{table}">
        <v-col>
          <extra-filters :category-id="currentCategoryId" @filter="table.updateFilter"/>
        </v-col>
      </template>

      <template v-slot:bulk_select_actions="{selectedItems,table}">

        <template v-if="selectedItems.length > 0">

          <delete-selected :selected-items="selectedItems" :table="table"/>

          <update-selected-discount-end-user :selected-items="selectedItems" :table="table"/>

          <update-selected-discount :selected-items="selectedItems" :table="table"/>

        </template>

      </template>

      <template v-slot:filter.category="{header, updateFilter}">
        <product-subcategory-filter
          :parent-category-id="currentCategoryId"
          filter-name="category.id"
          @filter="updateFilter"
        />
      </template>

      <template v-slot:filter.base_product="{header,updateFilter}">
        <base-product-filter
          :parent-category-id="currentCategoryId"
          filter-name="base_product_id"
          @filter="updateFilter"
        />
      </template>

      <template v-slot:filter.stock_value="{header,updateFilter}">
        <stock-status-filter filter-name="stock_value" @filter="updateFilter"/>
      </template>

      <template v-slot:filter.actions="{header,updateFilter}">
        <trash-status-filter filter-name="trashed" @filter="updateFilter"/>
      </template>

      <template v-slot:item.discount_end_users="{item, loadItems, startLoading,stopLoading}">
        <div class="flex justify-around">
          <discount-changer
            :item="item"
            field="discount_end_users"
            @reload="loadItems"
            @start-loading="startLoading"
            @stop-loading="stopLoading"
          />
        </div>
      </template>

      <template v-slot:item.discount_resellers="{item, loadItems, startLoading,stopLoading}">
        <div class="flex justify-around">
          <discount-changer
            :item="item"
            field="discount_resellers"
            @reload="loadItems"
            @start-loading="startLoading"
            @stop-loading="stopLoading"
          />
        </div>
      </template>

      <template v-slot:item.max_discount="{item, loadItems, startLoading,stopLoading}">
        <div class="flex justify-around">
          <discount-changer
            :item="item"
            field="max_discount"
            @reload="loadItems"
            @start-loading="startLoading"
            @stop-loading="stopLoading"
          />
        </div>
      </template>

      <template v-slot:item.category="{item, value}">
        <div>
          <strong>{{ value.name }}</strong>
        </div>
        <div v-if="item.quality">
          <template v-if="value.name !== item.quality.name">
            <small>Calitate: <strong>{{ item.quality.name }}</strong></small>
          </template>
        </div>
      </template>

      <template v-slot:[`item.base_product`]="{item, value}">
        <div class="flex justify-between items-center">
          <div>{{ value.name }}</div>
          <template v-if="!item.is_external">
            <base-product-editor-trigger :base-product-id="value.id"/>
          </template>
        </div>
      </template>

      <template v-slot:item.stock_value="{item}">
        <v-chip :color="item.stock_value_color" dark>{{ item.stock_value }}</v-chip>
      </template>

      <template v-slot:[`item.actions`]="{item, table}">
        <edit-button v-if="item.config_id" :to="getEditProductRoute(item)"></edit-button>

        <view-button :href="item.absolute_permalink"/>

        <action-restore v-if="item.is_deleted" :action-url="`products/${item.id}`"/>
        <action-delete v-else :action-url="`products/${item.id}`"/>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ProductSubcategoryFilter from '@/components/layout/CitDataTableFilters/ProductSubcategoryFilter'
import StockStatusFilter from '@/components/layout/CitDataTableFilters/StockStatusFilter'

import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ActionRestore from '@/components/layout/ActionButtons/ActionRestore'


import ViewButton from '@/components/general-form/ViewButton'
import EditButton from '@/components/general-form/EditButton'
import NewButton from '@/components/general-form/NewButton'

import BaseProductEditor from '@/views/ProductManagement/BaseProductManager/BaseProductEditor'
import BaseProductEditorTrigger from '@/views/ProductManagement/BaseProductManager/BaseProductEditorTrigger'

import UpdateSelectedDiscountEndUser from '@/views/Products/components/UpdateSelectedDiscountEndUser'
import UpdateSelectedDiscount from '@/views/Products/components/UpdateSelectedDiscount'
import SubcategoryFilter from '@/views/Products/components/Filters/SubcategoryFilter'
import DeleteSelected from '@/views/Products/components/DeleteSelected'
import DiscountChanger from '@/views/Products/components/DiscountChanger'
import ExtraFilters from '@/views/Products/components/ExtraFilters'
import TrashStatusFilter from '@/views/Products/components/Filters/TrashStatusFilter'
import BaseProductFilter from '@/views/Products/components/Filters/BaseProductsFilter'
import ExportProducts from '@/views/Products/components/ExportProducts'

export default {
  name: 'ProductList',
  components: {
    ExportProducts,
    ActionRestore,
    ActionDelete,
    BaseProductFilter,
    StockStatusFilter,
    TrashStatusFilter,
    ExtraFilters,
    NewButton,
    BaseProductEditor,
    UpdateSelectedDiscountEndUser,
    UpdateSelectedDiscount,
    ProductSubcategoryFilter,
    SubcategoryFilter,
    EditButton,
    ViewButton,
    DiscountChanger,
    BaseProductEditorTrigger,
    CitDataTable,
    DeleteSelected
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          filterType: 'text',
          width: '100',
          align: 'center'
        },
        {
          text: 'E.U.',
          value: 'discount_end_users',
          filterType: 'text',
          width: '100',
          align: 'center'
        },
        {
          text: 'Dist.',
          value: 'discount_resellers',
          filterType: 'text',
          width: '100',
          align: 'center'
        },
        {
          text: 'Max Discount',
          value: 'max_discount',
          sortable: false,
          width: '100',
          align: 'center'
        },
        {
          text: 'Poza',
          value: 'featured_image',
          display: 'image',
          sortable: false,
          width: '50',
          align: 'center'
        },
        {
          text: 'Categorie',
          value: 'category',
          chipDark: true,
          sortable: false,
          width: '150'
        },
        {
          text: 'Produs de baza',
          value: 'base_product',
          sortable: false,
          width: '250'
        },
        {
          text: 'Extern?',
          value: 'is_external',
          sortable: false,
          display: 'boolean'
        },
        {
          text: 'Provider',
          value: 'external_provider',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'full_name',
          filterType: 'text',
          sortable: true,
          width: '400'
        },
        {
          text: 'Pret intrare',
          value: 'buying_price',
          sortable: true,
          width: '120',
          align: 'center'
        },
        {
          text: 'Pret vanzare',
          value: 'price',
          sortable: true,
          width: '120',
          align: 'center'
        },
        {
          text: 'Cantitate',
          value: 'stock',
          sortable: true,
          width: '120',
          align: 'center'
        },
        {
          text: 'Stoc',
          value: 'stock_value',
          sortable: false,
          width: '150',
          align: 'center'
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          align: 'center',
          width: '100',
          sortable: true
        }
      ]
    }
  },
  computed: {
    currentCategoryId () {
      return this.$route.params.categoryId
    }
  },
  methods: {
    getEditProductRoute (item) {
      return {
        name: 'edit-generate-product',
        params: {
          categoryId: item.category.parent_category_id,
          productConfigId: item.config_id
        }
      }
    }
  }
}
</script>

