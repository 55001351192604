<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>

          <v-btn color="red lighten-4"  @click="logout" class="w-full md:w-auto">
            <v-icon class="mr-2">fal fa-sign-out</v-icon> Dezautentificare
          </v-btn>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <clear-cache-button/>
        </v-col>
        <v-col cols="12" md="4">
          <regenerate-customer-feeds-button/>
        </v-col>
        <v-col cols="12" md="4">
          <regenerate-product-attribute-pivot/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import RegenerateProductAttributePivot from '../components/buttons/RegenerateProductAttributePivot'
import ClearCacheButton from '../components/buttons/ClearCacheButton'
import RegenerateCustomerFeedsButton from '../components/buttons/RegenerateCustomerFeedsButton'

export default {
  components: {
    RegenerateCustomerFeedsButton,
    ClearCacheButton,
    RegenerateProductAttributePivot
  },
  computed: {
    activeUserInfo () {
      return this.$store.getters['auth/currentUser']
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
