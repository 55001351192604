<template>
  <action-add-new action-url="customer-benefit" button-label="Adauga Beneficiu nou">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.required_points" dense hide-details label="Puncte necesare" outlined type="number"/>
        <error-messages :errors="errors.required_points"/>
      </div>
      <div class="mb-4">
        <v-textarea v-model="form.description" dense hide-details label="Descriere" outlined></v-textarea>
        <error-messages :errors="errors.description"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume Premiu" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <div v-if="form.image">
          <v-img
            :src="form.image"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>
        <base64-file-uploader
          v-model="form.image_file"
          label="Imagine Premiu"
        />
        <error-messages :errors="errors.image_file"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    Base64FileUploader,
    ErrorMessages,
    ActionAddNew
  }

}
</script>
