<template>
  <p class="mb-2">
    <template v-if="step">
      <span class="block font-weight-bold">Pasul {{ step }}.</span>
    </template>
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'SettingPageItem',
  props: {
    step: {
      default: false
    }
  }
}
</script>
