<template>
  <div class="w-full component-types-wrapper">
    <div v-if="hasSlots" v-dragscroll class="component-types">
      <div v-for="slot in slots" :key="slot.uuid" class="component-types--element">
        <single-slot-display :component-type-slot="slot"/>
      </div>
    </div>
  </div>
</template>

<script>
import SingleSlotDisplay from '@/views/ProductGenerator/components/ComponentsSelector/SlotsDisplay/SingleSlotDisplay'
export default {
  name: 'SlotsDisplay',
  components: {SingleSlotDisplay},
  computed: {
    slots () {
      return this.$store.getters['productGenerator/slots']
    },
    hasSlots () {
      return !!this.$store.getters['productGenerator/slotsCount']
    }
  }
}
</script>
<style lang="scss" scoped>
.component-types-wrapper {
  border: 1px solid #f0f0f0;
  @apply p-2;
  .component-types {
    @apply flex overflow-x-auto h-full pb-5;
    scrollbar-width: thin;
    scrollbar-color: #707070 #d5d5d5;

    &::-webkit-scrollbar {
      height: 5px;
      @apply rounded-lg;
    }

    &::-webkit-scrollbar-track {
      @apply rounded-lg;
      background: #d5d5d5;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded-lg;
      background: #707070;
    }

    &--element {
      @apply flex-grow flex-shrink-0 bg-white shadow;
      width: 450px;
    }
  }
}
</style>
