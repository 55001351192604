<template>
  <product-subcategory-selector v-model="selectOption" :label="placeholder" :parent-category-id="parentCategoryId"/>
</template>

<script>
import ProductSubcategorySelector from '@/components/autocompletes/ProductSubcategorySelector'

export default {
  name: 'SubcategoryFilter',
  components: {ProductSubcategorySelector},
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    },
    parentCategoryId:{
      required: true
    }
  },
  data () {
    return {
      selectOption: ''
    }
  },
  watch: {
    selectOption () {
      if (typeof this.selectOption === 'undefined') {
        this.$emit('filter', '', this.filterName)
      } else {
        this.$emit('filter', this.selectOption, this.filterName)
      }
    }
  }
}
</script>

<style scoped>

</style>
