var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cit-data-table',{attrs:{"headers":_vm.headers,"includes":['categories'],"resource-path":"feeds"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('create-form')]},proxy:true},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"href":item.url,"color":"primary","x-small":""}},[_vm._v("Vezi")])],1),_c('div',{staticClass:"border border-double my-1 overflow-hidden text-no-wrap px-2",staticStyle:{"max-width":"290px"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.download_url",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"href":item.download_url,"color":"primary","x-small":""}},[_vm._v("Descarca")])],1),_c('div',{staticClass:"border border-double my-1 overflow-hidden text-no-wrap px-2",staticStyle:{"max-width":"290px"}},[_vm._v(" "+_vm._s(item.download_url)+" ")])]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(cat,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(cat.heading_one)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-form',{attrs:{"item":item}}),_c('action-delete',{attrs:{"action-url":("feeds/" + (item.id))}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }