<template>
  <action-edit
    :action-url="`location/countries/${item.id}`"
    :item="item"
    button-label="Editeaza tara"
  >

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
        <error-messages :errors="errors.name"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.code" dense hide-details outlined placeholder="Cod" single-line/>
        <error-messages :errors="errors.code"/>
      </div>
      <div class="mb-4">
        <v-switch v-model="form.is_active" :false-value="false" :true-value="true" hide-details label="Este Activa?"/>
        <error-messages :errors="errors.is_active"/>
      </div>
    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
