<template>
  <div class="flex items-center">
    <strong class="text-base mr-2">{{ group.order }}</strong>
    <div class="flex">
      <v-btn :disabled="group.order === 1" icon x-small @click="moveUp">
        <v-icon>fal fa-arrow-square-up</v-icon>
      </v-btn>
      <v-btn :disabled="group.order === max" icon x-small @click="moveDown">
        <v-icon>fal fa-arrow-square-down</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      required: true
    },
    max: {
      required: true
    }
  },
  methods: {
    moveUp () {
      this.$emit('move-up', this.group)
    },
    moveDown () {
      this.$emit('move-down', this.group)
    }
  }
}
</script>
