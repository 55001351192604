<template>
 <span>
   <v-dialog v-model="dialogDelete" max-width="350px" persistent>
      <v-card>
        <v-card-title class="text-center">
          {{ confirmationText }}
        </v-card-title>

        <template v-if="error">
          <v-divider/>
          <v-card-text class="pt-4 red--text">
            {{ error }}
          </v-card-text>
          <v-divider/>
        </template>

        <v-card-actions>
          <v-btn text @click="cancel">Anuleaza</v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="deleteItem">{{ deleteLabel }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="red"
          icon
          @click="actionButtonClicked"
        >
            <v-icon class="mx-2" x-small>fal fa-trash-alt</v-icon>
        </v-btn>
      </template>
      <span>{{ deleteLabel }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'ActionDelete',
  inject: ['table'],
  props: {
    withoutConfirmation: {
      default: false,
      type: Boolean
    },
    actionUrl: {
      type: String,
      required: true
    },
    deleteLabel: {
      type: String,
      default: 'Sterge'
    },
    confirmationText: {
      type: String,
      default: 'Esti sigur ca doresti sa stergi acest element?'
    }
  },
  data () {
    return {
      dialogDelete: false,
      error: ''
    }
  },
  methods: {
    cancel () {
      this.error = ''
      this.dialogDelete = false
    },
    actionButtonClicked () {
      if (this.withoutConfirmation) {
        this.deleteItem()
      } else {
        this.dialogDelete = true
      }
    },
    deleteItem () {
      this.table.startLoading()
      this.error = ''
      this.$http.delete(this.actionUrl)
        .then(() => {
          this.table.loadItems()
          this.dialogDelete = false
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.error = response.data.message
          } else {
            this.error = 'Eroare la stergere, va rugam reimprospatati pagina sau incercati mai tarziu'
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la stergere, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.table.stopLoading()
        })
    }
  }
}
</script>
