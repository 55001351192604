<template>
  <div>
    <vs-tooltip position="right" text="Marcheaza comanda cu plata intentionata prin OP" v-if="displayButton">
      <v-btn @click="sendChangeRequest">Platit OP</v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MarkAsPayButton',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed:{
    displayButton () {
      return (this.order.status === 'ordered' || this.order.status === 'proforma')
    }
  },
  methods: {
    sendChangeRequest () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`orders/${this.order.id}/mark-as-paid`)
        .then(({data}) => {
          this.$emit('updated', data)
        })
        .catch(() => {
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>
