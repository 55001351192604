<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="290px"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="text-center">
        <v-chip
          v-if="item.birthday"
          v-bind="attrs"
          v-on="on"
          x-small
        >
          {{ item.birthday | moment('D.M.Y') }}
        </v-chip>
        <basic-button
          v-else
          v-bind="attrs"
          v-on="on"
          :loading="loading"
          color="dark"
          icon="fal fa-birthday-cake"
          is-icon
          tooltip="Alege data de nastere a clientului"
        />
      </div>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import BasicButton from '@/components/general-form/BasicButton'

export default {
  components: {BasicButton},
  inject: ['table'],
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      loading: false,
      menu: false,
      date: ''
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$http.patch(`/clients/${this.item.id}/change-birthday`, {
        birthday: this.date
      })
        .then(() => {
          this.table.loadItems()
          this.$vs.notify({
            title: 'Succes!',
            text: 'Agentul a fost actualizat cu succes!!',
            color: 'success'
          })
          this.menu = false
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la adaugarea punctelor. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    if (this.item.birthday) {
      this.date = this.item.birthday
    }
  }
}
</script>
