<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      disable-actions
      resource-path="feed-connectors/nod/logs"
    >
    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ImportCategories from '@/views/FeedConnectors/Nod/NodCategories/Import'

export default {
  components: {
    ImportCategories,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Nume',
          value: 'value',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          sortable: true
        }
      ]
    }
  }
}
</script>
