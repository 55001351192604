
import axios from '@/axios.js'

export default {
  load ({commit}, item) {
    axios.get(`clients/${item}`)
      .then(({data}) => {
        commit('LOAD_ITEM', data)
      })
  }
}
