<template>
  <action-add-new action-url="order-vouchers" button-label="Adauga Voucher nou">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.code" dense hide-details label="COD Voucher" outlined/>
        <error-messages :errors="errors.code"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Denumire Voucher" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <v-switch v-model="form.is_fixed_value" dense hide-details label="Este suma fixa?" outlined/>
        <error-messages :errors="errors.is_fixed_value"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.value" dense hide-details label="Valoare" outlined step="0.01" type="number"/>
        <error-messages :errors="errors.value"/>
      </div>

      <div class="mb-4">
        <complex-customer-selector v-model="form.customer_id" placeholder="Client"/>
        <span>Daca se alege un client, acest voucher va fi unul nominal si nu va putea fi folosit decat de acest client</span>
        <error-messages :errors="errors.customer_id"/>
      </div>

    </template>


  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ComplexCustomerSelector from '@/components/autocompletes/ComplexCustomerSelector'

export default {
  components: {
    ComplexCustomerSelector,
    ErrorMessages,
    ActionAddNew
  }

}
</script>
