<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="loading"
        :loading="loading"
        class="mx-1"
        color="indigo lighten-4"
        elevation="1"
        @click="download"
      >
        <v-icon class="mr-2" small>fal fa-download</v-icon>
        <slot>Descarca Demo</slot>
      </v-btn>
    </template>
    <span>Descarca Sablonul Demo pentru editare</span>
  </v-tooltip>
</template>

<script>
export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    download () {
      this.loading = true
      this.$http.get('newsletter/templates/demo', {
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'demo.zip')
        document.body.appendChild(fileLink)

        fileLink.click()
        this.loading = false
      })

    }
  }
}
</script>
