<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="dark"
        icon
        @click="transform"
      >
        <v-icon>mdi-account-switch-outline</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TransformButton',
  props: {
    endpoint: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      default: 'Transforma Reseller'
    }
  },
  inject: ['table'],
  methods: {
    transform () {
      this.table.startLoading()
      this.$http.patch(this.endpoint)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Clientul a fost modificat cu succes!',
            color: 'success'
          })
          this.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la transformarea clientului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.table.stopLoading()
        })
    }
  }
}
</script>
