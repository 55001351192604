<template>
  <action-add-new
    action-url="feed-connectors/general/integrations"
    button-label="Adauga Integrare noua"
    dialog-title="Detalii feed"
  >

    <template v-slot:fields="{form, errors}">

      <div class="text-right mb-4">
        <create-provider-form/>
      </div>

      <div class="mb-4">
        <autocomplete-selector v-model="form.provider_id" endpoint="feed-connectors/general/providers"
                               label="Selecteaza Provider"/>
        <error-messages :errors="errors.provider_id"/>
      </div>

      <div class="mb-4">
        <feed-name-input v-model="form.name" :provider="form.provider_id"/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <product-subcategory-selector v-model="form.category_id" :parent-category-id="categoryId"
                                      label="Subcategorie CIT"/>
        <error-messages :errors="errors.category_id"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'
import SubcategoryFilter from '@/views/Products/components/Filters/SubcategoryFilter'
import ProductSubcategorySelector from '@/components/autocompletes/ProductSubcategorySelector'
import CreateProviderForm from '@/views/FeedConnectors/Generals/Providers/Create'
import Create from '@/views/TaskManagement/Create'
import FeedNameInput from '@/views/FeedConnectors/Generals/Integrations/FeedNameInput'

export default {
  components: {
    FeedNameInput,
    Create,
    ProductSubcategorySelector,
    SubcategoryFilter,
    AutocompleteSelector,
    ErrorMessages,
    CreateProviderForm,
    ActionAddNew
  },
  props: {
    categoryId: {
      required: true
    }
  }
}
</script>
