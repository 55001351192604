<template>
  <div>
    <v-autocomplete
      v-model="selectOptions"
      :items="filterType.options"
      class="w-full"
      item-text="name"
      item-value="id"
      label="Brand"
      multiple
      dense
      hide-details
      outlined
    >
      <template v-slot:append-item>
        <v-row class=" mt-2">
          <v-col>
            <v-btn block small text @click="selectAll">
              <v-icon class="mr-3" small>
                fal fa-check-double
              </v-icon>
              <span>Toate</span>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block small text @click="clearAll">
              <v-icon class="mr-3" small>
                fal fa-times
              </v-icon>
              <span>Niciunul</span>
            </v-btn>
          </v-col>

        </v-row>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'BrandsFilter',
  data () {
    return {
      selectOptions: []
    }
  },
  props: {
    filterType: {
      required: true
    }
  },
  methods: {
    clearAll () {
      this.selectOptions = []
    },
    selectAll () {
      this.selectOptions = JSON.parse(JSON.stringify(this.filterType.options.map(item => item.id)))
    }
  },
  watch: {
    selectOptions () {

      this.$emit('filter', this.selectOptions, this.filterType.machine_name)
    }
  }

}
</script>
