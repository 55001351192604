<template>
  <div class="mb-5">
    <v-img
      max-height="150"
      max-width="250"
      :src="image"
      :class="fullImage ? 'full-image' : ''"
    >
      <v-btn
        class="mx-2 float-right"
        fab
        dark
        small
        color="primary"
        @click="expandImage"
      >
        <template v-if="fullImage">
          <v-icon dark>
            mdi-magnify-minus
          </v-icon>
        </template>
        <template v-else>
          <v-icon dark>
            mdi-magnify-plus
          </v-icon>
        </template>
      </v-btn>
    </v-img>


  </div>
</template>

<script>
  export default {
    name: "ExpandableImage",
    props: {
      image: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        fullImage: false
      }
    },
    methods: {
      expandImage() {
        this.fullImage = !this.fullImage
      }
    }
  }
</script>

<style scoped>
  .full-image {
    max-height: 100% !important;
    max-width: 100% !important;
  }
</style>
