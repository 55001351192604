<template>
  <cit-data-table
    :fixed-filters="{
      has_parent:'yes'
    }"
    :headers="headers"
    :includes="['parent', 'badges', 'qualities']"
    height="75vh"
    hide-filters
    hide-pagination
    per-page="100"
    resource-path="product-categories"
    show-expand
  >
    <template v-slot:actions>
      <create-form/>
    </template>

    <template v-slot:item.display_order="{item, value}">
      <display-order-changer :item="item"/>
    </template>

    <template v-slot:item.badges="{item, value}">
      <div class="my-2">
        <v-chip v-for="(badge, index) in item.badges" :key="index" class="mx-2" x-small>
          {{ badge.name }}
        </v-chip>
      </div>
    </template>

    <template v-slot:item.actions="{item}">

      <edit-form :item="item"/>

      <category-filter-access-button :item="item"/>

      <featured-specs-filters-access-button :item="item"/>
      
      <banners-access-button :item="item"/>

      <action-delete :action-url="`product-categories/${item.id}`"/>

    </template>

    <template v-slot:expanded-item="{ item }">
      <children-list :parent_category_id="item.id"/>
    </template>

  </cit-data-table>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ChildrenList from '@/views/ProductManagement/Categories/ChildrenList'
import CreateForm from './Create'
import EditForm from './Edit'
import DisplayOrderChanger from '@/views/ProductManagement/Categories/components/DisplayOrderChanger'
import CategoryFilterAccessButton from './CategoryFilters/AccessButton'
import FeaturedSpecsFiltersAccessButton from '@/views/ProductManagement/Categories/FeaturedSpecsFilters/AccessButton'
import BannersAccessButton from '@/views/ProductManagement/Categories/Banners/AccessButton'

export default {
  components: {
    DisplayOrderChanger,
    ChildrenList,
    ActionDelete,
    CitDataTable,
    CategoryFilterAccessButton,
    FeaturedSpecsFiltersAccessButton,
    BannersAccessButton,
    EditForm,
    CreateForm
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65',
          sortable: false
        },
        {
          text: 'Ordine afisare',
          value: 'display_order',
          width: '65',
          sortable: false
        },
        {
          text: 'Denumire',
          value: 'name',
          width: 200,
          sortable: false
        },
        {
          text: 'Badge-uri',
          value: 'badges',
          sortable: false
        },
        {
          text: 'Taxa verde',
          value: 'green_tax',
          width: '100',
          sortable: false,
          align: 'right'
        }
      ]
    }
  }
}
</script>
