var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('integration-navigation')],1),_c('v-col',{attrs:{"cols":"10"}},[_c('cit-data-table',{attrs:{"fixed-filters":{
        'product_category.parent_category_id' : _vm.$route.params.categoryId
      },"headers":_vm.headers,"includes":[
        'nod_category',
        'product_category',
        'product_quality' ],"resource-path":"feed-connectors/nod/category-importer"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('create-form',{attrs:{"category-id":_vm.$route.params.categoryId}}),_c('attribute-page-button',{attrs:{"category-id":_vm.$route.params.categoryId}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(item.is_active)?_c('sync-integration',{attrs:{"item":item}}):_vm._e(),(item.is_active)?_c('deactivate-integration',{attrs:{"item":item}}):_vm._e(),(!item.is_active)?_c('activate-integration',{attrs:{"item":item}}):_vm._e(),(!item.is_active)?_c('action-delete',{attrs:{"action-url":("feed-connectors/nod/category-importer/" + (item.id))}}):_vm._e(),_c('edit-form',{attrs:{"item":item}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }