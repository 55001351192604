<template>
  <action-add-new action-url="location/counties" button-label="Adauga judet nou">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
        <error-messages :errors="errors.name"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.abbreviation" dense hide-details outlined placeholder="Cod" single-line/>
        <error-messages :errors="errors.abbreviation"/>
      </div>
      <div class="mb-4">
        <autocomplete-selector v-model="form.country_id" endpoint="location/countries" label="Tara"/>
        <error-messages :errors="errors.country_id"/>
      </div>
      <div class="mb-4">
        <autocomplete-selector v-model="form.zone_id" endpoint="location/zones" label="Zone"/>
        <error-messages :errors="errors.zone_id"/>
      </div>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'

export default {
  components: {
    AutocompleteSelector,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
