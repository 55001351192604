<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="loading"
        :loading="loading"
        color="success"
        icon
        @click="doClickAction"
      >
        <v-icon>fal fa-play-circle</v-icon>
      </v-btn>
    </template>
    <span>Activeaza</span>
  </v-tooltip>
</template>

<script>
export default {
  inject: ['table'],
  data () {
    return {
      loading: false
    }
  },
  props: {
    item: {
      required: true
    }
  },
  methods: {
    doClickAction () {
      this.loading = true
      this.$http.post(`feed-connectors/nod/category-importer/${this.item.id}/activate`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes',
            text: 'Integrarea a fost activata cu succes.',
            color: 'success'
          })
          this.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la activare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

