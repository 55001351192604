<template>
  <v-sheet>
    <cit-data-table
      :fixed-filters="{
        category_id:$route.params.categoryId,
        attribute_id:$route.params.attributeId,
      }"
      :headers="headers"
      :includes="[
        'product_attribute_value'
      ]"
      :resource-path="`feed-connectors/nod/attribute-values`"
    >

      <template v-slot:actions>
        <attribute-page-button
          :category-id="$route.params.categoryId"
          button-label="Inapoi la atribute"
          icon="fal fa-angle-left"
        />
      </template>

      <template v-slot:item.actions="{item}">
        <change :item="item"/>
        <add-new :item="item"/>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import GoBackButton from '@/views/FeedConnectors/Nod/NodAttributes/GoBackButton'
import AttributePageButton from '@/views/FeedConnectors/Nod/NodAttributes/AttributePageButton'
import AddNew from '@/views/FeedConnectors/Nod/NodAttributesValues/AddNew'
import Change from '@/views/FeedConnectors/Nod/NodAttributesValues/Change'

export default {
  components: {
    AddNew,
    AttributePageButton,
    GoBackButton,
    CitDataTable,
    Change
  },
  data () {
    return {
      headers: [
        {
          text: 'Valoare atribut Nod',
          value: 'value',
          filterType: 'text'
        },
        {
          text: 'Valoare atribut CIT',
          value: 'product_attribute_value.name',
          filterType: 'text'
        }
      ]
    }
  }
}
</script>
