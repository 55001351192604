<template>
  <action-add-new
    action-url="feed-connectors/general/providers"
    button-label="Adauga Provider nou"
  >

    <template v-slot:fields="{form, errors}">


      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.name"/>
      </div>


    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    ActionAddNew
  }
}
</script>
