export default {
  paginatedData (state) {
    return state.paginatedData.data
  },
  pagination (state) {
    return state.paginatedData.meta
  },
  queryString (state) {
    return state.queryString
  },
  currentPage (state) {
    return state.page
  },

  getItemForEdit (state) {
    return state.editItem
  },
  getDialogStatus (state) {
    return state.editDialog
  }
}
