<template>
  <div>
    Clientul a achizitionat tema <strong>{{ values.theme.name }}</strong>
  </div>
</template>

<script>
export default {
  props: {
    values: {}
  }
}
</script>
