import axios from '@/axios'

export default {
  loadRoles ({
    commit,
    dispatch
  }) {
    return axios.get('/roles')
      .then(({data}) => {
        commit('GET_ROLES', data)
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })
  }
}
