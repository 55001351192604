<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col>
        <v-card-title>Reducere de ziua clientului</v-card-title>

        <div class="mb-4">
          <v-card-text>
            <v-switch
              v-model="form.birthday_extra_discount_is_active"
              :false-value="0"
              :true-value="1"
              hide-details
              label="Activare Reducere de ziua de nastere "
            ></v-switch>
            <error-messages :errors="errors.birthday_extra_discount_is_active"/>

          </v-card-text>
        </div>

        <div class="mb-4">
          <v-card-text>
            <v-text-field v-model="form.birthday_extra_discount_index" dense label="Discount bonus"
                          hint="Se introduce o 'cota de discount' ce se va aduna la cota clientului pentru a oferi bonus de ziua lui de nastere. Ex. pentru un utilizator cu cota 1.2, daca se introduce 0.05, noua lui cota de ziua lui de nastere va fi 1.25"
                          persistent-hint
                          outlined/>
            <error-messages :errors="errors.birthday_extra_discount_index"/>
          </v-card-text>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" dark large @click="saveSettings">
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages
  },
  data () {
    return {
      form: {
        birthday_extra_discount_is_active: false,
        birthday_extra_discount_index: 0
      },
      errors: {}
    }
  },
  methods: {
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'birthday_extra_discount_is_active',
            value: this.form.birthday_extra_discount_is_active
          },
          {
            key: 'birthday_extra_discount_index',
            value: this.form.birthday_extra_discount_index
          },
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(() => {
          this.$store.dispatch('notify', {
            title: 'Eroare!',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          }, {root: true})
        })
    }
  },
  mounted () {
    this.$http.get('settings?settings[]=birthday_extra_discount_index&settings[]=birthday_extra_discount_is_active')
      .then(({data}) => {
        this.form.birthday_extra_discount_is_active = parseInt(data.birthday_extra_discount_is_active) || false
        this.form.birthday_extra_discount_index = parseFloat(data.birthday_extra_discount_index) || 0
      })

  }
}
</script>
