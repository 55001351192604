<template>
  <span>
    <v-dialog v-model="dialogIsOpened" max-width="350" persistent>
      <v-card>
        <v-card-title>Descarcare XLSX</v-card-title>
        <v-card-text>
          <v-progress-linear
            :active="true"
            :indeterminate="true"
            color="primary"
            rounded
            height="20"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <basic-button
          v-bind="attrs"
          v-on="on"
          :loading="loading"
          color="black"
          icon="fal fa-download"
          is-icon
          tooltip="Descarca structura"
          @click="downloadFile"
        />
      </template>
      <span>Descarca structura</span>
    </v-tooltip>
  </span>

</template>

<script>
import BasicButton from '@/components/general-form/BasicButton'

export default {
  name: 'DownloadEmptyStructure',
  components: {
    BasicButton
  },
  props: {
    integration: {
      required: true
    }
  },
  data () {
    return {
      loading: false,
      fileName: '',
      dialogIsOpened: false
    }
  },
  methods: {
    downloadFile () {
      this.dialogIsOpened = true
      this.loading = true
      this.$http
        .post(`feed-connectors/general/integrations/${this.integration.id}/generate`)
        .then(({data}) => {
          this.fileName = data
          this.tryToDownload()
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
        })

    },
    tryToDownload () {
      if (!this.downloaded) {
        this.$http
          .get(`feed-connectors/general/integrations/${this.integration.id}/generate/${this.fileName}`, {
            headers: {
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
          })
          .then(({data}) => {

            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.integration.name}.xlsx`)
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)

            this.loading = false
            this.dialogIsOpened = false
          })
          .catch(() => {
            setTimeout(() => {
              this.tryToDownload()
            }, 5000)
          })
      }
    }
  }

}
</script>
