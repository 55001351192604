<template>
  <div class="flex items-center">
    <strong class="text-base mr-2">{{ item.display_order }}</strong>
    <div class="flex">
      <v-btn :disabled="item.display_order === 1" icon x-small @click="moveUp">
        <v-icon>fal fa-arrow-square-up</v-icon>
      </v-btn>
      <v-btn :disabled="item.display_order === table.paginatedData.meta.total" icon x-small @click="moveDown">
        <v-icon>fal fa-arrow-square-down</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['table'],
  props: {
    item: {
      required: true
    }
  },
  methods: {
    moveUp () {
      this.move(-1)
    },
    moveDown () {
      this.move(1)
    },
    move (changer) {
      this.table.startLoading()
      this.$http.patch(`/product-categories/${this.item.id}/reorder`, {
        changer: changer
      })
        .then(() => {
          this.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la reordonare, va rugam reimprospatati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.table.stopLoading()
        })

    }
  }
}
</script>
