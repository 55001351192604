<template>
  <v-row class="flex flex-wrap extra-filters">
    <v-col v-for="(filterType, index) in filterTypes" :key="index" class="mb-2 py-0" cols="2">
      <component :is="`${filterType.type}-filter`" :filter-type="filterType" @filter="filter"></component>
    </v-col>
  </v-row>
</template>

<script>
import BrandsFilter from './ExtraFilters/BrandsFilter'
import ComponentsFilter from './ExtraFilters/ComponentsFilter'
import ModelFilter from '@/views/Products/components/ExtraFilters/ModelFilter'

export default {
  name: 'ExtraFilters',
  components: {
    ModelFilter,
    BrandsFilter,
    ComponentsFilter
  },
  data () {
    return {
      filterTypes: []
    }
  },
  props: {
    categoryId: ''
  },
  methods: {
    loadFilters () {
      this.$http.get(`/product-categories/${this.categoryId}/product-admin-filters`)
          .then(({data}) => {
            this.filterTypes = data
          })
    },
    filter (filterValue, filterMachineName) {
      this.$emit('filter', filterValue, filterMachineName)
    }
  },
  created () {
    this.loadFilters()
  }
}
</script>

