<template>
  <action-add-new
    :action-url="`feed-connectors/nod/category-importer/${categoryId}`"
    button-label="Adauga Integrare noua" dialog-width="500"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <nod-category-autocomplete v-model="form.selected_nod_category"/>
        <error-messages :errors="errors.selected_nod_category"/>
      </div>

      <div class="mb-4">
        <v-radio-group v-model="form.include_faults" dense hide-details>
          <template v-slot:label>
            <div><strong>Preia si produse resigilate?</strong></div>
          </template>
          <v-radio :value="false" label="Nu"></v-radio>
          <v-radio :value="true" label="Da"></v-radio>
        </v-radio-group>
        <error-messages :errors="errors.include_faults"/>
      </div>

      <div v-show="form.include_faults" class="mb-4">
        <nod-faults-multiselect v-model="form.selected_faults"/>
        <error-messages :errors="errors.selected_faults"/>
      </div>

      <div class="mb-4">
        <product-subcategory-selector
          v-model="form.cit_category" :parent-category-id="categoryId" label="Subcategorie CIT"/>
        <error-messages :errors="errors.cit_category"/>
      </div>

      <div class="mb-4">
        <cit-quality-selector v-model="form.cit_quality" :category="form.cit_category"/>
        <error-messages :errors="errors.cit_quality"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.multiplier" dense hide-details label="Adaos" outlined type="number"/>
        <error-messages :errors="errors.multiplier"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.delivery_period" dense hide-details label="Termen de livrare" outlined />
        <error-messages :errors="errors.delivery_period"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import ProductSubcategorySelector from '@/components/autocompletes/ProductSubcategorySelector'
import NodCategoryAutocomplete from '@/views/FeedConnectors/Nod/components/autocompletes/NodCategoryAutocomplete'
import NodFaultsMultiselect from '@/views/FeedConnectors/Nod/components/autocompletes/NodFaultsMultiselect'
import QualitySelector from '@/views/ProductGenerator/components/CategorySelector/QualitySelector'
import CitQualitySelector from '@/views/FeedConnectors/Nod/components/autocompletes/CitQualitySelector'

export default {
  components: {
    CitQualitySelector,
    QualitySelector,
    NodFaultsMultiselect,
    NodCategoryAutocomplete,
    ProductSubcategorySelector,
    Base64FileUploader,
    ErrorMessages,
    ActionAddNew
  },

  props: {
    categoryId: {
      required: true
    }
  }
}
</script>
