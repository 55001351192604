<template>
  <div>
    <div v-for="(category, index) in categories" :key="index">
      - {{ category }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: true
    }
  },
  computed: {
    categories () {
      return this.order.products
        .map(item => `${item.category.parent.name}`)
        .filter((value, index, self) => self.indexOf(value) === index)
    }
  }
}
</script>
