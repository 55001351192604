<template>
  <v-container class="max-w-full">

    <v-row class="items-center">
      <v-col class="py-0" cols="3">
        <p class="mb-0 text-lg">Coeficient multiplicare total comenzi*</p>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="cit_points_order_multiplier"
          hide-details
          outlined
          dense
          label="Coeficient multiplicare total comenzi*"
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="mb-0 text-lg">Scoring voucher</p>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="py-0" cols="3">
        <p class="mb-0 text-lg">Procent Voucher Client distributie*</p>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="voucher_reseller_percent"
          hide-details
          outlined
          dense
          single-line
          suffix="%"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="py-0" cols="3">
        <p class="mb-0 text-lg">Procent Voucher Client End User *</p>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="voucher_end_user_percent"
          hide-details
          outlined
          dense
          single-line
          suffix="%"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="py-0" cols="3">
        <p class="mb-0 text-lg">Procent Voucher pentru incasare cash *</p>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="voucher_cash_back_percent"
          hide-details
          outlined
          dense
          single-line
          suffix="%"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <vs-alert :active="errors" color="danger" icon="new_releases">
          <span>{{ generalError }}</span>
        </vs-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'clientScoring',
  data () {
    return {
      cit_points_order_multiplier: '',
      voucher_reseller_percent: '',
      voucher_end_user_percent: '',
      voucher_cash_back_percent: '',
      generalError: '',
      errors: false
    }
  },
  methods: {
    getScoringSettings () {
      this.$http.get('settings?settings[]=cit_points_order_multiplier&settings[]=voucher_reseller_percent&settings[]=voucher_end_user_percent')
        .then(({data}) => {
          this.cit_points_order_multiplier = data.cit_points_order_multiplier
          this.voucher_reseller_percent = data.voucher_reseller_percent
          this.voucher_end_user_percent = data.voucher_end_user_percent
          this.voucher_cash_back_percent = data.voucher_cash_back_percent
        })
    },
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'cit_points_order_multiplier',
            value: this.cit_points_order_multiplier
          },
          {
            key: 'voucher_reseller_percent',
            value: this.voucher_reseller_percent
          },
          {
            key: 'voucher_end_user_percent',
            value: this.voucher_end_user_percent
          },
          {
            key: 'voucher_cash_back_percent',
            value: this.voucher_cash_back_percent
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch((error) => {
          this.generalError = error.response.data.message
          this.errors = true
        })
    }
  },
  created () {
    this.getScoringSettings()
  }
}
</script>
