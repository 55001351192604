<template>
  <div>
    <span>
      Agentul a sters produsele cu ID-urile:
    </span>
    <div v-for="(item, index) in values" style="display: inline-block">
      <div>
        <v-tooltip bottom max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <strong v-bind="attrs" v-on="on">{{ item.id }}</strong>
          </template>
        {{ item.full_name }}
        </v-tooltip>
        <span v-if="index !== values.length - 1">
          ,
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsDeleted',
  props: {
    values: {
      type: Array
    }
  }
}
</script>

<style scoped>

</style>
