<template>
  <v-sheet>
    <cit-data-table
      ref="table"
      :appends="[
        'is_orders_last_month_higher',
        'orders_last_month',
        'orders_total',
        'activated',
        'last_order_at',
        'email_confirmed',
        'birthday'
      ]"
      :headers="headers"
      :includes="['website']"
      resource-path="clients/resellers"
    >

      <template v-slot:actions="{}">
        <create-form/>
        <v-spacer/>
        <import-customers resource-path="clients/resellers"/>
        <export-client-list type="resellers"/>
      </template>

      <template v-slot:[`filter.interest_lists`]="{header, updateFilter}">
        <interest-list-filter filter-name="interest_lists" @filter="updateFilter"/>
      </template>


      <template v-slot:[`item.company.caen`]="{item, value}">
        <template v-if="value">{{ value }}</template>
        <template v-else>-</template>
        <help-icon v-if="item.company.caen_explained" :text="item.company.caen_explained" position="bottom"/>
      </template>

      <template v-slot:[`item.last_contacted_at`]="{item, value}">
        <last-contacted-at :client="item" :last_contacted_at="item.last_contacted_at" @changed="reload"/>
      </template>


      <template v-slot:[`item.agent`]="{item, value}">
        <client-agent-replacer :agent="value" :client="item" @changed="reload"/>
      </template>

      <template v-slot:[`item.orders_last_month`]="{item, value}">
        <v-chip v-if="item.is_orders_last_month_higher" color="#60a629" dark>
          {{ item.orders_last_month }}
        </v-chip>
        <v-chip v-else color="#fb4739" dark>
          {{ item.orders_last_month }}
        </v-chip>
      </template>

      <template v-slot:[`item.orders_total`]="{value}">
        <v-chip dark>
          {{ value }}
        </v-chip>
      </template>

      <template v-slot:[`item.birthday`]="{item}">
        <birthday-updated :item="item"/>
      </template>

      <template v-slot:[`item.discount`]="{item}">
        <client-discount-select :client="item" @changed="reload"/>
      </template>

      <template v-slot:[`item.score`]="{item}">
        <client-add-extra-points :customer="item" @changed="reload"/>
      </template>

      <template v-slot:[`item.interest_lists`]="{item}">
        <client-interest-select :client="item"/>
      </template>

      <template v-slot:[`item.confirmed`]="{item}">
        <template v-if="!item.activated">
          <span class="text-danger">Neactivat</span>
          <v-btn text x-small @click="activateReseller(item)">Confirma si trimite mail de confirmare</v-btn>
        </template>

        <template v-else-if="!item.email_confirmed">
            <span class="text-warning">
              Neconfirmat
            </span>
          <v-btn text x-small @click="resendConfirmationEmail(item)">Retrimite mail de confirmare</v-btn>
        </template>

        <template v-else>
            <span class="text-success">
              Confirmat
            </span>
        </template>

      </template>

      <template v-slot:[`item.meta_data.wished_products_from_cit`]="{item}">
        <client-meta-wished-products-from-cit :customer="item" @change="reload"/>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <view-button :to="{name:'view-client', params:{id:item.id}}" target="_self"/>
        <action-delete :action-url="`clients/${item.id}`"/>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import HelpIcon from '@/components/HelpIcon'
import LastContactedAt from '@/views/Clients/components/lastContactedAt'
import ClientAgentReplacer from '@/views/Clients/components/ClientAgentReplacer'
import ClientDiscountSelect from '@/views/Clients/components/ClientDiscountSelect'
import ClientAddExtraPoints from '@/views/Clients/components/ClientAddExtraPoints'
import ClientInterestSelect from '@/views/Clients/components/ClientInterestSelect'
import ClientMetaWishedProductsFromCit from '@/views/Clients/components/ClientMetaWishedProductsFromCit'
import ViewButton from '@/components/general-form/ViewButton'
import NewButton from '@/components/general-form/NewButton'
import ClientConfirmed from '@/views/Clients/components/ClientConfirmed'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import TransformButton from '@/components/general-form/TransformButton'
import ExportClientList from '@/views/Clients/ExportClientList'
import ImportCustomers from '@/views/Clients/components/ImportCustomers'
import InterestListFilter from '@/views/Clients/components/Filters/InterestListFilter'
import BirthdayUpdated from '@/views/Clients/components/BirthdayUpdated'

export default {
  components: {
    BirthdayUpdated,
    InterestListFilter,
    ImportCustomers,
    ExportClientList,
    TransformButton,
    CreateForm,
    ActionDelete,
    ClientConfirmed,
    NewButton,
    ViewButton,
    ClientMetaWishedProductsFromCit,
    ClientInterestSelect,
    ClientAddExtraPoints,
    ClientDiscountSelect,
    ClientAgentReplacer,
    LastContactedAt,
    HelpIcon,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Nume Firma',
          value: 'company.name',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          align: 'center',
          text: 'E Voucher',
          sortable: false,
          value: 'is_voucher',
          display: 'checkmarks',
          width: '90'
        },
        {
          text: 'Activitate',
          value: 'company.activity',
          sortable: true,
          filterType: 'text',
          width: '150'
        },
        {
          text: 'Caen',
          value: 'company.caen',
          filterType: 'text',
          sortable: true,
          width: '100'
        },
        {
          text: 'Nr. Angajati',
          value: 'company.number_of_employees',
          sortable: true,
          width: '110'
        },
        {
          text: 'Persoana de contact',
          value: 'full_name',
          filterType: 'text',
          sortable: true,
          width: '150'
        },
        {
          text: 'Email',
          value: 'email',
          filterType: 'text',
          sortable: true,
          width: '100'
        },
        {
          text: 'Status Email',
          value: 'email_validation_status',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Deliverable',
              value: 'deliverable'
            },
            {
              text: 'Undeliverable',
              value: 'undeliverable'
            },
            {
              text: 'Unknown',
              value: 'unknown'
            },
            {
              text: 'Do Not Send',
              value: 'do_not_send'
            },
            {
              text: 'Catch All',
              value: 'catch_all'
            }
          ],
          width: '150'
        },
        {
          text: 'Telefon',
          value: 'phone',
          filterType: 'text',
          sortable: true,
          width: '150'
        },
        {
          text: 'Website',
          value: 'company.website',
          filterType: 'text',
          sortable: true,
          width: '150'
        },
        {
          text: 'Zona',
          value: 'zone.name',
          sortable: false,
          width: '120'
        },
        {
          text: 'Judet',
          value: 'county.name',
          sortable: false,
          width: '150'
        },
        {
          text: 'Localitate',
          value: 'city.name',
          sortable: false,
          width: '150'
        },
        {
          text: 'A fost contactat',
          value: 'last_contacted_at',
          filterType: 'date-range',
          sortable: true,
          width: '200'
        },
        {
          text: 'Agent',
          value: 'agent',
          sortable: false,
          filterType: 'agent',
          width: '200',
          type: 'agent',
          align: 'start'
        },
        {
          text: 'Ultima achizitie',
          value: 'last_order_at',
          sortable: false,
          display: 'date',
          width: '100',
          align: 'center'
        },
        {
          text: 'Comenzi luna trecuta',
          value: 'orders_last_month',
          sortable: false,
          width: '100',
          align: 'center'
        },
        {
          text: 'Total comenzi',
          value: 'orders_total',
          sortable: false,
          width: '100',
          align: 'center'
        },
        {
          text: 'Cifra de afaceri',
          value: 'company.net_worth',
          sortable: false,
          width: '120',
          align: 'center'
        },
        {
          text: 'Discount',
          value: 'discount',
          filterType: 'customer-discount',
          sortable: false,
          width: '120',
          align: 'center'
        },
        {
          text: 'Scor',
          value: 'score',
          sortable: false,
          width: '120',
          align: 'center'
        },
        {
          text: 'Liste de interes',
          value: 'interest_lists',
          width: '200',
          sortable: false
        },
        {
          align: 'start',
          text: 'Status',
          value: 'confirmed',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Confirmat',
              value: 'true'
            },
            {
              text: 'Neconfirmat',
              value: 'false'
            }
          ],
          sortable: false,
          width: '150'
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          filterType: 'date-range',
          sortable: false,
          width: '180'
        },
        {
          align: 'center',
          text: 'Zi nastere',
          value: 'birthday',
          display: 'date-no-time',
          sortable: false,
          width: '120'
        },
        {
          text: 'Care sunt produsele pe care le doreste de la cit?',
          value: 'meta_data.wished_products_from_cit',
          sortable: false,
          width: '250'
        },
        {
          align: 'center',
          text: 'Are Website',
          sortable: false,
          value: 'website.is_active',
          display: 'checkmarks',
          width: '90'
        },
        {
          text: 'Website URL',
          sortable: false,
          value: 'website.site_url',
          display: 'link',
          width: '150'
        }
      ]
    }
  },
  methods: {
    reload () {
      this.$refs.table.loadItems()
    },
    resendConfirmationEmail (item) {
      this.$refs.table.loading = true
      this.$http.patch(`clients/${item.id}/send-email-notification`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
          this.reload()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.$refs.table.loading = false
        })
    },
    activateReseller (item) {
      this.$refs.table.loading = true
      this.$http.patch(`clients/resellers/${item.id}/activate`)
        .then(() => {
          this.reload()
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.$refs.table.loading = false
        })
    }
  },
  created () {
    this.$store.dispatch('agents/loadItems')
    this.$store.dispatch('loadClientDiscountList')
  }
}
</script>
