<template>
  <action-edit
    :action-url="`feed-connectors/general/integrations/row/${item.id}/description`"
    :item="item"
    button-color="black"
    button-icon="fal fa-pen"
    button-label="Adauga Descrierea"
    dialog-width="700px"
  >
    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <Tinymce v-model="form.product_description"/>
        <error-messages :errors="errors.product_description"/>
      </div>
    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import Tinymce from '@/components/general-form/Tinymce'

export default {
  components: {
    Base64FileUploader,
    ActionEdit,
    ErrorMessages,
    Tinymce
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
