<template>
  <v-tabs class="homepage-settings" left vertical>

    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">SEO</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune text</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Date de contact</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiunea formular</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Program de lucru</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Detalii companie</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Premii si certificari</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Harta</span>
    </v-tab>


    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <seo-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <text-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <contact-details/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <contact-form-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <working-hours-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <company-details-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <certifications-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <map-section/>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import TextSection from './components/TextSection'
import MapSection from './components/MapSection'
import SeoSection from './components/SeoSection'
import CertificationsSection from './components/CertificationsSection'
import CompanyDetailsSection from './components/CompanyDetailsSection'
import ContactDetails from './components/ContactDetails'
import WorkingHoursSection from './components/WorkingHoursSection'
import ContactFormSection from './components/ContactFormSection'

export default {
  name: 'index',
  components: {
    TextSection,
    MapSection,
    SeoSection,
    CertificationsSection,
    CompanyDetailsSection,
    ContactFormSection,
    WorkingHoursSection,
    ContactDetails
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
