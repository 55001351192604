<template>
 <span>
   <v-dialog v-model="dialogIsOpened" :max-width="dialogWidth" class="action-add-new" persistent scrollable>
      <v-card>
        <v-card-title class="text-center justify-center">
          <slot name="title">
            {{ buttonLabel }}
          </slot>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-5">
          <slot v-bind="{form, errors, creator}" name="fields"></slot>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn :disabled="loading" :loading="loading" text @click="cancel">Anuleaza</v-btn>
          <v-spacer/>
          <v-btn :disabled="loading" :loading="loading" color="primary" @click="confirm">Salveaza</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :color="buttonColor"
          :loading="buttonLoading"
          icon
          @click="openDialog"
        >
          <v-icon class="mx-2">{{ buttonIcon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ buttonLabel }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'

export default {
  name: 'ActionEdit',
  components: {NewButton},
  inject: ['table'],
  props: {
    actionUrl: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      default: 'Editeaza'
    },
    buttonIcon: {
      type: String,
      default: 'fal fa-edit'
    },
    buttonColor: {
      type: String,
      default: 'gray'
    },
    buttonLoading: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: '350px'
    },
    item: {
      required: true
    }
  },
  data () {
    return {
      dialogIsOpened: false,
      form: {},
      errors: {},
      loading: false
    }
  },
  computed: {
    creator () {
      return this
    }
  },
  methods: {
    setItem (item) {
      this.form = JSON.parse(JSON.stringify(item))
    },
    openDialog () {
      this.dialogIsOpened = true
    },
    cancel () {
      this.errors = {}
      this.form = {}
      this.dialogIsOpened = false
    },
    confirm () {
      this.loading = true
      this.errors = {}
      this.$http.patch(this.actionUrl, this.form)
        .then(() => {
          this.$emit('saved')
          this.table.loadItems()
          this.loading = false
          this.cancel()
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
        })
    }
  },
  watch: {
    dialogIsOpened: {
      handler (value) {
        if (value) {
          this.setItem(this.item)
        }
      },
      immediate: false
    }
  }
}
</script>
