<template>
  <v-container class="max-w-full pt-0">
    <v-row class="pt-2">
      <v-col>
        <h3>Produs principal</h3>
      </v-col>
    </v-row>

    <product-selector v-model="product"/>

    <v-row class="mt-5">
      <v-col cols="12">
        <div class="d-flex items-center">
          <span class="mr-3 w-1/12">Titlu slide</span>
          <v-text-field
            v-model="slide_title"
            class="mt-0"
            dense
            hide-details
            label="Titlu slide"
            outlined
          ></v-text-field>
        </div>
        <error-messages :errors="errors.slide_title" class="mt-1"></error-messages>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex items-center">
          <span class="mr-3 w-1/12">Imagine Background</span>
          <v-select
            v-model="background_image"
            :items="background_types"
            dense
            hide-details
            item-text="color"
            item-value="background"
            label="Imagine"
            outlined
          ></v-select>
        </div>
        <error-messages :errors="errors.background_image" class="mt-1"></error-messages>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5 justify-end">
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductSelector from '@/components/autocompletes/ProductSelector'
import ErrorMessages from '@/components/general-form/ErrorMessages'
export default {
  name: 'featuredProduct',
  components: {ErrorMessages, ProductSelector},
  data () {
    return {
      product: '',
      slide_title: '',
      background_image: '',
      background_types: [
        {color: 'Verde', background: 'background-header-verde'},
        {color: 'Galben', background: 'background-header-galben'},
        {color: 'Albastru', background: 'background-header-albastru'}
      ],
      errors: {}
    }
  },
  methods: {
    saveSettings () {
      this.$http.post('settings/homepage/banner', {
        settings: this.sections
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
