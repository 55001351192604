<template>
  <action-add-new action-url="feeds" button-label="Adauga Feed nou">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Descriere" outlined/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.discount" dense hide-details label="Discount" outlined type="number"/>
        <error-messages :errors="errors.discount"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.ignored_products_id" dense hide-details label="Produse ignorate (ex. 123,521,23)" outlined />
        <error-messages :errors="errors.ignored_products_id"></error-messages>
      </div>

      <div class="mb-4">
        <all-product-category-selector v-model="form.categories" label="Catoegorii" multiple/>
        <error-messages :errors="errors.categories"></error-messages>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AllProductCategorySelector from '@/components/autocompletes/AllProductCategorySelector'

export default {
  components: {
    AllProductCategorySelector,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
