<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <vs-input v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import lodash from 'lodash'

export default {
  name: 'EditRole',
  components: {ErrorMessages},
  data () {
    return {
      form: {
        name: ''
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('startLoading')

      this.$store.dispatch('Roles/updateItem', this.form)
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              'color': 'danger'
            })
          } else {
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              'color': 'danger'
            })
          }
        })

    }
  },
  mounted () {
    const item = this.$store.getters['Roles/getItemForEdit']
    this.form = lodash.cloneDeep(item)
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
