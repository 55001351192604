<template>
<span>
  <template v-if="value">Confirmat</template>
  <template v-else>Neconfirmat</template>
</span>
</template>

<script>
export default {
  name: 'ClientConfirmed',
  props: {
    value: {
      required: true
    }
  }
}
</script>
