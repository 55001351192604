<template>
  <action-add-new action-url="newsletter/templates" button-label="Sablon Nou" @cancel="clearFile" @saved="clearFile">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Denumire" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <base64-file-uploader
          ref="archive"
          v-model="form.template"
          accept=".zip"
          label="Arhiva sablon"
        />
        <error-messages :errors="errors.template"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    Base64FileUploader,
    ErrorMessages,
    ActionAddNew
  },

  methods: {
    clearFile () {
      this.$refs.avatar.clearFile()
    }
  },

  computed: {
    roles () {
      return this.$store.getters['users/getUserRoles']
    }
  },

  created () {
    this.$store.dispatch('users/loadRoles')
  }
}
</script>
