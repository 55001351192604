<template>
  <v-sheet>
    <cit-data-table
      :fixed-filters="fixedFilters"
      :headers="headers"
      :includes="['customer', 'customer.agent']"
      disable-actions
      resource-path="login-log"
    >
    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  name: 'ExtraWarrantiesList',
  components: {
    CitDataTable
  },
  data () {
    return {
      expanded: [],
      headers: [
        {
          text: 'Client',
          value: 'customer.client_display_name',
          filterName: 'customer_id',
          filterType: 'customer',
          sortable: false
        },
        {
          text: 'Agent',
          value: 'customer.agent.client_display_name',
          filterName: 'agent_id',
          filterType: 'agent',
          sortable: false
        },
        {
          text: 'Data',
          display: 'date',
          filterType: 'date-range',
          sortable: true,
          value: 'created_at'
        }
      ]
    }
  },
  computed: {
    fixedFilters () {
      if (this.$route.params.clientType !== 'all') {
        return {
          customer_type: this.$route.params.clientType
        }
      }
      return {}
    }
  },
  created () {
    if (!this.$store.getters['agents/isLoaded']) {
      this.$store.dispatch('agents/loadItems')
    }
  }
}
</script>
