<template>
  <action-edit
    :action-url="`form-submissions/statuses/${item.id}`"
    :item="item"
    button-label="Editeaza formularul"
  >

    <template v-slot:title>
      Editeaza Status pentru <strong>Formular de contact</strong>
    </template>

    <template v-slot:fields="{form, errors}">
      <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
      <error-messages :errors="errors.name"/>
    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  name: 'EditFormSubmissionsStatus',
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
