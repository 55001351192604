<template>
  <div class="h-full">
    <template v-if="dataNotLoaded && customerId">
      <div class="flex items-center justify-center my-3 h-full">
        <span class="mx-1">
          <v-icon>fal fa-spinner fa-spin fa-lg</v-icon>
        </span>
      </div>
    </template>
    <template v-else>
      <div ref="chatWrapper" class="chat-wrapper">
        <div v-for="(message, index) in pageData" :key="index"
             :class="!message.agent_id ? 'ml-auto' : ''"
             class="chat-wrapper__message">
          <span class="chat-wrapper__message-date">
            <template v-if="message.agent_id">
              {{ message.agent.full_name }},
            </template>
            {{ message.created_at | moment("lll") }}
          </span>
          <span :class="!message.agent_id ? '' : 'justify-self-end'" class="chat-wrapper__message-body"
                v-html="message.body"></span>
        </div>
      </div>

      <div v-if="customerId" class="message-submit">
        <textarea v-model="body" placeholder="Scrie mesajul aici..."></textarea>

        <button :disabled="loading || body.length === 0" class="message-submit__button" @click="send">
          <template v-if="loading">
            <v-icon x-small>
              fal fa-spinner fa-spin fa-sm
            </v-icon>
          </template>
          <template v-else>
            <v-icon color="primary">fab fa-telegram-plane fa-lg</v-icon>
          </template>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ChatShow",
  props: {
    customerId: {
      required: true
    }
  },
  data() {
    return {
      body: '',
      dataNotLoaded: true,
      loading: false,
      pageData: {},
      timer: ''
    }
  },
  methods: {
    scrollToEnd() {
      var chatWrapper = this.$refs.chatWrapper;
      if(chatWrapper) {
        chatWrapper.scrollTop = chatWrapper.scrollHeight;
      }
    },
    getPageData() {
      if (this.customerId) {
        this.$http.get(`/chat/${this.customerId}`)
          .then(({data}) => {
            this.pageData = data
            this.dataNotLoaded = false
            this.$nextTick(() => {
              this.scrollToEnd();
            });
          })
          .catch((e) => {
            this.$vs.notify({
              title: 'Eroare',
              text: e.response.data.message,
              color: 'danger'
            })
          })
          .finally(() => {
            this.dataNotLoaded = false
          })
      }
    },
    send() {
      this.$http.post(`/chat`, {
        customer_id: this.customerId,
        body: this.body
      })
        .then(() => {
          this.getPageData()
          this.body = ''
        })
        .catch((e) => {
          this.$vs.notify({
            title: 'Eroare',
            text: e.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancelAutoUpdate () {
      clearInterval(this.timer);
    }
  },
  created() {
    this.timer = setInterval(this.getPageData, 5000);
  },
  watch: {
    customerId(value) {
      if (value) {
        this.getPageData()
      }
    }
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  }
}
</script>

<style lang="scss" scoped>
.chat-wrapper {
  @apply flex flex-col overflow-y-auto p-3;
  height: 500px;
  scrollbar-width: thin;
  scrollbar-color: #707070 #d5d5d5;

  &::-webkit-scrollbar {
    height: 3px;
    width: 7px;
    @apply rounded-lg;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-lg;
    background: #d5d5d5;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-lg;
    background: #707070;
  }

  &__message {
    @apply my-1 flex flex-col;
    max-width: 80%;

    @screen md {
      max-width: 60%;
    }

    &-body {
      @apply rounded-lg p-2 text-sm inline-block;
      background: #f7f7f7;
    }

    &-date {
      @apply font-semibold;
      font-size: 10px;
    }
  }
}

.message-submit {
  @apply flex mt-2 p-3;

  textarea {
    @apply w-full rounded-lg p-2 resize-none;
    background: #eeeeee;
  }

  &__button {
    @apply mx-5;

    &:disabled {
      @apply cursor-not-allowed;
    }
  }
}
</style>
