<template>
  <action-add-new action-url="location/countries" button-label="Adauga Tara noua">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
        <error-messages :errors="errors.name"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.code" dense hide-details outlined placeholder="Cod" single-line/>
        <error-messages :errors="errors.code"/>
      </div>
      <div class="mb-4">
        <v-switch v-model="form.is_active" :false-value="false" :true-value="true" hide-details label="Este Activa?"/>
        <error-messages :errors="errors.is_active"/>
      </div>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    ActionAddNew
  }
}
</script>
