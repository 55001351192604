<template>
 <span>
   <v-dialog v-model="dialogDelete" max-width="500px" style="z-index: 99999">
      <v-card>
        <v-card-title
          class="text-xl font-bold text-center">Esti sigur ca doresti sa stergi acest element?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Anuleaza</v-btn>
          <v-btn text @click="confirmDelete">Sterge</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs" v-on="{...listeners, ...on}"
          :to="to"
          color="red"
          icon
          @click="triggerAction"
        >
          <v-icon x-small>fal fa-trash-alt</v-icon>
        </v-btn>
      </template>
      <span>Stergere</span>
    </v-tooltip>

  </span>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    to: {
      default: '',
      type: String | Object
    },
    withoutConfirmation: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      dialogDelete: false
    }
  },
  methods: {
    triggerAction () {
      if (this.withoutConfirmation) {
        this.$emit('delete')
      } else {
        this.dialogDelete = true
      }
    },
    confirmDelete () {
      this.$emit('delete')
      this.dialogDelete = false
    }
  },
  computed: {
    listeners () {
      return {
        // ...this.$listeners
      }
    }
  }
}
</script>

