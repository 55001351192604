var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"fixed-filters":{
      category_id:_vm.$route.params.categoryId
    },"headers":_vm.headers,"includes":[
      'cit_attribute',
      'cit_attribute.group'
    ],"resource-path":"feed-connectors/nod/attribute-names"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('go-back-button',{attrs:{"category-id":_vm.$route.params.categoryId}})]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"italic font-weight-light"},[_vm._v(" ex. "+_vm._s(item.example_value)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('edit-form',{attrs:{"item":item}}),(item.cit_attribute_id)?_c('view-button',{attrs:{"to":{name:'feed-connectors-nod-attributes-values', params:{attributeId:item.cit_attribute_id, categoryId:_vm.$route.params.categoryId}},"target":"_self","text":"Vezi atributele"}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }