<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <vs-input v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>
      <div class="mb-5">
        <label class="vs-select--label">Incarca Logo-ul <small>(Maxim 1MB si 500x500 px)</small></label>
        <vs-upload
          ref="upload"
          :show-upload-button="false"
          name="logo"
          text="Cauta si incarca logo-ul" @change="setUploadFile"
          limit="1"
        />
        <error-messages :errors="errors.logo"></error-messages>
      </div>
    </form>
  </div>
</template>

<script>

import ErrorMessages from '@/components/general-form/ErrorMessages'
import {EventBus} from '@/plugins/event-bus'

export default {
  name: 'AddPortfolioCustomers',
  components: {ErrorMessages},
  data () {
    return {
      form: {
        name: '',
        logo: ''
      },
      errors: {}
    }
  },
  computed: {},
  methods: {
    submitForm () {
      this.$store.dispatch('startLoading')

      const formData = new FormData()
      formData.append('name', this.form.name)
      formData.append('logo', this.form.logo)

      this.$store.dispatch('portfolioCustomers/storeItem', formData)
        .then(() => {
          EventBus.$emit('portfolioCustomers-created')
        })
        .catch(({response}) => {
          this.errors = response.data.errors
        })

    },
    setUploadFile () {
      this.form.logo = this.$refs.upload.filesx.splice(-1)[0]
    }

  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
    margin: 0;
  }

  .img-upload {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    }
  }
}
</style>
