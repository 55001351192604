<template>
  <action-add-new action-url="product-qualities" button-label="Adauga Calitate noua">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.multiplier" label="Coeficient inmultire"/>
        <error-messages :errors="errors.multiplier"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.schema_condition" label="Element conditie Schema.org"/>
        <error-messages :errors="errors.schema_condition"></error-messages>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    ActionAddNew
  }
}
</script>
