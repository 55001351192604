<template>
  <v-container class="max-w-full">
    <p>Notificari stoc produse</p>
    <div>
      <v-row class="items-center">
        <v-col cols="2">
          <p class="text-lg">Cantitate alerta</p>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="notifications_stock_products_min_quantity"
            dense
            hide-details
            label="Cantitate"
            number
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="items-center">
        <v-col cols="2">
          <p class="text-lg">Agenti</p>
        </v-col>
        <v-col cols="10">
          <v-select
            v-model="notifications_stock_products_agents"
            :items="agents"
            dense
            hide-details
            item-text="full_name"
            item-value="id"
            label="Selecteaza din lista agentii care vor primi notificare la atingerea cantitatii"
            multiple
            outlined
            small-chips
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <v-divider class="my-4"/>
    <p>Notificari stoc componente</p>
    <div>
      <v-row class="items-center">
        <v-col cols="2">
          <p class="text-lg">Cantitate alerta</p>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="notifications_stock_components_min_quantity"
            dense
            hide-details
            label="Cantitate"
            number
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="items-center">
        <v-col cols="2">
          <p class="text-lg">Agenti</p>
        </v-col>
        <v-col cols="10">
          <v-select
            v-model="notifications_stock_components_agents"
            :items="agents"
            dense
            hide-details
            item-text="full_name"
            item-value="id"
            label="Selecteaza din lista agentii care vor primi notificare la atingerea cantitatii"
            multiple
            outlined
            small-chips
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col>
        <v-btn color="primary" dark large @click="saveSettings">
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      notifications_stock_products_agents: [],
      notifications_stock_components_agents: [],
      notifications_stock_products_min_quantity: 0,
      notifications_stock_components_min_quantity: 0
    }
  },
  computed: {
    agents () {
      return this.$store.getters['agents/data']
    }
  },
  methods: {
    getAgentSettings () {
      this.$http.get('settings?settings[]=notifications_stock_products_agents&settings[]=notifications_stock_components_agents&settings[]=notifications_stock_products_min_quantity&settings[]=notifications_stock_components_min_quantity')
        .then(({data}) => {
          this.notifications_stock_products_agents = JSON.parse(data.notifications_stock_products_agents || [])
          this.notifications_stock_components_agents = JSON.parse(data.notifications_stock_components_agents || [])
          this.notifications_stock_products_min_quantity = parseInt(data.notifications_stock_products_min_quantity || 0)
          this.notifications_stock_components_min_quantity = parseInt(data.notifications_stock_components_min_quantity || 0)
        })
    },
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'notifications_stock_products_agents',
            value: JSON.stringify(this.notifications_stock_products_agents)
          },
          {
            key: 'notifications_stock_products_min_quantity',
            value: this.notifications_stock_products_min_quantity
          },
          {
            key: 'notifications_stock_components_agents',
            value: JSON.stringify(this.notifications_stock_components_agents)
          },
          {
            key: 'notifications_stock_components_min_quantity',
            value: this.notifications_stock_components_min_quantity
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(() => {
          this.$store.dispatch('notify', {
            title: 'Eroare!',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          }, {root: true})
        })
    }
  },
  mounted () {
    if (!this.$store.getters['agents/isLoaded']) {
      this.$store.dispatch('agents/loadItems')
    }
    this.getAgentSettings()
  }
}
</script>
