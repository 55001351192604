<template>
  <div>
    <v-dialog v-model="dialogIsOpened" persistent scrollable width="350">
      <v-card>
        <v-card-title class="text-center justify-center">Exporta Clienti</v-card-title>
        <v-divider/>
        <v-card-text class="pt-5">

          <div class="mb-4">
            <v-row>
              <v-col>Descarca Sablonul in functie de formatul ales, completeaza-l si incarca-l mai jos</v-col>
            </v-row>
            <v-row>
              <v-col>
                <basic-button
                  color="blue-grey lighten-4"
                  icon="fal fa-download"
                  text="CSV"
                  tooltip="Descarca sablonul CSV"
                  @click="download('csv')"
                />
              </v-col>
              <v-col>
                <basic-button
                  color="blue-grey lighten-4"
                  icon="fal fa-download"
                  text="XLSX"
                  tooltip="Descarca sablonul XLSX"
                  @click="download('xlsx')"
                />
              </v-col>
            </v-row>
          </div>

          <v-divider class="my-4"/>

          <div class="mb-4">
            <div class="mb-4">
              Incarca sablonul completat. <br/>
              <strong>Atentie</strong> Daca un client (cu aceasi adresa de email) exista deja inregistrat, datele
              acestuia din fisierul CSV vor fi inlocuite
            </div>
            <base64-file-uploader
              v-model="importFile"
              accept=".csv,.xlsx"
              label="Fisier completat"
            />
          </div>


        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn :disabled="loading" :loading="loading" text @click="cancel">Anuleaza</v-btn>
          <v-spacer/>
          <v-btn :disabled="loading" :loading="loading" color="primary" @click="confirm">Importa</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <basic-button
      color="blue-grey lighten-4"
      icon="fal fa-upload"
      text="Importa clienti"
      tooltip="Importa clienti"
      @click="openDialog"
    />
  </div>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import BasicButton from '@/components/general-form/BasicButton'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    Base64FileUploader,
    NewButton,
    BasicButton
  },
  inject: ['table'],
  data () {
    return {
      importFile: false,
      loading: false,
      dialogIsOpened: false
    }
  },
  props: {
    resourcePath: {
      required: true
    }
  },
  methods: {
    openDialog () {
      this.dialogIsOpened = true
    },
    cancel () {
      this.$emit('cancel')
      this.dialogIsOpened = false
    },

    confirm () {
      this.loading = true
      this.errors = {}
      this.$http.post(`${this.resourcePath}/import`, {
        import_file: this.importFile
      })
        .then(({data}) => {
          this.$emit('saved', data)
          this.loading = false
          this.table.loadItems()
          this.cancel()
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
        })
    },
    download (type) {
      let blobType = ''
      if (type === 'csv') {
        blobType = 'text/csv;charset=utf-8;'
      } else {
        blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      }
      this.loading = true
      this.$http.get(`${this.resourcePath}/import?type=${type}`, {
        responseType: 'arraybuffer'
      })
        .then(({data}) => {
          const blob = new Blob([data], {type: blobType})
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
