<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      disable-actions
      resource-path="reports/zones"
    >

      <template v-slot:footer-right="{paginatedData}">
        <div v-if="paginatedData.footer_data" class="ml-5" style="width: 100%">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <th>Total</th>
                <td>
                  {{ paginatedData.footer_data.totals }}
                </td>
              </tr>
              <tr>
                <th>Nr. comenzi</th>
                <td>
                  {{ paginatedData.footer_data.count }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  components: {
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Nume',
          value: 'name',
          sortable: false
        },
        {
          text: 'Numar de comenzi',
          value: 'count',
          align: 'center',
          width: 100,
          sortable: false
        },
        {
          text: 'Total Produse',
          value: 'total_products',
          align: 'center',
          width: 100,
          sortable: false
        },
        {
          text: 'TVA',
          value: 'total_vat',
          align: 'center',
          width: 100,
          sortable: false
        },
        {
          text: 'Total',
          value: 'total',
          align: 'center',
          width: 100,
          sortable: false
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('agents/loadItems')
  }
}
</script>
