<template>
  <div>
    <div class="my-6">
      <vs-input
        v-model="newComponent.name"
        class="w-full"
        label="Nume"
      />
      <error-messages :errors="errors.name"/>

      <vs-select v-model="newComponent.dependencies.first" autocomplete label="Componenta 1">
        <vs-select-item v-for="(item,index) in components" :key="index"
                        :text="item.name + ' (stock: ' + item.stock + ', ' + item.price + ' Lei)'" :value="item"/>
      </vs-select>


      <vs-select v-model="newComponent.dependencies.second" autocomplete label="Componenta 2">
        <vs-select-item v-for="(item,index) in components" :key="index"
                        :text="item.name + ' (stock: ' + item.stock + ', ' + item.price + ' Lei)'" :value="item"/>
      </vs-select>

      <vs-input
        v-model="newComponent.price"
        class="w-full"
        label="Pret vanzare"
      />
      <error-messages :errors="errors.price"/>
    </div>
    <div class="mb-6">
      <div><strong>Rezumat:</strong></div>
      <ul>
        <li><strong>Nume:</strong> {{ finalNewComponent.name }}</li>
        <li><strong>Pret Vanzare:</strong> {{ finalNewComponent.price }}</li>
        <li><strong>Pret Achizitie:</strong> {{ finalNewComponent.buying_price }}</li>
        <li><strong>Stoc:</strong> {{ finalNewComponent.stock }}</li>
      </ul>
    </div>
    <div>
      <vs-button class="mr-6" @click="createComponent" :disabled="loading">Salveaza</vs-button>
      <vs-button color="danger" type="border" @click="cancel" :disabled="loading">Anuleaza</vs-button>
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddNewComputedComponent',
  components: {ErrorMessages},
  props: {
    componentType: {
      required: true
    },
    components: {
      required: true
    }
  },
  data () {
    return {
      loading:false,
      newComponent: {
        name: '',
        price: '',
        component_type_id: '',
        dependencies: {
          first: {},
          second: {}
        }
      },
      errors: {}
    }
  },
  computed: {
    finalNewComponent () {
      return {
        name: this.newComponent.name,
        price: this.price,
        buying_price: this.buyingPrice,
        stock: this.stock,
        component_type_id: this.componentType.id,
        dependencies: [
          this.newComponent.dependencies.first.id,
          this.newComponent.dependencies.second.id
        ]
      }
    },
    stock () {
      if (typeof this.newComponent.dependencies.first.stock !== 'undefined' && typeof this.newComponent.dependencies.second.stock !== 'undefined') {
        return Math.min(this.newComponent.dependencies.first.stock, this.newComponent.dependencies.second.stock)
      }
      return 0
    },
    buyingPrice () {
      if (typeof this.newComponent.dependencies.first.buying_price !== 'undefined' && typeof this.newComponent.dependencies.second.buying_price !== 'undefined') {
        const firstBP = parseFloat(this.newComponent.dependencies.first.buying_price)
        const secondBP = parseFloat(this.newComponent.dependencies.second.buying_price)
        return firstBP + secondBP
      }
      return 0
    },
    price () {
      if (this.newComponent.price) {
        return this.newComponent.price
      }
      if (typeof this.newComponent.dependencies.first.price !== 'undefined' && typeof this.newComponent.dependencies.second.price !== 'undefined') {
        const firstBP = parseFloat(this.newComponent.dependencies.first.price)
        const secondBP = parseFloat(this.newComponent.dependencies.second.price)
        return firstBP + secondBP
      }
      return 0
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
      this.newComponent = {
        name: '',
        price: '',
        buying_price: '',
        dependencies: []
      }
    },
    createComponent () {
      this.loading = true
      this.$http.post('product-components/computed', this.finalNewComponent)
        .then(({data}) => {
          this.$emit('created', data)
          this.cancel()
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: error.message,
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })

    }
  }
}
</script>
