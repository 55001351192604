<template>
  <div>
    Agentul a creat clientul:
    <router-link :to="routeLink"><strong>{{ values.client_display_name }}</strong></router-link>
  </div>
</template>

<script>
export default {
  name: 'ClientCreated',
  props: {
    values: {}
  },
  computed: {
    routeLink () {
      return {
        name: 'view-user',
        params: {
          id: this.values.id
        }
      }
    }
  }
}
</script>
