<template>
  <div class="">
    <div class="custom-table-wrapper">
      <v-data-table
          v-model="selectedCategories"
          :dense="true"
          :expanded="selectedCategories"
          :headers="headers"
          :hide-default-footer="true"
          :items="categories"
          :items-per-page="100000"
          show-select
      >

        <template v-slot:expanded-item="{ headers, item }">
<!--          <td></td>-->
          <td v-if="item.qualities.length" :colspan="headers.length" class="sm:pl-8">
            <small>Alegeti calitatea produselor din subcategoria <strong>{{item.name}}</strong></small>
            <quality-selector :category-id="item.id" :qualities="item.qualities"/>
          </td>
        </template>

      </v-data-table>
    </div>
    <error-messages :errors="errors.categories"/>
  </div>
</template>

<script>
import QualitySelector from '@/views/ProductGenerator/components/CategorySelector/QualitySelector'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'CategorySelector',
  components: {ErrorMessages, QualitySelector},
  data () {
    return {
      headers: [{text: 'Nume', value: 'name', sortable: false}],
      categories: [],
      selectedCategories: [],
    }
  },
  methods:{
    selectCategories(categories){
        this.selectedCategories = JSON.parse(JSON.stringify(categories))
    }
  },
  computed: {
    selectedCategoriesIds () {
      return this.selectedCategories.map(item => item.id)
    },
    categoryId () {
      return this.$store.getters['productGenerator/categoryId']
    },
    errors () {
      return this.$store.getters['productGenerator/errors']
    },
  },
  created () {
    this.$http.get(`product-categories/${this.categoryId}/children`)
      .then(({data}) => {
        this.categories = data
      })
  },
  watch: {
    selectedCategoriesIds (newVal) {
      this.$store.commit('productGenerator/SET_SUBCATEGORIES', newVal)
    }
  }
}
</script>
<style scoped>

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 5px;
  padding-right: 5px;
}

/*noinspection CssUnusedSymbol*/
.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded__content {
  box-shadow: none;
  background: #f5f5f5;
}

.custom-table-wrapper >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded__content > td {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
