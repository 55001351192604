<template>
  <base-locations-management subtitle="Judete">
    <cit-data-table
      :headers="headers"
      :includes="[
        'country',
        'zone'
      ]"
      resource-path="location/counties"
    >

      <template v-slot:actions>
        <create-form/>
      </template>

      <template v-slot:item.actions="{item}">
        <edit-form :item="item"/>
        <action-delete :action-url="`location/counties/${item.id}`"/>
      </template>

    </cit-data-table>
  </base-locations-management>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import EditForm from './Edit'
import BaseLocationsManagement from '@/views/Settings/LocationsManagement/BaseLocationsManagement'

export default {
  components: {
    BaseLocationsManagement,
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '80',
          sortable: true
        },
        {
          text: 'Cod',
          value: 'abbreviation',
          width: '80',
          sortable: true
        },
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Tara',
          value: 'country.name',
          sortable: false
        },
        {
          text: 'Zone',
          value: 'zone.name',
          sortable: false
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          align: 'center',
          width: '140',
          sortable: true
        }
      ]
    }
  }
}
</script>
