<template>
  <div>
    <img :src="require('@/assets/images/logo/logo.png')" alt="Cit Grup"/>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
