<template>
 <span class="button-wrapper">
   <vs-tooltip text="Editare">
    <v-btn
        v-on="listeners" :to="to"
        color="dark"
        icon
    >
      <v-icon x-small>fal fa-edit</v-icon>
      <slot></slot>
    </v-btn>
   </vs-tooltip>
  </span>
</template>

<script>
export default {
  name: 'EditButton',
  props: {
    to: {
      default: '',
      type: String | Object
    }
  },
  methods: {},
  computed: {
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>


<style scoped>
.button-wrapper {
  @apply inline-block
}
</style>

