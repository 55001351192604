<template>
  <div class="w-full">
    <v-text-field
        v-model="name"
        :disabled="loading"
        :loading="loading"
        append-icon="mdi-plus"
        hide-details
        label="Adauga valoare atribut nou"
        outlined
        placeholder="Ex: 3.5GHz"
        @click:append="addAttribute"/>
    <error-messages :errors="errors.name"/>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AttributeValueCreator',
  components: {ErrorMessages},
  props: {
    attrId: {}
  },
  data () {
    return {
      name: '',
      loading: false,
      errors: {}
    }
  },
  methods: {
    addAttribute () {
      this.loading = true

      this.$http.post(`attribute-values`, {
        name: this.name,
        attribute_id: this.attrId
      })
          .then(({data}) => {
            this.$vs.notify({
              title: 'Succes!',
              text: 'Atributul a fost salvat!',
              color: 'success'
            })
            this.name = ''
            this.$emit('attrCreated', data)
          })
          .catch(({response}) => {
            if (response.status === 422) {
              this.errors = response.data.errors
              this.$vs.notify({
                title: 'Eroare',
                text: 'Nu toate campurile obligatorii au fost completate.',
                color: 'danger'
              })
            } else {
              this.$vs.notify({
                title: 'Eroare',
                text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
                color: 'danger'
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>
