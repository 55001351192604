import axios from '@/axios'

export default {
  deleteItem ({dispatch}, id) {
    dispatch('startLoading', null, {root: true})
    return axios.delete(`/product-attributes/${id}`)
      .then(() => {
        dispatch('loadItems')

        dispatch('notify', {
          title: 'Succes',
          text: 'Elementul a fost sters cu succes!',
          color: 'success'
        }, {root: true})
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la stergere. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
        dispatch('stopLoading', null, {root: true})
      })
  },
  loadItems ({commit, dispatch, getters}) {
    dispatch('startLoading', null, {root: true})
    commit('CALCULATE_FILTER')
    return axios.get(`/product-attributes${getters.queryString}`)
      .then(({data}) => {
        commit('SET_ITEMS', data)
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })
      .then(() => {
        dispatch('stopLoading', null, {root: true})
      })
  },
  changePage ({commit, dispatch}, page) {
    commit('SET_PAGE', page)
    dispatch('loadItems')
  },
  deleteFilter ({commit, dispatch}, filterName) {
    commit('DELETE_FILTER_ITEM', filterName)
    dispatch('loadItems')
  },
  addFilter ({commit, dispatch}, filterName) {
    commit('ADD_FILTER_ITEM', filterName)
    dispatch('loadItems')
  },
  changeSort ({commit, dispatch}, sort) {
    commit('CHANGE_SORT', sort)
    dispatch('loadItems')
  },
  setType ({commit}, type) {
    commit('SET_TYPE', type)
  },
  storeItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/product-attributes', itemData)
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
          dispatch('stopLoading', null, {root: true})
        })
    })

  },
  updateItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post(`/product-attributes/${itemData.id}`, itemData.form)
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  },
  editBaseProductItem ({dispatch, commit}, item) {
    dispatch('sidebar/setCurrentActiveSidebarComponent', {
      component: 'edit-base-product-attribute',
      title: 'Editeaza Atributul'
    }, {root: true})
    commit('SET_EDIT_ITEM', item)
  },
  editProductComponentItem ({dispatch, commit}, item) {
    dispatch('sidebar/setCurrentActiveSidebarComponent', {
      component: 'edit-product-component-attribute',
      title: 'Editeaza Atributul'
    }, {root: true})
    commit('SET_EDIT_ITEM', item)
  }
}
