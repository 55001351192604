<template>
  <v-card flat>
    <v-card-text>
      <p>
        dsfsdf
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
    export default {
        name: "Qualities"
    }
</script>

<style scoped>

</style>
