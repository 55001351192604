<template>
  <v-sheet>
    <cit-data-table
      :appends="['categories']"
      :headers="headers"
      resource-path="product-component-types"
    >
      <template v-slot:actions>
        <create-component-type/>
      </template>

      <template v-slot:item.product_categories="{value}">
        <div v-for="(productCategory, index) in value" :key="index">
          {{ productCategory.name }}
        </div>
      </template>

      <template v-slot:item.actions="{item}">
        <edit-component-type :item="item"/>
        <action-delete :action-url="`product-component-types/${item.id}`"/>
      </template>
    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateComponentType from '@/views/ProductManagement/ComponentTypes/CreateComponentType'
import EditComponentType from '@/views/ProductManagement/ComponentTypes/EditComponentType'

export default {
  name: 'ComponentTypeList',
  components: {
    EditComponentType,
    CreateComponentType,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          filterType: 'text',
          sortable: true,
          width: '100'
        },
        {
          text: 'Categorie',
          value: 'product_categories',
          filterType: 'main-product-categories',
          sortable: false,
          width: '250'
        },
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text',
          sortable: false
        },
        {
          text: 'Poate avea stoc',
          value: 'has_stock',
          display: 'checkmarks',
          sortable: false,
          width: '100',
          align: 'center'
        }
      ]
    }
  }
}
</script>
