<template>
  <div class="select-filter">
    <v-select
      v-model="selectOption"
      :disabled="selectOption === 'Confirmat'"
      :items="allOptions"
      clearable
      dense
      hide-details
      item-text="text"
      item-value="value"
      label="Status"
      outlined
      single-line
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'StatusChanger',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  inject: ['table'],
  data () {
    return {
      allOptions: [
        {
          value: 'Confirmat',
          text: 'Confirmat'
        },
        {
          value: 'Anulata',
          text: 'Anulata'
        }
      ]
    }
  },
  computed: {
    selectOption: {
      get () {
        return this.item.status
      },
      set (value) {
        this.$http.patch(`reseller-websites/purchases/${this.item.id}`, {
          status: value
        })
          .then(() => {
            this.table.loadItems()
          })
          .catch()
      }
    }
  }
}
</script>

<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
