var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('action-add-new',{attrs:{"action-url":("product-categories/" + _vm.categoryId + "/banners"),"button-label":"Adauga Banner nou"},scopedSlots:_vm._u([{key:"fields",fn:function(ref){
var form = ref.form;
var errors = ref.errors;
return [_c('div',{staticClass:"mb-4"},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":"Link","outlined":""},model:{value:(form.link),callback:function ($$v) {_vm.$set(form, "link", $$v)},expression:"form.link"}}),_c('error-messages',{attrs:{"errors":errors.link}})],1),_c('div',{staticClass:"mb-4"},[_c('v-select',{attrs:{"items":[
          {
            label: 'Top',
            value:'top'
          },
          {
            label: 'Sidebar',
            value:'sidebar'
          } ],"item-text":"label","item-value":"value","label":"Pozitie","dense":"","outlined":"","hide-details":""},model:{value:(form.position),callback:function ($$v) {_vm.$set(form, "position", $$v)},expression:"form.position"}}),_c('error-messages',{attrs:{"errors":errors.position}})],1),_c('div',{staticClass:"mb-4"},[_c('image-uploader',{ref:"banner",attrs:{"label-idle":"Incarca bannerul"},model:{value:(form.image_id),callback:function ($$v) {_vm.$set(form, "image_id", $$v)},expression:"form.image_id"}}),_c('error-messages',{attrs:{"errors":errors.image_id}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }