<template>

  <span>
   <v-dialog v-model="dialogDisplay" class="action-add-new" max-width="250px" persistent>
      <v-card>
        <v-card-title class="text-center justify-center">
          Esti sigur ca vrei sa salvezi comanda?
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="cancel">Anuleaza</v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="triggerAction">Creaza</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          small
          @click="dialogDisplay = true"
        >
          <v-icon left>
            fal fa-save
          </v-icon>
          Creaza comanda
        </v-btn>
      </template>
      <span>Creaza comanda</span>
    </v-tooltip>
  </span>

</template>

<script>
export default {
  name: 'AgentConfirmsOrderButton',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      dialogDisplay: false,
      loading: false
    }
  },
  methods: {
    cancel () {
      this.loading = false
      this.dialogDisplay = false
    },
    triggerAction () {
      this.loading = true
      this.$http.patch(`orders/${this.order.id}/generate-order`)
        .then(() => {
          this.$emit('confirmed')
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.dialogDisplay = false
        })
    }
  }
}
</script>

