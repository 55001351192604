<template>
  <action-edit
    :action-url="`reseller-websites/${item.id}`"
    :item="item"
    button-label="Editeaza Tema"
  >
    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.name"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.price" dense hide-details label="Pret" outlined type="number"/>
        <error-messages :errors="errors.price"/>
      </div>

      <div class="mb-4">
        <date-picker disable-internal-format-date v-model="form.start_at" dense hide-details label="Valabil din data" outlined/>
        <error-messages :errors="errors.start_at"/>
      </div>

      <div class="mb-4">
        <date-picker disable-internal-format-date v-model="form.end_at" dense hide-details label="Valabil pana in data" outlined/>
        <error-messages :errors="errors.end_at"/>
      </div>

      <div class="mb-4">
        <v-switch v-model="form.is_active" dense hide-details
                  label="Tema este activa"
                  outlined/>
        <error-messages :errors="errors.is_active"/>
      </div>


    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    Base64FileUploader,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
