<template>
  <div class="select-filter">
    <v-autocomplete
      v-model="selectOption"
      :items="dataOptions"
      :label="label"
      :loading="loading"
      :search-input.sync="search"
      class="filter-select text-sm"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="machine_name"
      outlined
      single-line
    >

    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'OrderPaymentMethodSelector',
  props: {
    value: {},
    label: {
      default: 'Selecteaza metoda de plata'
    }
  },
  data () {
    return {
      paginatedData: [],
      loading: false,
      search: ''
    }
  },
  computed: {
    dataOptions () {
      return Object.values(this.paginatedData)
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        const option = val || ''
        this.$emit('input', option)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    },
    customerId () {
      this.loadItems()
    }
  },
  methods: {
    loadItems (search) {
      this.paginatedData = []
      search = search || ''
      this.$http.get(`/orders/payment-methods`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>


<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>

