<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="yellow"
        icon
        @click="makeDefault"
      >
        <v-icon>fal fa-star</v-icon>
      </v-btn>
    </template>
    <span>Seteaza ca predefinit</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  inject: ['table'],
  methods: {
    makeDefault () {
      this.table.startLoading()
      this.$http.patch(`newsletter/templates/${this.item.id}/toggle-default`)
        .then(() => {
          this.table.loadItems()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la setare, va rugam reimprospatati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.table.stopLoading()
        })
    }
  }
}
</script>
