<template>
  <div>
    <v-sheet>
      <v-app-bar color="white hidden-md-and-up" elevate-on-scroll>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>

      <v-row class="elevation-2" style="position: relative">

          <v-navigation-drawer v-model="drawer" :permanent="$vuetify.breakpoint.mdAndUp" height="auto" hide-overlay>
            <div>
              <v-list class="py-0" dense nav>
                <v-list-item
                    v-for="(category, index) in categories" :key="index"
                    :to="{name:'feed-connectors-generals-category',params:{categoryId:category.id}}"
                    link
                >
                  <v-list-item-content>
                    <v-list-item-title> {{ category.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-navigation-drawer>

        <v-col class="pa-0 custom-column-width">
          <slot></slot>
        </v-col>
      </v-row>

    </v-sheet>
  </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: null,
      categories: []
    }
  },
  created () {
    this.$http.get('product-categories/parents-only')
        .then(({data}) => {
          this.categories = data
        })
  }
}
</script>


<style lang="scss" scoped>
.v-navigation-drawer {
  position: absolute;
  z-index: 9;
  @apply py-4;
  @screen lg {
    position: inherit;
  }
}
.custom-column-width {
  @screen lg {
    max-width: calc(100% - 256px);
  }
}
</style>
