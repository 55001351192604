<template>
  <cit-data-table
    :headers="headers"
    :resource-path="`product-categories/${categoryId}/banners`"
  >
    <template v-slot:actions>
      <create-form :category-id="categoryId"/>
    </template>

    <template v-slot:item.actions="{item}">
      <action-delete :action-url="`product-categories/${categoryId}/banners/${item.id}`"/>
    </template>

  </cit-data-table>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'

export default {
  components: {
    ActionDelete,
    CreateForm,
    CitDataTable
  },
  computed: {
    categoryId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Image',
          value: 'image',
          display: 'image',
          width: '120',
          sortable: false
        },
        {
          text: 'Position',
          value: 'position',
          width: '150',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Top',
              value: 'top'
            },
            {
              text: 'Sidebar',
              value: 'sidebar'
            }
          ],
          sortable: false
        },
        {
          text: 'Link',
          value: 'link',
          sortable: false
        }
      ]
    }
  }
}
</script>
