<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      hide-filters
      per-page="100"
      resource-path="customer-benefit"
      show-expand
    >

      <template v-slot:actions>
        <create-form/>
      </template>

      <template v-slot:expanded-item="{item}">
        {{ item.description }}
      </template>
      <template v-slot:item.actions="{item}">

        <action-delete :action-url="`customer-benefit/${item.id}`"/>

        <edit-form :item="item"/>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import EditForm from './Edit'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Titlu ',
          value: 'title',
          sortable: false
        },
        {
          align: 'center',
          text: 'Puncte necesare',
          value: 'required_points',
          sortable: false,
          width: 100
        },
        {
          align: 'center',
          text: 'Imagine Premiu',
          value: 'image',
          display: 'image',
          sortable: false,
          width: 100
        },
        {
          text: 'Nume premiu',
          value: 'name',
          sortable: false
        }
      ]
    }
  }
}
</script>
