export default {

  configData (state) {
    return state.configData
  },
  categoryId (state) {
    return state.configData.category_id
  },
  baseProductId (state) {
    return state.configData.base_product_id
  },
  shortNameRecipe (state) {
    return state.configData.short_name_recipe
  },

  selectedQualities (state) {
    return state.configData.qualities
  },

  // Warranties
  selectedWarranties (state) {
    return state.configData.selectedWarranties
  },
  bufferWarranties(state){
    return state.bufferWarranties
  },

  // Publish Locations
  isPublishedToCit (state) {
    return state.configData.is_published_to_cit
  },
  isPublishedToResellers (state) {
    return state.configData.is_published_to_resellers
  },
  isPublishedToFeed (state) {
    return state.configData.is_published_to_feed
  },

  // Components
  slots (state) {
    return state.configData.slots
  },
  slotsCount (state) {
    return state.configData.slots.length
  },
  // Errors
  errors (state) {
    return state.errors
  },


  // Helpers
  cachedComponentTypes (state) {
    return state.cachedComponentTypes
  },
  isEditing (state) {
    return state.isEditing
  },
  isNotEditing (state) {
    return !state.isEditing
  },
  nameGenerationMethods (state) {
    return state.nameGenerationMethods
  }
}
