<template>
  <cit-settings-manager action-url="settings/page-reseller/benefits-section">

    <template v-slot:fields="{form, errors}">
      <error-messages :errors="errors"/>

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlul sectiunii" outlined/>
        <error-messages :errors="errors.title"/>
      </div>

      <benefits-repeater v-model="form.benefits"/>
    </template>

  </cit-settings-manager>

</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CitSettingsManager from '@/components/layout/CitSettings/CitSettingsManager'
import BenefitsRepeater from '@/views/Settings/PageResellerSettings/components/BenefitesRepeater'


export default {
  components: {
    BenefitsRepeater,
    ErrorMessages,
    CitSettingsManager
  }
}
</script>
