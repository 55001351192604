<template>
  <div class="block lg:inline-block relative">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="{...on,...listeners}"
          :color="color"
          :dark="dark"
          :href="href"
          :icon="isIcon"
          :loading="loading"
          :target="target"
          :to="to"
          class="w-full md:w-auto md:mx-4 mb-2"
        >
          <template v-if="icon">
            <v-icon :class="{'mr-2' : !isIcon}" small>{{ icon }}</v-icon>
          </template>
          <template v-if="!isIcon">
            {{ text }}
          </template>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'BasicButton',
  props: {
    icon: {
      type: String,
      default: ''
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    to: {
      default: '',
      type: String | Object
    },
    target: {
      default: '_BLANK'
    },
    href: {
      default: '',
      type: String | Object
    },
    text: {
      default: 'Vizualizare',
      type: String
    },
    tooltip: {
      default: '',
      type: String
    },
    color: {
      type: String,
      default: 'primary'
    },
    dark: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tooltipText () {
      return this.tooltip || this.text
    },
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>
