<template>
  <v-sheet>
    <div v-for="(item, index) in dynamicValue" :key="index">
      <div class="text-right mb-3">
        <v-btn
          icon
          @click="removeItem(index)"
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </div>

      <div class="d-flex items-center mb-4">
        <span class="mr-3 w-2/12">Titlu</span>
        <v-text-field
          v-model="item.title"
          class="mt-0"
          dense
          hide-details
          label="Titlu"
          outlined
        ></v-text-field>
      </div>

      <div class="d-flex items-center mb-4">
        <span class="mr-3 w-2/12">Descriere</span>
        <v-text-field
          v-model="item.description"
          class="mt-0"
          dense
          hide-details
          label="Descriere"
          outlined
        ></v-text-field>
      </div>

      <div class="d-flex items-center mb-4">
        <div class="mr-3 w-2/12">Iconita</div>
        <div class="w-full">
          <div v-if="item.image">
            <v-img
              :src="item.image"
              class="mb-4"
              contain
              max-height="150"
              max-width="500"
            />
          </div>
          <base64-file-uploader
            ref="avatar"
            v-model="item.image_file"
            label="Iconita"
          />
        </div>
      </div>

      <div class="mb-4">
        <v-divider/>
      </div>

    </div>
    <v-btn @click="addNew">Adauga unul nou</v-btn>
  </v-sheet>
</template>

<script>
import ImageUploader from '@/components/general-form/ImageUploader'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    Base64FileUploader,
    ImageUploader
  },
  props: {
    value: {}
  },
  computed: {
    dynamicValue: {
      get () {
        return this.value
      },
      set (val) {
        val = val || []
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addNew () {
      if (typeof this.dynamicValue == 'undefined') {
        this.dynamicValue = [
          {
            title: ''
          }
        ]
      } else {
        this.dynamicValue.push({
          title: ''
        })
      }
    },
    removeItem (index) {
      this.dynamicValue.splice(index, 1)
    }
  },
  created () {
    if (typeof this.value == 'undefined') {
      this.$emit('input', [])
    }
  }
}
</script>

