<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col>
        <v-card-title>Black Firday</v-card-title>
        <v-switch
          v-model="form.black_friday_active"
          :false-value="0"
          :true-value="1"
          label="Afisare Black Friday"
        ></v-switch>
        <error-messages :errors="errors.black_friday_active"/>

      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" dark large @click="saveSettings">
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages
  },
  data () {
    return {
      form: {
        black_friday_active: false
      },
      errors: {}
    }
  },
  methods: {
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'black_friday_active',
            value: this.form.black_friday_active
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(() => {
          this.$store.dispatch('notify', {
            title: 'Eroare!',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          }, {root: true})
        })
    }
  },
  mounted () {
    this.$http.get('settings?settings[]=black_friday_active')
      .then(({data}) => {
        this.form.black_friday_active = parseInt(data.black_friday_active)
      })

  }
}
</script>
