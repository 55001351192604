<template>
  <div>
    <vue-editor
      v-model="text"
      :customModules="customModulesForEditor"
      :editorOptions="editorConfig"
      :useCustomImageHandler="true"
      @image-added="handleImageAdded"
      @image-removed="handleImageRemoved"
    ></vue-editor>
  </div>
</template>

<script>
import {VueEditor} from 'vue2-editor'
import {ImageDrop} from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize'

export default {
  name: 'Wysiwyg',
  components: {VueEditor},
  props: {
    value: {}
  },
  data () {
    return {
      customModulesForEditor: [
        {
          alias: 'imageDrop',
          module: ImageDrop
        },
        {
          alias: 'imageResize',
          module: ImageResize
        }
      ],
      editorConfig: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      }
    }
  },
  computed: {
    text: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    handleImageRemoved () {
      // @todo remove image
      console.log('image removed')
    },
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {

      const formData = new FormData()
      formData.append('image', file)

      this.$http.post('uploader/image', formData)
        .then(({data}) => {
          const url = data.src // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          if (err.response.status === 422) {
            const errors = err.response.data.errors.image
            alert(errors)
          }
        })
    }
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.ck-editor__editable_inline {
  min-height: 400px;
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 9999000;
}
</style>
