<template>
  <v-sheet>
    <cit-data-table
      :appends="[
        'split_customer_count'
      ]"
      :headers="headers"
      :includes="[
        'customersCount'
      ]"
      resource-path="users"
    >

      <template v-slot:actions>
        <create-form/>
      </template>

      <template v-slot:item.split_customer_count="{item}">

        <table class="w-full">
          <tr class="font-weight-bold">
            <td>Total</td>
            <td align="right" width="50">{{ item.customers_count }}</td>
          </tr>

          <tr v-for="(count, index) in item.split_customer_count " :key="index">
            <td>{{ count.name }}</td>
            <td align="right" width="50">{{ count.count }}</td>
          </tr>
        </table>

      </template>

      <template v-slot:item.actions="{item}">

        <view-button :to="{name:'user-activity', params:{id:item.id}}" icon="fal fa-tasks-alt" target="_self"
                     text="Istoric agent"/>

        <action-delete :action-url="`users/${item.id}`"/>

        <reset-password-button :user="item"/>

        <edit-form :item="item"/>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ViewButton from '@/components/general-form/ViewButton'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ResetPasswordButton from '@/views/Users/ResetPasswordButton'
import CreateForm from './Create'
import EditForm from './Edit'

export default {
  components: {
    ResetPasswordButton,
    ActionDelete,
    ViewButton,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65'
        },
        {
          text: 'Avatar',
          value: 'avatar',
          display: 'image',
          align: 'center',
          width: '100',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'full_name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Email',
          value: 'email',
          filterType: 'text',
          display: 'mail',
          sortable: true
        },
        {
          text: 'Phone',
          value: 'phone',
          filterType: 'text',
          width: '150',
          align: 'center',
          display: 'phone',
          sortable: true
        },
        {
          text: 'Clienti',
          value: 'split_customer_count',
          width: 250
        },
        {
          text: 'Vizibil in website',
          value: 'public_display',
          filterType: 'boolean',
          display: 'boolean',
          width: '150'
        }
      ]
    }
  }
}
</script>
