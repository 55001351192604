var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"fixed-filters":{
      customer:_vm.customerId
    },"headers":_vm.headers,"includes":[
      'customer',
      'customer.agent',
      'customer.company'
    ],"hide-filters":"","resource-path":"analytics"},scopedSlots:_vm._u([{key:"item.referrer_page",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.full_referrer_page,"target":"_blank"}},[_vm._v(_vm._s(item.referrer_page))])]}},{key:"item.accessed_page",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.full_accessed_page,"target":"_blank"}},[_vm._v(_vm._s(item.accessed_page))])]}},{key:"item.time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toSeconds")(item.time))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }