<template>
  <div>
    <div class="flex items-end">
      <base-product-selector v-model="selectedBaseProduct" :category-id="category" :disabled="$store.getters['productGenerator/isEditing']"/>
      <vs-tooltip text="Creaza un produs de baza nou" v-if="$store.getters['productGenerator/isNotEditing']">
        <base-product-creator v-if="!disabled" :for-category="category" activator-is-only-icon @added="selectNewBrand"/>
      </vs-tooltip>
    </div>
    <error-messages :errors="errors.base_product_id"/>

  </div>
</template>

<script>
import BaseProductSelector from '@/components/autocompletes/BaseProductSelector'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import BaseProductCreator from '@/views/ProductManagement/BaseProductManager/BaseProductCreator'

export default {
  name: 'BaseProductSelect',
  components: {BaseProductCreator, ErrorMessages, BaseProductSelector},
  props: {
    disabled: {
      default: false
    }
  },
  computed: {
    category () {
      return this.$store.getters['productGenerator/categoryId']
    },
    selectedBaseProduct: {
      get () {
        return this.$store.getters['productGenerator/baseProductId']
      },
      set (value) {
        return this.$store.commit('productGenerator/SET_BASE_PRODUCT_ID', value)
      }
    },
    errors () {
      return this.$store.getters['productGenerator/errors']
    }
  },
  methods: {
    selectNewBrand (brand) {
      this.$store.commit('productGenerator/SET_BASE_PRODUCT_ID', brand.id)
    }
  }
}
</script>
