<template>
  <v-autocomplete
    v-model="selectOption"
    :disabled="disabled"
    :items="searchedList"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    cache-items
    class="filter-select text-sm"
    clearable
    dense
    hide-details
    item-text="name"
    item-value="id"
    outlined
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {},
    label: {
      default: 'Calitate'
    },
    disabled: {
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paginatedData: {},
      loading: false,
      search: ''
    }
  },
  computed: {
    searchedList () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || ''
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/product-qualities?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
