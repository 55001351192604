<template>
  <v-sheet>

    <stock-updater :component="stockUpdater.item" :dialog="stockUpdater.isOpened" @close="closeStockUpdater"/>

    <cit-data-table
      :appends="[
        'has_attribute_values',
        'badges_ids'
      ]"
      :headers="headers"
      :includes="[
        'categories.parent',
        'group'
      ]"
      resource-path="product-components"
    >

      <template v-slot:actions>
        <create-component/>
      </template>

      <template v-slot:item.categories="{item}">
        <div v-if="item.categories.length" class="category-column">
          <div v-for="category in item.categories" :key="category.id" class="category-name-list"
               v-html="getTitleWithParent(category)">
          </div>
        </div>
      </template>

      <template v-slot:item.display_order="{item}">
        <text-inline-field
          :action-url="`product-components/${item.id}/display-order`"
          :value="item.display_order"
          field-name="display_order"
          type="number"
        />
      </template>

      <template v-slot:item.actions="{item}">
        <action-delete :action-url="`product-components/${item.id}`"/>
        <edit-component :item="item"/>

        <v-tooltip bottom max-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              bottom
              icon
              @click="cloneItem(item)"
            >
              <v-icon>fal fa-clone</v-icon>
            </v-btn>
          </template>
          <span>Cloneaza componenta <strong>(fara atribute)</strong></span>
        </v-tooltip>

        <v-tooltip bottom max-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :to="{name:'pm-component-attributes', params:{id:item.id}}"
              bottom
              icon
            >
              <v-icon>fal fa-calculator-alt</v-icon>
            </v-btn>
          </template>
          <span>Vezi atributele acestei componente</span>
        </v-tooltip>

        <v-tooltip bottom max-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              bottom
              icon
              @click.stop="openStockUpdated(item)"
            >
              <v-icon>fal fa-balance-scale</v-icon>
            </v-btn>
          </template>
          <span>Actualizare stoc și uniformizare</span>
        </v-tooltip>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import StockUpdater from '@/views/ProductManagement/Components/components/StockUpdater'
import CreateComponent from '@/views/ProductManagement/Components/CreateComponent'
import EditComponent from '@/views/ProductManagement/Components/EditComponent'
import TextInlineField from '@/components/layout/InlineEditors/TextInlineField'

export default {
  name: 'ComponentsList',
  components: {
    TextInlineField,
    EditComponent,
    CreateComponent,
    StockUpdater,
    ActionDelete,
    DeleteButton,
    EditButton,
    NewButton,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          filterType: 'text',
          sortable: true,
          width: '100'
        },
        {
          text: 'Categorii',
          value: 'categories',
          filterType: 'main-product-categories',
          sortable: false,
          width: '150'
        },
        {
          text: 'Tip',
          value: 'type.name',
          filterType: 'component-type',
          filterName: 'component_type_id',
          width: '150',
          sortable: false
        },
        {
          text: 'Grupa',
          value: 'group.name',
          filterType: 'component-group',
          filterName: 'group_id',
          width: '150',
          sortable: false
        },
        {
          text: 'Denumire Gestiune',
          value: 'internal_name',
          filterType: 'text',
          sortable: true,
          width: '200'
        },
        {
          text: 'Denumire Scurta',
          value: 'name',
          filterType: 'text',
          sortable: true,
          width: '200'
        },
        {
          text: 'Stoc',
          value: 'stock',
          align: 'center',
          sortable: true,
          width: '100'
        },
        {
          text: 'Pret Achizitie',
          value: 'buying_price',
          align: 'center',
          sortable: true,
          width: '100'
        },
        {
          text: 'Pret',
          value: 'price',
          align: 'center',
          sortable: true,
          width: '100'
        },
        {
          text: 'Are Atribute?',
          value: 'has_attribute_values',
          display: 'checkmarks',
          width: '100',
          align: 'center',
          sortable: false
        },
        {
          text: 'Ordinea',
          value: 'display_order',
          width: '110',
          align: 'center',
          sortable: true
        }
      ],
      stockUpdater: {
        item: {},
        isOpened: false
      }
    }
  },
  methods: {
    getTitleWithParent (item) {
      let title = ''
      if (item.parent !== null) {
        title = `${this.getTitleWithParent(item.parent)} - ${title}`
      }
      return `${title}<span>${item.name}</span>`
    },
    cloneItem (item) {
      this.$store.dispatch('components/setCloneItem', item)
        .then(() => {
          this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
            component: 'add-component',
            title: 'Adauga Componenta noua'
          })
        })
    },
    openStockUpdated (item) {
      this.stockUpdater.item = item
      this.stockUpdater.isOpened = true
    },
    closeStockUpdater () {
      this.stockUpdater.item = {}
      this.stockUpdater.isOpened = false
    }
  }
}
</script>
