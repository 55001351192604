<template>
  <v-dialog v-model="dialog" width="500">

    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="light-gray" small>
        Alege Garantia
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="">
        Alege Garantia extinsa
      </v-card-title>

      <v-card-text>
        <product-extra-warranty-selector v-model="warranty" return-object/>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="close">
          Anuleaza
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="select">
          Alege Garantia
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductExtraWarrantySelector from '@/components/autocompletes/ProductExtraWarrantySelector'

export default {
  name: 'ProductExtraWarrantySelectorDialog',
  components: {ProductExtraWarrantySelector},
  data () {
    return {
      dialog: false,
      warranty: ''
    }
  },
  methods: {
    select () {
      if(this.warranty){
        this.$emit('input', this.warranty)
      }
      this.close()
    },
    close () {
      this.warranty = ''
      this.dialog = false
    }
  }
}
</script>
