<template>
  <v-row>
    <v-col cols="7">
      <v-card>
        <v-card-title>Atribute</v-card-title>
        <v-card-text>

          <div v-if="attributes.length > 0">
            <v-data-table
              :headers="headers"
              :hide-default-footer="true"
              :items="attributes"
            >
              <template v-slot:body="props">
                <draggable v-model="attributes" tag="tbody">
                  <tr v-for="(item, index) in attributes" :key="index" class="custom-tr children cursor-move">
                    <td style="width: 65px">
                      <vs-icon icon="fa-bars" icon-pack="fal"></vs-icon>
                    </td>
                    <td>{{ item.attributeName.full_name }}</td>
                    <td>{{ item.name }}</td>
                    <td class="text-right" style="width: 100px">
                      <delete-button without-confirmation @delete="deleteItem(item)"></delete-button>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </div>
          <div v-else> Nu este nici un atribut selectat</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-card>
        <v-card-title>Creaza tip de atribut nou</v-card-title>
        <v-card-text>
          <create-attribute @created="reloadAttributeTypes"/>
        </v-card-text>
      </v-card>
      <attribute-and-value-selector
        ref="selector"
        :attributes="attributes"
        class="mt-5 bg-grey-light"
        @add-attribute="addAttribute"
      />
    </v-col>
  </v-row>
</template>
<script>
import draggable from 'vuedraggable'
import DeleteButton from '@/components/general-form/DeleteButton'
import SearchOrCreateAttribute from '@/views/ProductManagement/Components/components/SearchOrCreateAttribute'
import CreateAttributeForCategory from '@/views/ProductManagement/Attributes/CreateAttribute'
import CreateAttribute from '@/views/ProductManagement/Attributes/CreateAttribute'
import AttributeAndValueSelector
  from '@/views/ProductManagement/Attributes/AttributeAndValueSelector/AttributeAndValueSelector'

export default {
  name: 'BaseProductAttributeManager',
  components: {
    CreateAttribute,
    AttributeAndValueSelector,
    CreateAttributeForCategory,
    SearchOrCreateAttribute,
    DeleteButton,
    draggable
  },
  props: {
    baseProduct: {
      required: true
    }
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'handler',
          width: '65',
          sortable: false
        },
        {
          text: 'Atribut',
          value: 'attribute_name.name',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: '100',
          sortable: false,
          align: 'right'
        }
      ],
      attributes: [],
      attrTypes: []
    }
  },
  computed: {
    attributeIdsList () {
      return this.attributes.map((item) => {
        return item.id
      })
    },
    categoryName () {
      if (this.baseProduct.category) {
        return this.baseProduct.category.name
      }
    }
  },
  methods: {
    save () {
      return new Promise((resolve) => {
        this.$store.dispatch('startLoading')
        this.$http.post(`base-products/${this.baseProduct.id}/attributes`, {attributes: this.attributeIdsList})
          .then(() => {
            resolve(this.baseProduct)
            this.$vs.notify({
              title: 'Succes!',
              text: 'Atributele au fost salvate!',
              color: 'success'
            })
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare la salvare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          })
          .finally(() => {
            this.$store.dispatch('stopLoading')
          })
      })
    },
    addAttribute (item) {
      this.attributes.push(item)
    },
    deleteItem (item) {
      this.attributes.splice(this.attributes.findIndex(function (i) {
        return i.id === item.id
      }), 1)
    },
    loadItems () {
      this.$http.get(`base-products/${this.baseProduct.id}/attributes`)
        .then(({data}) => {
          this.attributes = data
        })
    },
    reloadAttributeTypes () {
      this.$refs.selector.getAttributeTypes()
    }
  },
  created () {
  },
  watch: {
    baseProduct: {
      deep: true,

      handler (value) {
        if (value) {
          this.loadItems()
        }
      }
    }
  }
}
</script>
