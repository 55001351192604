import axios from '@/axios'

export default {
  loadItems ({commit, getters}) {
    return new Promise((resolve, reject) => {
      axios.get(`product-categories/${getters.categoryId}/filters`)
        .then((response) => {
          commit('SET_FILTERS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveItems ({getters}) {
    return new Promise((resolve, reject) => {
      axios.post(`product-categories/${getters.categoryId}/filters`, {
        filters: getters.filters
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  addNewFilter ({commit}, filter) {
    commit('ADD_FILTER', filter)
  }
}
