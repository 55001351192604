<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="pages"
    >
      <template v-slot:actions>
        <slot name="actions">
          <new-button :to="{name:'settings-page-management-new'}">Adauga Pagina noua</new-button>
        </slot>
      </template>

      <template v-slot:item.title="{item}">
        <div>{{ item.title }}</div>
        <div>
          <span class="font-italic text-xs">{{ item.absolute_permalink }}</span>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <edit-button :to="{'name': 'settings-page-management-edit', params:{page:item.id}}"></edit-button>
        <action-delete :action-url="`pages/${item.id}`"/>
        <view-button :href="item.absolute_permalink"></view-button>
      </template>

    </cit-data-table>
  </v-sheet>

</template>

<script>
import EditButton from '@/components/general-form/EditButton'
import NewButton from '@/components/general-form/NewButton'
import ViewButton from '@/components/general-form/ViewButton'
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'

export default {
  name: 'PageList',
  components: {
    ActionDelete,
    CitDataTable,
    ViewButton,
    EditButton,
    NewButton
  },
  data () {
    return {
      headers: [
        {
          text: 'id',
          value: 'id',
          sortable: false,
          width: '60'
        },
        {
          text: 'Titlu',
          value: 'title',
          filterType: 'text',
          sortable: false
        }
      ]
    }
  }
}
</script>
