<template>
  <v-autocomplete
    v-model="selectOption"
    :items="categories"
    :label="label"
    :loading="loading"
    :menu-props="{ maxHeight: '400' }"
    :return-object="returnObject"
    :search-input.sync="search"
    class="filter-select text-sm"
    dense
    hide-details
    hide-no-data
    item-text="description"
    item-value="nod_id"
    multiple
    no-filter
    outlined
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0">
        <span>{{ selectOption.length }} defecte selectate</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'NodFaultsMultiselect',
  props: {
    value: {},
    returnObject: {
      default: false
    },
    label: {
      default: 'Defecte'
    }
  },
  data () {
    return {
      paginatedContent: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    categories () {
      return this.paginatedContent.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/feed-connectors/nod/fault-codes?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedContent = data.data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
