<template>
  <v-sheet :elevation="0">
    <cit-data-table
      :headers="headers"
      :resource-path="`activity/user/${userId}`"
      disable-actions
      hide-filters
      per-page="12"
    >
      <template v-slot:actions>
        <div>
          Agent <strong>{{ user.full_name }}</strong>
        </div>
      </template>

      <template v-slot:item.values="{item}">
        <div v-if="item.type === 'product_config_added'">
          <products-added :values="item.values"/>
        </div>
        <div v-if="item.type === 'product_deleted'">
          <products-deleted :values="item.values"/>
        </div>
        <div v-if="item.type === 'product_config_edited'">
          <product-edited :values="item.values"/>
        </div>
        <div v-if="item.type === 'user_customer_ordered_finished'">
          <user-customer-client-order-finished :values="item.values"/>
        </div>
        <div v-if="item.type === 'customer_finished_order'">
          <client-order-finished :values="item.values"/>
        </div>

        <div v-if="item.type === 'user_deleted_customer'">
          <client-deleted :values="item.values"/>
        </div>
        <div v-if="item.type === 'user_created_customer'">
          <client-created :values="item.values"/>
        </div>
        <div v-if="item.type === 'user_customer_message_created'">
          <client-message-created :values="item.values"/>
        </div>
        <div v-if="item.type === 'user_customer_ordered'">
          <order-placed :values="item.values"/>
        </div>

      </template>
    </cit-data-table>
  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ProductsAdded from '@/views/Users/SingleUser/components/ProductsAdded'
import ProductsDeleted from '@/views/Users/SingleUser/components/ProductsDeleted'
import ProductEdited from '@/views/Users/SingleUser/components/ProductEdited'
import ClientDeleted from '@/views/Users/SingleUser/components/ClientDeleted'
import ClientCreated from '@/views/Users/SingleUser/components/ClientCreated'
import OrderPlaced from '@/views/Users/SingleUser/components/OrderPlaced'
import ClientMessageCreated from '@/views/Users/SingleUser/components/ClientMessageCreated'
import ClientOrderFinished from '@/views/Users/SingleUser/components/ClientOrderFinished'
import UserCustomerClientOrderFinished from '@/views/Users/SingleUser/components/UserCustomerClientOrderFinished'

export default {
  components: {
    UserCustomerClientOrderFinished,
    ClientOrderFinished,
    ClientMessageCreated,
    OrderPlaced,
    ClientCreated,
    ClientDeleted,
    ProductEdited,
    ProductsDeleted,
    ProductsAdded,
    CitDataTable
  },
  computed: {
    userId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Tip Activitate',
          value: 'label',
          width: '200',
          sortable: false
        },
        {
          text: 'Info',
          value: 'values',
          sortable: false
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          width: '150'
        }
      ],
      user: {
        full_name: ''
      }
    }
  },
  methods: {
    loadUser () {
      this.$http.get(`/users/${this.userId}`)
        .then(({data}) => {
          this.user = data
        })
        .catch((e) => {
          this.$vs.notify({
            title: 'Eroare',
            text: e.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {

        })
    }
  },
  created () {
    this.loadUser()
  }
}
</script>
