export default {
  getDiscountOptions (state) {
    return state.possibleDiscounts
  },
  productStatuses (state) {
    const productStatuses = state.productStockStatuses
    return Object.assign({}, ...productStatuses.map(item => ({[item.id]:item.value})))
  },
  productStockStatuses (state) {
    return state.productStockStatuses
  },
  defaultProductStatus (state) {
    return state.defaultProductStatus
  }
}
