<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="reseller-discounts"
      per-page="100"
      hide-filters
    >

      <template v-slot:actions>
        <create-form/>
      </template>


      <template v-slot:item.actions="{item}">

        <action-delete :action-url="`reseller-discounts/${item.id}`"/>

        <edit-form :item="item"/>

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'
import EditForm from './Edit'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'De la',
          value: 'start',
          sortable: false
        },
        {
          text: 'Pana la',
          value: 'end',
          sortable: false
        },
        {
          text: 'Discount',
          value: 'discount_index',
          sortable: false
        },
        {
          text: 'Adaos maximal client distributie',
          value: 'max_reseller_multiplier',
          sortable: false
        }
      ]
    }
  }
}
</script>
