<template>
  <section class="data-list-container">

    <div class="mb-5">
      <new-button class="mr-5" @click="openAddNew">Adauga Grup de atribute nou</new-button>
    </div>

    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :hide-default-footer="true"
          :items="paginatedItems"
          @update:sort-by="updateSortField"
          @update:sort-desc="updateSortType"
        >

          <template v-slot:item.actions="{item}">
            <edit-button @click="editItem(item)"></edit-button>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-pagination
          :length="total_pages"
          :total-visible="5"
          :value="currentPage"
          color="#76bd43"
          @input="changePage"
        ></v-pagination>
      </v-card-actions>
    </v-card>

  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'

export default {
  name: 'AttributeGroupList',
  components: {
    EditButton,
    NewButton
  },
  data () {
    return {
      headers: [
        {
          text: 'Denumire',
          value: 'name'
        },
        {
          text: 'Ordine afisare',
          value: 'display_order',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: '150',
          sortable: false
        }
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    totalItems () {
      return this.paginatedItems.length
    },
    total_pages () {
      return this.$store.getters['attributeValueGroup/last_page']
    },
    paginatedItems () {
      return this.$store.getters['attributeValueGroup/paginatedData']
    },
    currentPage () {
      return this.$store.getters['attributeValueGroup/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-attribute-group',
        title: 'Adauga Grup de atribute'
      })
    },
    editItem (item) {
      this.$store.dispatch('attributeValueGroup/editItem', item)
    },
    changePage (newPage) {
      this.$store.dispatch('attributeValueGroup/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('attributeValueGroup/deleteFilter', filterName)
      } else {
        this.$store.dispatch('attributeValueGroup/addFilter', {
          filterName,
          searchValue
        })
      }
    },
    loadItems () {
      this.$store.dispatch('attributeValueGroup/loadItems')
    },
    deleteItem (item) {
      this.$store.dispatch('attributeValueGroup/deleteItem', item.id)
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('attributeValueGroup/changeSort', newValue)
      }
    }
  },
  created () {
    this.loadItems()
  }
}
</script>
