<template>
  <v-autocomplete
      v-model="selectOption"
      :items="componentTypes"
      :label="label"
      :loading="loading"
      :search-input.sync="search"
      cache-items
      small-chips
      class="filter-select text-sm"
      dense
      hide-details
      hide-no-data
      item-text="name"
      item-value="id"
      multiple
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'ClientInterestListSelect',
  props: {
    value: {
      default () {
        return []
      }
    },
    label: {
      default: 'Seteaza Listele de interes'
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    componentTypes () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || []
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/clients/interest-lists?filter[name]=${search}`)
          .then(({data}) => {
            this.paginatedData = data
            this.loading = false
          })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
