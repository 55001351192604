<template>
  <action-add-new
    action-url="product-component-types"
    button-label="Adauga tip de componenta nou"
    dialog-width="500px"
    @saved="emitBusEvent"
  >

    <template v-slot:title>
      Adauga un nou tip de componenta
    </template>

    <template v-slot:fields="{form, errors}">
      <div class="mb-5">
        <v-text-field v-model="form.name" dense hide-details label="Nume" outlined/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-5">
        <v-select
          v-model="form.categories"
          :items="categories"
          dense
          hide-details
          item-text="name"
          item-value="id"
          label="Categorii"
          multiple
          outlined
        >
        </v-select>
        <error-messages :errors="errors.categories"></error-messages>
      </div>

      <div class="mb-5">
        <v-select
          v-model="form.has_stock"
          :items="has_stock"
          dense
          hide-details
          item-text="text"
          item-value="value"
          label="Posibilitate de stoc?"
          outlined
        >
        </v-select>
        <error-messages :errors="errors.has_stock"></error-messages>
      </div>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import {EventBus} from '@/plugins/event-bus'

export default {
  name: 'CreateComponentType',
  components: {
    ErrorMessages,
    ActionAddNew
  },
  data () {
    return {
      categories: [],
      has_stock: [
        {
          value: 0,
          text: 'Componentele NU au stoc'
        },
        {
          value: 1,
          text: 'Componentele au stoc'
        }
      ]
    }
  },
  methods: {
    loadParents () {
      this.$http.get('/product-categories/parents-only')
        .then(({data}) => {
          this.categories = data
        })
    },
    emitBusEvent () {
      EventBus.$emit('component-type-created')
    }
  },
  created () {
    this.loadParents()
  }
}
</script>
