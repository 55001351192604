<template>
  <cit-data-table
    :headers="headers"
    :includes="['categories']"
    resource-path="feeds"
  >

    <template v-slot:actions>
      <create-form/>
    </template>

    <template v-slot:item.url="{item}">

      <div>
        <v-btn :href="item.url" color="primary" x-small>Vezi</v-btn>
      </div>
      <div class="border border-double my-1 overflow-hidden text-no-wrap px-2" style="max-width: 290px">{{ item.url }}</div>

    </template>

    <template v-slot:item.download_url="{item}">

      <div>
        <v-btn :href="item.download_url" color="primary" x-small>Descarca</v-btn>
      </div>
      <div class="border border-double my-1 overflow-hidden text-no-wrap px-2" style="max-width: 290px">
        {{ item.download_url }}
      </div>

    </template>

    <template v-slot:item.categories="{item}">

      <div v-for="(cat, index) in item.categories" :key="index">
        {{ cat.heading_one }}
      </div>

    </template>


    <template v-slot:item.actions="{item}">

      <edit-form :item="item"/>
      <action-delete :action-url="`feeds/${item.id}`"/>

    </template>

  </cit-data-table>

</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import CreateForm from './Create'
import EditForm from './Edit'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text',
          width: 300,
          sortable: false
        },
        {
          text: 'Link Access',
          value: 'url',
          width: 200,
          sortable: false
        },
        {
          text: 'Link Descarcare',
          value: 'download_url',
          width: 200,
          sortable: false
        },
        {
          text: 'Categorii',
          value: 'categories',
          width: 200,
          sortable: false
        },
        {
          text: 'Discount',
          value: 'discount',
          sortable: false
        }
      ]
    }
  }
}
</script>
