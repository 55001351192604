export default {
  OPEN_SIDEBAR (state, payload) {
    state.isSidebarActive = true
    state.sidebarTitle = payload
  },
  CLOSE_SIDEBAR (state) {
    state.isSidebarActive = false
    state.sidebarTitle = ''
  },
  SET_CURRENT_ACTIVE_SIDEBAR_COMPONENT (state, payload) {
    state.currentActiveSidebarComponent = payload
  },
  SET_CURRENT_ACTIVE_SIDEBAR_CONFIRMATION_BUTTON (state, payload) {
    state.sidebarConfirmationButton = payload
  }
}
