<template>
  <div>
    {{values.id}}
  </div>
</template>

<script>
export default {
  name: "ClientDeleted",
  props: {
    values: {}
  }
}
</script>

<style scoped>

</style>
