var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cit-data-table',{staticClass:"my-4",attrs:{"fixed-filters":{
    parent_category_id:_vm.parent_category_id
  },"headers":_vm.headers,"includes":['parent', 'badges', 'qualities'],"dense":"","height":"auto","hide-filters":"","hide-pagination":"","hide-title":"","per-page":"100","resource-path":"product-categories"},scopedSlots:_vm._u([{key:"item.display_order",fn:function(ref){
  var item = ref.item;
  var value = ref.value;
return [_c('display-order-changer',{attrs:{"item":item}})]}},{key:"item.badges",fn:function(ref){
  var item = ref.item;
  var value = ref.value;
return [_c('div',{staticClass:"my-2"},_vm._l((item.badges),function(badge,index){return _c('v-chip',{key:index,staticClass:"mx-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(badge.name)+" ")])}),1)]}},{key:"item.qualities",fn:function(ref){
  var item = ref.item;
return [(item.qualities.length)?_vm._l((item.qualities),function(quality,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(quality.name)+" ")])}):_vm._e()]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('edit-form',{attrs:{"item":item}}),_c('category-filter-access-button',{attrs:{"item":item}}),_c('featured-specs-filters-access-button',{attrs:{"item":item}}),_c('banners-access-button',{attrs:{"item":item}}),_c('action-delete',{attrs:{"action-url":("product-categories/" + (item.id))}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }