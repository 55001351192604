<template>
  <div class="items-center p-2">
    <span class="mb-2" v-if="lastContactedAt.contacted_at">
      {{lastContactedAt.contacted_at | moment("D-MM-YYYY HH:mm")}}
    </span>
    <span v-else>
      {{lastContactedAt}}
    </span>
      <v-dialog
        ref="dialog"
        v-model="formModal"
        persistent
        width="350px"
        style="z-index:70000;"
      >
        <template v-slot:activator="{ on, attrs }">
          <vs-button color="dark" size="small"
                     icon="fa-plus" icon-pack="fal"
                     type="border" v-bind="attrs"
                     v-on="on">
            <span>
              Adauga contact
            </span>
          </vs-button>
        </template>

        <v-card>
          <v-card-title>
            <span class="headline">Adauga data contactare</span>
          </v-card-title>
          <v-card-text>
            <v-datetime-picker
              ref="datetimepicker"
              v-model="datetime"
              :time-picker-props="{format:'24hr'}"
              label="Selectati data si ora"
              clearable>
            </v-datetime-picker>
            <v-textarea
              outlined
              name="input-7-4"
              label="Mesaj"
              v-model="message"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="formModal = false"
            >
              Anuleaza
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="save"
            >
              Adauga
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'lastContactedAt',
  data: () => ({
    datetime: '',
    message: '',
    formModal: false,
    modal: false
  }),
  props: {
    last_contacted_at: '',
    client: {
      required: true
    }
  },
  methods: {
    initValues () {
      this.datetime = null
      this.message = ''
    },
    save () {
      this.datetime.setHours(this.datetime.getHours() + 2)
      this.$http.post(`/clients/${this.client.id}/contact-messages`, {date: this.datetime, message: this.message})
        .then(() => {
          this.$emit('changed')
          this.$vs.notify({
            title: 'Succes!',
            text: 'Data a fost actualizata cu succes!!',
            color: 'success'
          })
          this.initValues()
          this.$refs.datetimepicker.clearHandler()
          this.modal = false
          this.formModal = false
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la salvare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })


    }
  },
  computed:{
    lastContactedAt () {
      if (this.last_contacted_at !== null && this.last_contacted_at.contacted_at !== null) {
        return this.last_contacted_at
      } else {
        return 'Nu a fost contactata'
      }
    }
  },
  created () {
    this.initValues()
  }
}
</script>

<style scoped>

</style>
