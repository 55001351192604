<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="newsletter/templates"
    >

      <template v-slot:actions>
        <download-demo/>
        <create-form/>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <make-default v-if="!item.is_default" :item="item"/>
        <edit-form :item="item"/>
        <action-delete :action-url="`newsletter/templates/${item.id}`"/>
      </template>

    </cit-data-table>
  </v-sheet>

</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import MakeDefault from '@/views/Newsletter/TemplateManagement/MakeDefault'
import EditForm from './Edit'
import CreateForm from './Create'
import DownloadDemo from '@/views/Newsletter/TemplateManagement/DownloadDemo'

export default {
  components: {
    DownloadDemo,
    MakeDefault,
    CreateForm,
    EditForm,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65'
        },
        {
          align: 'center',
          text: 'Predefinit',
          value: 'is_default',
          display: 'boolean',
          sortable: false,
          width: '65'
        },
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text'
        },
        {
          text: 'Data incarcare',
          display: 'date',
          value: 'created_at',
          sortable: false,
          width: '180'
        }
      ]
    }
  }
}
</script>
