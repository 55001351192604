import axios from '@/axios'

export default {
  loadItems ({commit, dispatch, getters}) {
    dispatch('startLoading', null, {root: true})
    commit('CALCULATE_FILTER')
    return axios.get(`/product-configs${  getters.queryString}`)
      .then(({data}) => {
        commit('SET_ITEMS', data)
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la afisare. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })
      .then(() => {
        dispatch('stopLoading', null, {root: true})
      })
  },
  changePage ({commit, dispatch}, page) {
    commit('SET_PAGE', page)
    dispatch('loadItems')
  },
  deleteFilter ({commit, dispatch}, filterName) {
    commit('DELETE_FILTER_ITEM', filterName)
    dispatch('loadItems')
  },
  addFilter ({commit, dispatch}, filterName) {
    commit('ADD_FILTER_ITEM', filterName)
    dispatch('loadItems')
  },
  changeSort ({commit, dispatch}, sort) {
    commit('CHANGE_SORT', sort)
    dispatch('loadItems')
  },


  storeItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/product-configs', itemData)
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })
  }
}
