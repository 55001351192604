var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"data-list-container"},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"expanded":_vm.expanded,"headers":_vm.headers,"hide-default-footer":true,"items":_vm.paginatedItems,"server-items-length":_vm.totalItems,"single-expand":true,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sort-by":_vm.updateSortField,"update:sort-desc":_vm.updateSortType},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [(item.order)?[_c('router-link',{attrs:{"to":{name:'orders-page', params:{id:item.id}}}},[_vm._v(" "+_vm._s(item.order.order_number)+" ")])]:_vm._e()]}},{key:"item.rating_agent",fn:function(ref){
var item = ref.item;
return [(item.has_not_interacted_with_agent)?[_vm._v(" Fara interactiune ")]:[_vm._v(" "+_vm._s(item.rating_agent)+" ")]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-button',{on:{"delete":function($event){return _vm.deleteItem(item)}}}),(!item.approved_at)?_c('div',{staticClass:"inline-block mr-2"},[_c('vs-tooltip',{attrs:{"text":"Accepta"}},[_c('v-btn',{attrs:{"color":"success","icon":"","x-small":""},on:{"click":function($event){return _vm.approveItem(item)}}},[_c('v-icon',[_vm._v(" fal fa-thumbs-up ")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"inline-block"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.featured),expression:"item.featured"}]},[_c('vs-tooltip',{attrs:{"text":"Elimina din favorite"}},[_c('v-btn',{attrs:{"color":"yellow","icon":"","x-small":""},on:{"click":function($event){return _vm.toggleFeatured(item)}}},[_c('v-icon',[_vm._v(" fa fa-star ")])],1)],1)],1)]),_c('div',{staticClass:"inline-block"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.featured),expression:"!item.featured"}]},[_c('vs-tooltip',{attrs:{"text":"Adauga la favorite"}},[_c('v-btn',{attrs:{"color":"yellow","icon":"","x-small":""},on:{"click":function($event){return _vm.toggleFeatured(item)}}},[_c('v-icon',[_vm._v(" fal fa-star ")])],1)],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('strong',[_vm._v("Nume:")]),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_c('div',[_c('strong',[_vm._v("Telefon:")]),_vm._v(" "+_vm._s(item.phone)+" ")]),_c('div',[_c('div',[_c('strong',[_vm._v("Review:")])]),_vm._v(" "+_vm._s(item.body)+" ")])])]}}])})],1),_c('v-card-actions',[_c('v-pagination',{attrs:{"length":_vm.pagination.last_page,"total-visible":5,"value":_vm.currentPage,"color":"#76bd43"},on:{"input":_vm.changePage}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }