<template>
  <div>
    <v-sheet>
      <v-app-bar color="white hidden-md-and-up" elevate-on-scroll>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>

      <v-row class="elevation-2" style="position: relative">
        <v-navigation-drawer v-model="drawer" :permanent="$vuetify.breakpoint.mdAndUp" height="600" hide-overlay>
          <div
            v-for="(item, index) in customerChats"
            :key="index"
          >
            <v-list class="py-0">
              <div class="text-h6 primary white--text pa-3">
                {{ item.agent.full_name }}
              </div>
              <template v-if="item.customers.length">
                <v-list-item v-for="(chat, index) in item.customers" :key="index" shaped @click="customerId = chat.id">
                  <v-list-item-avatar>
                    <v-icon>fal fa-user-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ chat.first_name }} {{ chat.last_name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="chat.is_company">{{ chat.client_display_name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Nu ai nici o conversatie.
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </v-navigation-drawer>

        <v-col class="pa-0">
          <chat-show :customer-id="customerId"/>
        </v-col>
      </v-row>

    </v-sheet>
  </div>
</template>

<script>
import ChatShow from "./components/ChatShow";

export default {
  name: 'List.vue',
  components: {ChatShow},
  data() {
    return {
      dataNotLoaded: true,
      customerChats: {},
      drawer: null,
      customerId: ''
    }
  },
  methods: {
    getCustomerChats() {
      this.$http.get(`/chat`)
        .then(({data}) => {
          this.customerChats = data
          this.dataNotLoaded = false
        })
        .catch((e) => {
          this.$vs.notify({
            title: 'Eroare',
            text: e.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {
          this.dataNotLoaded = false
        })
    }
  },
  mounted() {
    this.getCustomerChats()
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  position: absolute;
  @screen lg {
    position: inherit;
  }
}
</style>
