<template>
  <action-add-new action-url="product-warranties" button-label="Adauga ExtraGarantie noua">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.number_of_months" label="Numar de luni"/>
        <error-messages :errors="errors.number_of_months"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.percent_value" label="Procent"/>
        <error-messages :errors="errors.percent_value"></error-messages>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    ActionAddNew
  }
}
</script>
