<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      min-width="350"
      nudge-left="50"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="text-filter">
          <v-text-field
            v-model="clientName"
            v-bind="attrs"
            v-on="on"
            :label="placeholder"
            class="text-sm date-select"
            clearable
            dense
            hide-details
            outlined
            readonly
            single-line
          ></v-text-field>
        </div>
      </template>
      <v-sheet class="p-8">
        <v-row>
          <v-col>
            Cauta clientul ...
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="customerFirstNameSelection"
              :items="customerFirstNameItems"
              :loading="customerFirstNameLoading"
              :search-input.sync="customerFirstNameSearch"
              class="filter-select text-sm"
              clearable
              dense
              hide-details
              item-text="first_name"
              item-value="id"
              label="in functie de Nume"
              outlined
            >
              <template v-slot:item="{item}">
                <div class="flex flex-column">
                  {{ item.full_name }}
                  <div v-if="item.company" style="font-size: 10px">({{ item.company.name }})</div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="customerLastNameSelection"
              :items="customerLastNameItems"
              :loading="customerLastNameLoading"
              :search-input.sync="customerLastNameSearch"
              class="filter-select text-sm"
              clearable
              dense
              hide-details
              item-text="last_name"
              item-value="id"
              label="in functie de Prenume"
              outlined
            >
              <template v-slot:item="{item}">
                <div class="flex flex-column">
                  {{ item.full_name }}
                  <div v-if="item.company" style="font-size: 10px">({{ item.company.name }})</div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="customerCompanySelection"
              :items="customerCompanyItems"
              :loading="customerCompanyLoading"
              :search-input.sync="customerCompanySearch"
              class="filter-select text-sm"
              clearable
              dense
              hide-details
              item-text="company.name"
              item-value="id"
              label="in functie de Numele companiei"
              outlined
            >
              <template v-slot:item="{item}">
                <div class="flex flex-column">
                  {{ item.full_name }}
                  <div v-if="item.company" style="font-size: 10px">({{ item.company.name }})</div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ComplexCustomerFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      menu: false,
      customerFirstNameSelection: '',
      customerFirstNameItems: [],
      customerFirstNameLoading: false,
      customerFirstNameSearch: '',
      customerLastNameSelection: '',
      customerLastNameItems: [],
      customerLastNameLoading: false,
      customerLastNameSearch: '',
      customerCompanySelection: '',
      customerCompanyItems: [],
      customerCompanyLoading: false,
      customerCompanySearch: ''
    }
  },
  methods: {
    filter (value) {
      value = value || ''
      this.$emit('filter', value, this.filterName)
      this.menu = false
    },
    customerFirstNameLoad (customerFirstNameName) {
      customerFirstNameName = customerFirstNameName || ''
      this.$http.get(`/clients?filter[first_name]=${customerFirstNameName}`)
        .then(({data}) => {
          this.customerFirstNameItems = data.data
          this.customerFirstNameLoading = false
        })
    },
    customerLastNameLoad (customerLastNameName) {
      customerLastNameName = customerLastNameName || ''
      this.$http.get(`/clients?filter[last_name]=${customerLastNameName}`)
        .then(({data}) => {
          this.customerLastNameItems = data.data
          this.customerLastNameLoading = false
        })
    },
    customerCompanyLoad (customerCompanyName) {
      customerCompanyName = customerCompanyName || ''
      this.$http.get(`/clients?filter[company.name]=${customerCompanyName}`)
        .then(({data}) => {
          this.customerCompanyItems = data.data
          this.customerCompanyLoading = false
        })
    },
    clearAndFilter () {
      this.customerFirstNameSelection = ''
      this.customerFirstNameItems = []
      this.customerFirstNameLoading = false
      this.customerFirstNameSearch = ''
      this.customerLastNameSelection = ''
      this.customerLastNameItems = []
      this.customerLastNameLoading = false
      this.customerLastNameSearch = ''
      this.customerCompanySelection = ''
      this.customerCompanyItems = []
      this.customerCompanyLoading = false
      this.customerCompanySearch = ''
    }
  },
  computed: {
    clientName () {
      if (this.customerFirstNameSelection) {
        return this.customerFirstNameSearch
      }
      if (this.customerLastNameSelection) {
        return this.customerLastNameSearch
      }
      if (this.customerCompanySelection) {
        return this.customerCompanySearch
      }
      return ''
    }
  },
  watch: {
    customerFirstNameSelection (value) {
      this.filter(value)
    },
    customerLastNameSelection (value) {
      this.filter(value)
    },
    customerCompanySelection (value) {
      this.filter(value)
    },
    customerFirstNameSearch (value) {
      this.customerFirstNameLoading = true
      this.customerFirstNameLoad(value)
    },
    customerLastNameSearch (value) {
      this.customerLastNameLoading = true
      this.customerLastNameLoad(value)
    },
    customerCompanySearch (value) {
      this.customerCompanyLoading = true
      this.customerCompanyLoad(value)
    }
  },
  created () {
    this.customerFirstNameLoad()
    this.customerLastNameLoad()
    this.customerCompanyLoad()
  }
}
</script>

<style lang="scss">
.text-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-input__append-inner {
      display: none !important;
    }

    .v-text-field__slot {
      font-weight: 400 !important;
      font-size: 10px !important;

      .v-label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
      margin-top: -5px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}

.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
