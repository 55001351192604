<template>
 <span>
   <v-dialog v-model="dialogIsOpened" class="action-add-new" max-width="450" persistent scrollable>
      <v-card>
        <v-card-title class="text-center justify-center">
          <template v-if="isGenerating">
            Descarcare Structura
          </template>
          <template v-else>
          Descarca Structura
          </template>
        </v-card-title>
        <v-divider/>
        <v-card-text v-show="!isGenerating" class="pt-5">

            <autocomplete-selector
              v-model="category_id"
              :fixed-filters="{
              parent_and_child:categoryId
            }"
              endpoint="product-categories"
              label="Selecteaza Categoria / Subcategoria"
            />
            <div v-if="Object.keys(columns).length" class="mt-3 border-2 border-solid border-primary rounded pl-2">
            <span class="text-base font-bold block pt-3 pb-2">Selecteaza Coloanele</span>
            <div class="overflow-y-auto" style="max-height: 400px; ">
              <div v-for="(column, index) in columns" :key="index">
                <v-checkbox v-model="selectedColumns" :label="column" :value="index" class="mt-0" dense hide-details/>
              </div>
            </div>
          </div>

        </v-card-text>
        <v-cart-text v-show="isGenerating" class="p-8">
           <v-progress-linear
             :active="isGenerating"
             :indeterminate="isGenerating"
             color="primary"
             rounded
             height="20"
           ></v-progress-linear>
        </v-cart-text>
        <v-divider/>
        <v-card-actions v-show="!isGenerating">
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary darken-1" text
            @click="dialogIsOpened = false"
          >
            Anuleaza
          </v-btn>
          <vs-spacer/>
          <v-btn :disabled="loading" :loading="loading" color="primary" @click="download">Descarca</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
           <basic-button
             v-bind="attrs"
             v-on="on"
             color="blue-grey lighten-4"
             icon="fal fa-download"
             text="Descarca structura"
             tooltip="Descarca structura"
             @click="openDialog"
           />
      </template>
      <span>Descarca structura</span>
    </v-tooltip>
  </span>
</template>

<script>
import BasicButton from '@/components/general-form/BasicButton'
import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'

export default {
  name: 'DownloadStructure',
  components: {
    AutocompleteSelector,
    BasicButton
  },
  props: {
    categoryId: {
      required: true
    }
  },
  data() {
    return {
      dialogIsOpened: false,
      category_id: '',
      errors: {},
      loading: false,
      columns: {},
      selectedColumns: [],
      isGenerating: false
    }
  },
  methods: {
    openDialog() {
      this.dialogIsOpened = true
    },
    cancel() {
      this.category_id = ''
      this.dialogIsOpened = false
      this.errors = {}
      this.loading = false
      this.columns = {}
      this.selectedColumns = []
    },
    download() {
      this.loading = true
      this.isGenerating = true
      this.errors = {}
      this.$http
        .get(`feed-connectors/general/product-exports/${this.category_id}/generate`, {
          params: {
            columns: this.selectedColumns
          }
        })
        .then(({data}) => {

          this.fileName = data

          this.tryToDownload()
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
          this.isGenerating = false
        })
    },
    tryToDownload() {
      this.$http
        .get(`feed-connectors/general/product-exports/${this.category_id}/download/${this.fileName}`, {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
          responseType: 'arraybuffer'
        })
        .then(({data}) => {

          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'template.xlsx')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(link)

          this.loading = false
          this.cancel()
        })
        .catch(() => {
          setTimeout(() => {
            this.tryToDownload()
          }, 5000)
        })

    }
  },
  watch: {
    category_id(value) {
      if (value) {
        this.loading = true
        this.$http
          .get(`feed-connectors/general/product-exports/${value}/columns`)
          .then(({data}) => {
            this.columns = data
            this.loading = false
            this.selectedColumns = Object.keys(data)
          })
          .catch(({response}) => {
            if (response.status === 422) {
              this.errors = response.data.errors
              this.$vs.notify({
                title: 'Eroare',
                text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
                color: 'danger'
              })
            } else {
              this.$vs.notify({
                title: 'Eroare',
                text: 'Eroare la incarcarea coloanelor, va rugam reimprospatati pagina sau incercati mai tarziu',
                color: 'danger'
              })
            }
            this.loading = false
          })
      }
    }
  }
}
</script>
