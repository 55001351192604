<template>
  <span class="button-wrapper lg:mx-1 mb-2">
    <v-btn
      v-on="listeners"
      :to="to"
      color="teal lighten-4"
      elevation="1"
      class="w-full"
    >
      <v-icon class="mr-2" small>fal fa-plus</v-icon>
      <slot>{{ buttonLabel }}</slot>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'NewButton',
  props: {
    to: {
      default: '',
      type: String | Object
    },
    buttonLabel: {
      default: 'Adauga',
      type: String
    }
  },
  methods: {},
  computed: {
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-wrapper {
  @apply block;
  @screen lg {
    @apply inline-block;
  }
}
</style>
