<template>
  <div class="text-inline-field">
    <v-text-field
      v-model="fieldValue"
      :type="type"
      class="text-sm"
      dense
      hide-details
      outlined
      single-line
    >
      <template v-slot:append>
        <v-btn :disabled="table.loading" color="primary" icon x-small @click="save">
          <v-icon x-small>fal fa-check</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextInlineField',
  inject: ['table'],
  props: {
    actionUrl: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    method: {
      type: String,
      default: 'patch'
    },
    fieldName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data () {
    return {
      fieldValue: ''
    }
  },
  methods: {
    save () {
      this.table.startLoading()
      this.$http({
        method: this.method,
        url: this.actionUrl,
        data: {
          [this.fieldName]: this.fieldValue
        }
      })
        .then(() => {
          this.table.loadItems()
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.$vs.notify({
              title: 'Eroare',
              text: response.data.errors,
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la stergere, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.table.stopLoading()
        })
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.fieldValue = JSON.parse(JSON.stringify(value))
      }
    }
  }
}
</script>

<style lang="scss">
.text-inline-field {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-input__icon--clear {
      font-size: 16px !important;
      margin-top: -5px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
