<template>
  <div>

    <div v-if="order.is_editable">

      <div v-if="order.proforma">

        <v-tooltip bottom max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              :loading="loading"
              color="#FFF9C4" @click="dialogIsOpened=true"
            >
              REGENEREAZA si trimite factura proforma
            </v-btn>
          </template>
          <span>Regenereaza factura proforma si trimite email catre client. Proforma deja generata va fi inlocuita cu aceasta.</span>
        </v-tooltip>

      </div>

      <div v-else>

        <v-tooltip bottom max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              :loading="loading"
              color="primary" @click="sendProforma"
            >
              Genereaza si trimite factura proforma
            </v-btn>
          </template>
          <span>Genereaza factura proforma si trimite email catre client</span>
        </v-tooltip>

      </div>

      <v-dialog v-model="dialogIsOpened" width="350">
        <v-card>
          <v-card-title class="text-center">
            Esti sigur ca vrei sa regenerezi factura proforma?
          </v-card-title>
          <v-divider/>
          <v-card-text class="pt-5">
            Regenerand factura proforma, actuala factura se va inlocui cu cea noua.
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text @click="dialogIsOpened = false">Anuleaza</v-btn>
            <v-spacer/>
            <v-btn color="primary" @click="sendProforma" :disabled="loading" :loading="loading">Regenereaza</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SendProformaButton',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      dialogIsOpened: false,
      loading: false
    }
  },
  methods: {
    sendProforma () {
      this.loading = true
      this.$http.post(`orders/${this.order.id}/proforma`)
        .then(({data}) => {
          this.$emit('should-refresh', data)
          this.dialogIsOpened = false
          this.$vs.notify({
            title: 'Scuces',
            text: 'Factura proforma a fost generata si trimisa!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la generarea proformei, va rugam reimprospatati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
