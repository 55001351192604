<template>
  <v-autocomplete
    v-model="selectOption"
    :items="categories"
    :label="label"
    :loading="loading"
    :return-object="returnObject"
    :search-input.sync="search"
    class="filter-select text-sm"
    dense
    hide-details
    hide-no-data
    item-text="name"
    item-value="id"
    no-filter
    outlined
  >
    <template v-slot:item="data">
      <div>
        <div class="font-bold">{{ data.item.name }}</div>
        <div class="italic text-xs">
          (
          <template v-if="data.item.parent">
            {{ data.item.parent.name }}
            <template v-if="data.item.parent.parent">
              / {{ data.item.parent.parent.name }}
            </template>
          </template>
          )
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'NodCategoryAutocomplete',
  props: {
    value: {},
    returnObject: {
      default: false
    },
    label: {
      default: 'Categorii Nod'
    }
  },
  data () {
    return {
      paginatedAttributes: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    categories () {
      return this.paginatedAttributes.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || ''
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/feed-connectors/nod/categories?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedAttributes = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
