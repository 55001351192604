<template>
  <div>
    <vs-tooltip position="right" text="Inchide comanda si plata ramburs">
      <v-btn :disabled="loading" @click="sendChangeRequest">Plata Ramburs</v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MarkAsPaiedOnDelivery',
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    sendChangeRequest () {
      this.loading = true
      this.$http.patch(`orders/${this.order.id}/mark-as-paid-on-delivery`)
        .then(() => {
          this.$router.push({
            name: 'orders-all',
            params: {status: 'unprocessed'}
          })
        }).finally(() => {

        this.loading = true
      })
    }
  }
}
</script>
