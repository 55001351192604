import { render, staticRenderFns } from "./ClientDeleted.vue?vue&type=template&id=b53a4996&scoped=true&"
import script from "./ClientDeleted.vue?vue&type=script&lang=js&"
export * from "./ClientDeleted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b53a4996",
  null
  
)

export default component.exports