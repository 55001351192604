<template>
  <div class="flex">
    <v-text-field
        v-model="fieldData"
        :disabled="disabled"
        :label="label" :placeholder="placeholder"
        :type="type"
        class="w-full"
    />
    <vs-tooltip :text="tooltipMsg">
      <v-btn fab icon tile @click="toggleDisable">
        <span v-show="disabled"><i class="fal fa-eye-slash"></i></span>
        <span v-show="!disabled"><i class="fal fa-eye"></i></span>
      </v-btn>
    </vs-tooltip>

  </div>
</template>

<script>
export default {
  name: 'DisableableTextField',
  props: {
    label: {
      required: true
    },
    placeholder: {
      required: true
    },
    value: {},
    type: {
      type: String,
      default: 'text'
    }
  },
  data () {
    return {
      disabled: false
    }
  },
  methods: {
    toggleDisable () {
      this.disabled = !this.disabled
      if (this.disabled) {
        this.fieldData = ''
      }
    }
  },
  computed: {
    fieldData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    tooltipMsg () {
      if (this.disabled) {
        return `Activeaza utilizarea campului ${this.label} in generarea produselor`
      } else {
        return `Dezactiveaza utilizarea campului ${this.label} in generarea produselor`
      }
    }
  }

}
</script>

<style scoped>

</style>
