<template>
  <div class="the-navbar__user-meta flex items-center">

    <div v-if="activeUserInfo" class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.full_name }}</p>

    </div>

    <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>

      <div class="con-img ml-3">
        <template v-if="activeUserInfo.avatar">
          <span :style="`background-image: url('${activeUserInfo.avatar}')`" class="active-user-inf-avatar"></span>
        </template>
        <template v-else>
          <vs-icon icon="fa-user-circle" icon-pack="fal" size="40px"/>
        </template>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  computed: {
    activeUserInfo () {
      return this.$store.getters['auth/currentUser']
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
<style>
.active-user-inf-avatar {
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
  background-position: center center;
}
</style>
