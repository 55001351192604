export default {
  SET_EDIT_ITEM (state, item) {
    state.editItem = item
  },
  OPEN_DIALOG (state) {
    state.modalStatus = true
  },
  CLOSE_DIALOG (state) {
    state.modalStatus = false
  },
  SET_ITEM_PROP (state, {key, value}) {
    state.editItem[key] = value
  }
}
