import { render, staticRenderFns } from "./ProductExtraWarrantySelector.vue?vue&type=template&id=84938e64&scoped=true&"
import script from "./ProductExtraWarrantySelector.vue?vue&type=script&lang=js&"
export * from "./ProductExtraWarrantySelector.vue?vue&type=script&lang=js&"
import style0 from "./ProductExtraWarrantySelector.vue?vue&type=style&index=0&id=84938e64&lang=scss&scoped=true&"
import style1 from "./ProductExtraWarrantySelector.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84938e64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
