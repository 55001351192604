<template>
  <v-row>
    <v-col cols="12" class="flex flex-wrap md:-mx-3">
      <div v-for="(category, index) in categories" :key="index" class="w-full md:w-1/2 lg:w-1/3 flex justify-center">
        <router-link
          class="border border-solid border-grey rounded p-10 w-full text-center text-xl font-bold mb-2 md:m-2 hover:bg-primary hover:text-white transition-ease-in-out"
          :to="{name:'feed-connectors-nod-integration',params:{categoryId:category.id}}">
          {{ category.name }} <v-icon color="white">mdi-arrow-right</v-icon>
        </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      categories: []
    }
  },
  created() {
    this.$http.get('product-categories/parents-only')
      .then(({data}) => {
        this.categories = data
      })
  }
}
</script>
