<template>
  <v-sheet>
    <cit-data-table
      :appends="[

      ]"
      :headers="headers"
      :includes="[
        'customer',
        'theme'
      ]"
      disable-actions
      resource-path="reseller-websites/purchases"
    >

      <template v-slot:[`item.status`]="{item}">
        <status-changer :item="item"/>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import StatusChanger from '@/views/Orders/ThemeOrders/StatusChanger'

export default {
  components: {
    StatusChanger,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Numar comanda',
          value: 'id',
          width: '100',
          sortable: true
        },
        {
          text: 'Client',
          value: 'customer.client_display_name',
          sortable: false
        },
        {
          text: 'Cost',
          value: 'cost',
          width: 100,
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          width: 200,
          sortable: false
        }
      ]
    }
  }
}
</script>
