<template>
  <div>
    <v-autocomplete
      v-model="selectedAttributeId"
      :items="filterType.options"
      item-text="full_name"
      item-value="id"
      label="Alege"
    ></v-autocomplete>
    <v-btn
      :disabled="!selectedAttributeId"
      elevation="2"
      @click="addFilter"
    >Adauga
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AttributeFilter',
  data () {
    return {
      selectedAttributeId: false
    }
  },
  computed: {
    selectedAttribute () {
      return this.filterType.options.find(element => element.id === this.selectedAttributeId)
    }
  },
  methods: {
    addFilter () {
      this.$store.dispatch(this.storeName, {
        type: 'attribute',
        model_id: this.selectedAttributeId,
        name: this.selectedAttribute.name,
        deletable: true
      })
      this.selectedAttributeId = false
    }
  },
  props: {
    filterType: {
      required: true
    },
    storeName: ''
  }
}
</script>
