<template>
  <list-layout>
    <cit-data-table
      :appends="['is_processing']"
      :headers="headers"
      :includes="['category']"
      resource-path="feed-connectors/general/integrations"
      :fixed-filters="{category:categoryId}"
    >

      <template v-slot:actions>
        <create-form :category-id="categoryId"/>
        <download-structure :category-id="categoryId"/>
      </template>

      <template v-slot:item.actions="{item}">
        <view-button
          :to="{
            name:'feed-connectors-generals-category-integration',
            params:{
              categoryId:categoryId,
              integrationId:item.id
            }
          }"
          color="black"
          target="_self"
        />
        <download-empty-structure :integration="item"/>
        <upload :item="item"/>
        <edit-form :item="item"/>
      </template>

    </cit-data-table>
  </list-layout>
</template>

<script>
import ListLayout from '@/views/FeedConnectors/Generals/ListLayout'
import CitDataTable from '@/components/layout/CitDataTable'
import CreateForm from './Create'
import EditForm from './Edit'
import Upload from './Upload'
import DownloadStructure from '@/views/FeedConnectors/Generals/Integrations/components/DownloadStructure'
import DownloadEmptyStructure from '@/views/FeedConnectors/Generals/Integrations/components/DownloadEmptyStructure'
import ViewButton from '@/components/general-form/ViewButton'

export default {
  name: 'List',
  components: {
    ViewButton,
    DownloadEmptyStructure,
    DownloadStructure,
    ListLayout,
    Upload,
    EditForm,
    CreateForm,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65'
        },
        {
          text: 'Denumire Feed',
          value: 'name',
          filterType: 'text'
        },
        {
          align: 'center',
          text: 'In Procesare',
          value: 'is_processing',
          width: '50',
          display: 'boolean',
          sortable: false
        },
        {
          text: 'Subcategorie CIT',
          value: 'category.name',
          width: '150',
          sortable: false
        },
        {
          align: 'center',
          text: 'Data Creare',
          value: 'created_at',
          width: '150',
          display: 'date'
        },
        {
          align: 'center',
          text: 'Ultimul import',
          value: 'updated_at',
          width: '150',
          display: 'date'
        }
      ]
    }
  },
  computed: {
    categoryId () {
      return this.$route.params.categoryId
    }
  }
}
</script>
