<template>
  <div>
    <v-edit-dialog large @open="open" @save="save">
      <v-chip color="#8a867e" dark>{{ orderItem.quantity }}</v-chip>
      <template v-slot:input>
        <div class="mt-4 title">
          <label for="selectedQuantity">Noua Cantitate</label>
        </div>
        <div class="mt-4">
          <input id="selectedQuantity" v-model="selectedQuantity" class="defaultInputStyle" min="1" step="1"
                 type="number">
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import ClientAgent from '@/views/Clients/components/ClientAgent'

export default {
  name: 'OrderItemQuantity',
  components: {ClientAgent},
  data () {
    return {
      selectedQuantity: ''
    }
  },
  props: {
    orderItem: {
      required: true
    }
  },
  methods: {
    open () {
      this.selectedQuantity = this.orderItem.quantity
    },
    save () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`/orders/${this.orderItem.order_id}/${this.orderItem.id}`, {quantity: this.selectedQuantity})
        .then(({data}) => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Cantitatea a fost actualizat cu succes!!',
            color: 'success'
          })
          this.$emit('saved', data)
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la salvarea cantitatii. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => this.$store.dispatch('stopLoading'))

    }
  }
}
</script>
<style scoped>
input.defaultInputStyle {
  -webkit-writing-mode: horizontal-tb !important;
  /*noinspection CssInvalidPropertyValue*/
  appearance: textfield;
  border: 1px solid;
  width: 100px;
}
</style>
