<template>

  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="['agent']"
      actions-width="50"
      resource-path="clients/reminders"
      :fixed-filters="{customer_id:customerId}"
      show-expand
    >

      <template v-slot:actions="{}">
        <create-form :customer-id="customerId"/>
      </template>

      <template v-slot:expanded-item="{item}">
        {{ item.body }}
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <action-delete
          v-if="item.is_active && !item.delivered_at"
          :action-url="`clients/reminders/${item.id}`"
          confirmation-text="Esti stigur ca vrei sa anulezi acest reminder?"
          delete-label="Anuleaza"
        />
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import CreateForm from './Create'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm
  },
  props: {
    customerId: {
      required: true
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Agent',
          value: 'agent.full_name',
          sortable: false,
          width: '150'
        },
        {
          text: 'Title',
          value: 'title',
          filterType: 'text'
        },
        {
          align: 'center',
          text: 'Activ',
          value: 'is_active',
          display: 'boolean',
          sortable: false,
          width: '80'
        },
        {
          align: 'center',
          text: 'Livrat',
          value: 'delivered_at',
          display: 'boolean',
          sortable: false,
          width: '80'
        },
        {
          text: 'Data reminder',
          value: 'reminder_at',
          sortable: true,
          display: 'date-no-time',
          width: '150'
        },
        {
          text: 'Data Creare',
          value: 'created_at',
          sortable: true,
          display: 'date',
          width: '150'
        }

      ]
    }
  }
}
</script>

