<template>
  <v-btn :loading="loading" @click="clearCache" class="d-block w-full">
    <span class="whitespace-normal">Curata Cache</span>
  </v-btn>
</template>

<script>
export default {
  name: "ClearCacheButton",
  data() {
    return {
      loading: false
    }
  },
  methods: {
    clearCache() {
      this.loading = true
      this.$http.post('settings/clear-cache')
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Memoria cache a fost curatata!!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'Eroare la curatarea memoriei cache!!',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
