<template>
  <div class="text-filter">
    <v-text-field
      v-model="searchValue"
      v-debounce:300="filter"
      :placeholder="placeholder"
      class="text-sm"
      clearable
      dense
      hide-details
      outlined
      single-line
      @click:clear="clearAndFilter"
    >

    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Cauta...'
    },
    hideIcon: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      searchValue: '',
      isTyping: false
    }
  },
  methods: {
    clearAndFilter () {
      this.searchValue = ''
      this.filter()
    },
    filter () {
      this.$emit('filter', this.searchValue, this.filterName)
    }
  }
}
</script>

<style lang="scss">
.text-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-input__icon--clear {
      font-size: 16px !important;
      margin-top: -5px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
