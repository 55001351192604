<template>
  <div>
    <v-container class="flex">
      <v-row>
        <v-col>
          Min si max vor fi calculate automat in functie de preturile din listare
        </v-col>
      </v-row>
    </v-container>
    <v-btn elevation="2" @click="addFilter">
      Adauga
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PriceFilter',
  methods: {
    addFilter () {
      this.$store.dispatch('CategoryFilters/addNewFilter', {
        type: 'price',
        model_id: 'price',
        deletable: true,
        name: 'Price'
      })
    }
  },
  props: {
    filterType: {
      required: true
    }
  }
}
</script>
