<template>
  <div class="button-wrapper">
    <vs-tooltip text="Apasa butonul pentru a adauga un tip de componenta in configurator">
      <v-btn color="primary" small @click="dialogIsOpened=true">Adauga slot de componenta</v-btn>
    </vs-tooltip>
    <v-dialog v-model="dialogIsOpened"  max-width="500px" persistent style="z-index: 99999">
      <v-card class="flex flex-col">
        <v-card-title class="text-xl font-bold">Alege tipul componentei</v-card-title>
        <v-card-text>
          <product-component-selector-with-checkbox
            v-model="selectedComponentType"
            :category="category"
            :menu-props="{closeOnClick: true, closeOnContentClick: true}"
            :multiple="true"
            :return-object="true"
            include-component-group
          />
        </v-card-text>
        <v-spacer/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogIsOpened=false">Anuleaza</v-btn>
          <v-btn text @click="addSlotToConfig">Adauga</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductComponentSelector from '@/components/autocompletes/ProductComponentSelector'
import ProductComponentSelectorWithCheckbox from '@/components/autocompletes/ProductComponentSelectorWithCheckbox'

export default {
  name: 'AddComponentSlotToProduct',
  components: {
    ProductComponentSelectorWithCheckbox,
    ProductComponentSelector,
    ErrorMessages
  },
  data () {
    return {
      dialogIsOpened: false,
      selectedComponentType: []
    }
  },
  computed: {
    category () {
      return this.$store.getters['productGenerator/categoryId']
    }
  },
  methods: {
    addSlotToConfig () {
      this.selectedComponentType.forEach((item) => {
        this.$store.dispatch('productGenerator/addSlot', item)
      })
      this.selectedComponentType = []
      this.dialogIsOpened = false
    }
  }
}
</script>


<style scoped>
.button-wrapper {
  @apply inline-block;
}
</style>
