<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <div class="mb-5">
          <v-text-field v-model="form.name" label="Nume"/>
          <error-messages :errors="errors.name"></error-messages>
        </div>
      </div>
      <div class="mb-5">
        <attribute-group-selector v-model="form.group_id"/>
        <error-messages :errors="errors.group_id"></error-messages>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AttributeGroupSelector from '@/components/autocompletes/AttributeGroupSelector'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'

export default {
  name: 'AddBaseProductAttribute',
  components: {
    AttributeGroupSelector,
    ProductCategorySelector,
    ErrorMessages
  },
  data () {
    return {
      form: {
        name: '',
        product_category_id: 'true',
        group_id: ''
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('startLoading')
      this.$store.dispatch('attributes/storeItem', this.form)
        .catch(({response}) => {
          this.errors = response.data.errors
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })

    }
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
