<template>
  <v-sheet>
    <cit-data-table
        :headers="headers"
        :includes="['agent']"
        :resource-path="`clients/${customerId}/contact-messages`"
        disable-actions
    ></cit-data-table>
  </v-sheet>
</template>
<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  components: {CitDataTable},
  props: {
    customerId: {
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Agent',
          value: 'agent.full_name',
          sortable: false,
          width: 100
        },
        {
          text: 'Mesaj',
          value: 'message',
          sortable: false
        },
        {
          text: 'Data Contact',
          value: 'contacted_at',
          display: 'date',
          sortable: true,
          width: 150
        },
        {
          text: 'Data creare inregistrare',
          value: 'created_at',
          display: 'date',
          sortable: true,
          width: 150
        }
      ]
    }
  }
}
</script>
