var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-layout',[_c('cit-data-table',{attrs:{"appends":['is_processing'],"headers":_vm.headers,"includes":['category'],"resource-path":"feed-connectors/general/integrations","fixed-filters":{category:_vm.categoryId}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('create-form',{attrs:{"category-id":_vm.categoryId}}),_c('download-structure',{attrs:{"category-id":_vm.categoryId}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('view-button',{attrs:{"to":{
          name:'feed-connectors-generals-category-integration',
          params:{
            categoryId:_vm.categoryId,
            integrationId:item.id
          }
        },"color":"black","target":"_self"}}),_c('download-empty-structure',{attrs:{"integration":item}}),_c('upload',{attrs:{"item":item}}),_c('edit-form',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }