<template>
  <div
    id="page-login"
    :style="`background-image: url(${require('@/assets/images/pages/login/login-bk.jpeg')})`"
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center bg-cover"
  >
    <div class="m-4 md:w-2/5 sm:m-0 sm:w-2/3 vx-col w-3/4 lg:w-1/3 ">
      <v-card>
        <v-card-text>
          <form @submit.prevent="login">

            <div slot="no-body" class="full-page-bg-color">

              <div class="vx-row no-gutter justify-center items-center">

                <div class="vx-col w-full d-theme-dark-bg p-5">
                  <div class="vx-card__title mb-4">

                    <div class="mb-10">
                      <img :src="require('@/assets/images/logo/logo.png')" alt="CIT Grup">
                    </div>

                    <h4 class="mb-4">Autentificare panou de administrare</h4>
                  </div>

                  <div>
                    <v-text-field
                      v-model="loginData.email"
                      class="w-full mt-6"
                      dense
                      hide-details
                      label-placeholder="E-mail"
                      name="email"
                      outlined
                      placeholder="E-mail"
                      type="email"
                    />
                    <error-messages :errors="errors.email"/>

                    <v-text-field
                      ref="password"
                      v-model="loginData.password"
                      class="w-full mt-6"
                      dense
                      hide-details
                      label-placeholder="Parola"
                      name="password"
                      outlined
                      placeholder="Parola"
                      type="password"
                    />
                    <error-messages :errors="errors.password"/>

                    <v-btn :disabled="loading" :loading="loading" class="mt-6" color="primary" @click="login">
                      Autentificare
                    </v-btn>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {ErrorMessages},
  data () {
    return {
      loading: false,
      loginData: {
        email: '',
        password: ''
      },
      errors: []
    }
  },
  computed: {},
  methods: {
    login () {
      this.loading = true
      this.$store.dispatch('auth/login', this.loginData)

        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              'color': 'danger'
            })
          } else if (response.status === 401) {
            this.errors = {
              password: ['Emailul sau parola nu sunt valide. Va rugam verificati']
            }
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Emailul sau parola nu sunt valide. Va rugam verificati',
              'color': 'danger'
            })

          } else {
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              'color': 'danger'
            })
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    }

  }
}
</script>
