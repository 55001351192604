<template>
  <v-sheet>
    <cit-data-table
      :appends="['progress','processed_jobs']"
      :headers="headers"
      resource-path="helpers/job-batches"
    >

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  name: 'ListJobs',
  components: {CitDataTable},
  data () {
    return {
      headers: [
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          width: '150',
          align: 'center',
          sortable: true
        },
        {
          text: 'Progres',
          value: 'progress',
          display: 'percent',
          width: '100',
          align: 'center',
          sortable: true
        },
        {
          text: 'Total',
          value: 'total_jobs',
          width: '100',
          align: 'center',
          sortable: true
        },
        {
          text: 'Finalizate',
          value: 'processed_jobs',
          width: '100',
          align: 'center',
          sortable: true
        },
        {
          text: 'In asteptare',
          value: 'pending_jobs',
          width: '100',
          align: 'center',
          sortable: true
        },
        {
          text: 'Esuate',
          value: 'failed_jobs',
          width: '100',
          align: 'center',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          filterType: 'date-range',
          width: '100',
          align: 'center',
          sortable: true
        },
        {
          text: 'Finalizat',
          value: 'finished_at',
          display: 'date',
          filterType: 'date-range',
          width: '100',
          align: 'center',
          sortable: true
        }

      ]
    }
  }
}
</script>
