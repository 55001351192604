<template>
  <v-tabs class="homepage-settings" left vertical>

    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">SEO</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Header</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune text</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Branduri</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Categorii</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Bg. Albastru</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Avantaje</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Text/Imagine Bg. Albastru</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Echipa de Vanzari</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Companii</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Presa</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Premii si Certificari</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Despre CIT</span>
    </v-tab>

    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <seo-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <header-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <text-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <div class="p-8">
          Se editeaza de la sectiunea de <a href="#">brand-uri</a>.
        </div>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <div class="p-8">
          Se editeaza de la listarea de <a href="https://admin.citgrup.ro/#/product-management/categories">categorii</a>
        </div>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <blue-bg-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <benefits-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <text-image-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <team-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <clients-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <press-section/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <div class="p-8">
          Se editeaza de la sectiunea de <a href="#">premii si certificari</a>.
        </div>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <about-section/>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import SeoSection from './components/SeoSection'
import HeaderSection from './components/HeaderSection'
import TextSection from './components/TextSection'
import BenefitsSection from './components/BenefitsSection'
import TeamSection from './components/TeamSection'
import AboutSection from './components/AboutSection'
import PressSection from './components/PressSection'
import BlueBgSection from "./components/BlueBgSection"
import TextImageSection from "./components/TextImageSection";
import ClientsSection from "./components/ClientsSection";

export default {
  name: 'index',
  components: {
    ClientsSection,
    PressSection,
    AboutSection,
    TeamSection,
    BenefitsSection,
    BlueBgSection,
    HeaderSection,
    TextSection,
    SeoSection,
    TextImageSection
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
