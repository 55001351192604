<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="['items','customer','customer.agent']"
      disable-actions
      resource-path="reports/sales"
    >

      <template v-slot:[`item.status_details`]="{value}">
        <v-chip :color="value.color" x-small>{{ value.name }}</v-chip>
      </template>

      <template v-slot:footer-right="{paginatedData}">
        <div v-if="paginatedData.footer_data" class="ml-5" style="width: 100%">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <th>Total</th>
                <td>
                  {{ paginatedData.footer_data.totals }}
                </td>
              </tr>
              <tr>
                <th>Nr. comenzi</th>
                <td>
                  {{ paginatedData.footer_data.count }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  components: {
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Client',
          value: 'customer.client_display_name',
          filterType: 'customer',
          filterName: 'customer_id',
          width: 150,
          sortable: false
        },
        {
          text: 'Agent',
          value: 'customer.agent.full_name',
          filterType: 'agent',
          filterName: 'customer.agent_id',
          width: 150,
          sortable: false
        },
        {
          text: 'Status',
          value: 'status_details',
          sortable: false
        },
        {
          text: 'Data',
          value: 'submitted_at',
          filterType: 'date-range',
          width: 100,
          align: 'center',
          display: 'date'
        },
        {
          text: 'Numar de Produse',
          value: 'number_of_products',
          align: 'center',
          width: 100
        },
        {
          text: 'Total Produse',
          value: 'total_products',
          align: 'center',
          width: 100
        },
        {
          text: 'TVA',
          value: 'total_vat',
          align: 'center',
          width: 100
        },
        {
          text: 'Taxa verde',
          value: 'total_green_tax',
          align: 'center',
          width: 100
        },
        {
          text: 'Total',
          value: 'total',
          align: 'center',
          width: 100
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('agents/loadItems')
  }
}
</script>
