<template>
  <v-row>
    <v-col cols="2">
      <integration-navigation/>
    </v-col>
    <v-col cols="10">

      <cit-data-table
        :fixed-filters="{
          'product_category.parent_category_id' : $route.params.categoryId
        }"
        :headers="headers"
        :includes="[
          'nod_category',
          'product_category',
          'product_quality',
        ]"
        resource-path="feed-connectors/nod/category-importer"
      >

        <template v-slot:actions="{item}">
          <create-form :category-id="$route.params.categoryId"/>
          <attribute-page-button :category-id="$route.params.categoryId"/>
        </template>

        <template v-slot:item.actions="{item}">
          <sync-integration v-if="item.is_active" :item="item"/>
          <deactivate-integration v-if="item.is_active" :item="item"/>
          <activate-integration v-if="!item.is_active" :item="item"/>

          <action-delete v-if="!item.is_active" :action-url="`feed-connectors/nod/category-importer/${item.id}`"/>
          <edit-form :item="item"/>
        </template>

      </cit-data-table>
    </v-col>

  </v-row>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import IntegrationNavigation from '@/views/FeedConnectors/Nod/NodIntegrations/IntegrationNavigation'
import SyncIntegration from '@/views/FeedConnectors/Nod/NodIntegrations/SyncIntegration'
import ActivateIntegration from '@/views/FeedConnectors/Nod/NodIntegrations/ActivateIntegration'
import DeactivateIntegration from '@/views/FeedConnectors/Nod/NodIntegrations/DeactivateIntegration'
import CreateForm from '@/views/FeedConnectors/Nod/NodIntegrations/Create'
import EditForm from '@/views/FeedConnectors/Nod/NodIntegrations/Edit'
import AttributePageButton from '@/views/FeedConnectors/Nod/NodAttributes/AttributePageButton'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'

export default {
  components: {
    ActionDelete,
    AttributePageButton,
    DeactivateIntegration,
    ActivateIntegration,
    SyncIntegration,
    IntegrationNavigation,
    CitDataTable,
    CreateForm,
    EditForm,
  },
  computed: {
    categoryId () {
      return this.$route.params.categoryId
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '50',
          sortable: false
        },
        {
          text: 'Activ',
          value: 'is_active',
          display: 'boolean',
          sortable: false
        },
        {
          text: 'Procesare',
          value: 'integration_in_progress',
          display: 'boolean',
          sortable: false
        },
        {
          text: 'Subcategorie CIT',
          value: 'product_category.name',
          sortable: false
        },
        {
          text: 'Calitate',
          value: 'product_quality.name',
          sortable: false
        },
        {
          text: 'Categorie Nod',
          value: 'nod_category.name',
          sortable: false
        },
        {
          text: 'Adaos',
          value: 'multiplier',
          sortable: false,
          width: '100',
          align: 'center'
        },
        {
          text: 'Include defecte?',
          value: 'has_resealed',
          sortable: false,
          width: '100',
          align: 'center',
          display: 'boolean'
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          width: '100',
          sortable: false
        }
      ]
    }
  }
}
</script>
