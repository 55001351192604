<template>
  <action-add-new
    action-url="task-management/tasks/for-all"
    button-extra-class="d-block"
    button-label="Creaza Taskuri pentru toti"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details outlined placeholder="Titlu" single-line/>
        <error-messages :errors="errors.title"/>
      </div>

      <div class="mb-4">
        <v-textarea v-model="form.task" dense hide-details outlined placeholder="Continut" single-line/>
        <error-messages :errors="errors.task"/>
      </div>

      <div class="mb-4">
        <date-picker
          v-model="form.deadline_at" dense hide-details label="Data"
          outlined
        />
        <error-messages :errors="errors.deadline_at"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
