<template>
  <div class="select-filter">
    <v-select
      v-model="selectOption"
      :items="options"
      :label="placeholder"
      class="filter-select text-sm"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="machine_name"
      outlined
      single-line
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'OrderStatusFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      options: [],
      selectOption: ''
    }
  },
  mounted () {
    this.$http.get('orders/statuses')
      .then(({data}) => {
        this.options = data
      })
  },
  watch: {
    selectOption () {
      if (typeof this.selectOption === 'undefined') {
        this.$emit('filter', '', this.filterName)
      } else {
        this.$emit('filter', this.selectOption, this.filterName)
      }
    }
  }
}
</script>

<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
