<template>
  <settings-page-layout :navigation="navigation" :subtitle="subtitle" title="Management Locatii">
    <slot></slot>
  </settings-page-layout>
</template>

<script>
import SettingsPageLayout from '@/views/Settings/SettingsPageLayout'

export default {
  components: {
    SettingsPageLayout
  },
  props: {
    subtitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      navigation: [
        {
          label: 'Zone',
          routeName: 'settings-locations-county-zones'
        },
        {
          label: 'Tari',
          routeName: 'settings-locations-countries'
        },
        {
          label: 'Judete',
          routeName: 'settings-locations-counties'
        },
        {
          label: 'Orase',
          routeName: 'settings-locations-cities'
        }
      ]
    }
  }
}
</script>
