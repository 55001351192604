<template>
  <cit-data-table
    :fixed-filters="{
      parent_category_id:parent_category_id
    }"
    :headers="headers"
    :includes="['parent', 'badges', 'qualities']"
    class="my-4"
    dense
    height="auto"
    hide-filters
    hide-pagination
    hide-title
    per-page="100"
    resource-path="product-categories"

  >

    <template v-slot:item.display_order="{item, value}">
      <display-order-changer :item="item"/>
    </template>

    <template v-slot:item.badges="{item, value}">
      <div class="my-2">
        <v-chip v-for="(badge, index) in item.badges" :key="index" class="mx-2" x-small>
          {{ badge.name }}
        </v-chip>
      </div>
    </template>

    <template v-slot:item.qualities="{item}">
      <template v-if="item.qualities.length">
        <div v-for="(quality, index) in item.qualities" :key="index">
          {{ quality.name }}
        </div>
      </template>

    </template>

    <template v-slot:item.actions="{item}">

      <edit-form :item="item"/>

      <category-filter-access-button :item="item"/>

      <featured-specs-filters-access-button :item="item"/>

      <banners-access-button :item="item"/>

      <action-delete :action-url="`product-categories/${item.id}`"/>

    </template>


  </cit-data-table>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import EditForm from './Edit'
import DisplayOrderChanger from '@/views/ProductManagement/Categories/components/DisplayOrderChanger'
import CategoryFilterAccessButton from './CategoryFilters/AccessButton'
import FeaturedSpecsFiltersAccessButton from './FeaturedSpecsFilters/AccessButton'
import BannersAccessButton from './Banners/AccessButton'

export default {
  components: {
    DisplayOrderChanger,
    EditForm,
    ActionDelete,
    CategoryFilterAccessButton,
    FeaturedSpecsFiltersAccessButton,
    BannersAccessButton,
    CitDataTable
  },
  props: {
    parent_category_id: null
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65',
          sortable: false
        },
        {
          text: 'Ordine afisare',
          value: 'display_order',
          width: '65',
          sortable: false
        },
        {
          text: 'Denumire',
          value: 'name',
          width: 200,
          sortable: false
        },
        {
          text: 'Calitate',
          value: 'qualities',
          width: '200',
          sortable: false
        },
        {
          text: 'Badge-uri',
          value: 'badges',
          sortable: false
        },
        {
          text: 'Taxa verde',
          value: 'green_tax',
          width: '100',
          sortable: false,
          align: 'right'
        }
      ]
    }
  }
}
</script>
