<template>
  <v-sheet>
    <template v-if="cartLoaded">
      <cart-details :cart="cart"/>
    </template>
  </v-sheet>
</template>

<script>
import CartDetails from '@/views/Carts/CartDetails'

export default {
  name: 'SingleClientCartDisplay',
  components: {CartDetails},
  props: {
    customerId: {
      required: true
    }
  },
  data () {
    return {
      cart: {},
      cartLoaded: false
    }
  },
  mounted () {
    this.$http.get(`clients/${this.customerId}/cart`)
      .then(({data}) => {
        this.cart = data
        this.cartLoaded = true
      })
  }
}
</script>

