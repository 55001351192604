<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="['customer', 'customer.agent', 'product']"
      disable-actions
      resource-path="reports/wishlist"
    >
    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  components: {
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Client',
          value: 'customer.client_display_name',
          filterType: 'customer',
          filterName: 'customer_id',
          width: 150,
          sortable: false
        },
        {
          text: 'Agent',
          value: 'customer.agent.full_name',
          filterType: 'agent',
          filterName: 'customer.agent_id',
          width: 150,
          sortable: false
        },
        {
          text: 'Produs',
          value: 'product.full_name',
          filterName: 'products',
          filterType: 'product_id'
        },
        {
          text: 'Data adaugarii',
          value: 'created_at',
          filterType: 'date-range',
          width: 100,
          align: 'center',
          display: 'date'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('agents/loadItems')
  }
}
</script>
