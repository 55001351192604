<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="blue" dark small>+ Adauga produs</v-btn>
    </template>
    <v-card>
      <v-card-title>Alege produsul</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <product-selector v-model="product" :return-object="false" class="mb-5" :for-client="order.customer_id" />
        <v-text-field v-model="quantity" label="Cantiate"/>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="cancel">Anuleaza</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="add">Adauga</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductSelector from '@/components/autocompletes/ProductSelector'

export default {
  name: 'AddNewProductToOrder',
  components: {ProductSelector},
  props: {
    order: {
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      product: '',
      quantity: 1
    }
  },
  methods: {
    cancel () {
      this.product = ''
      this.quantity = 1
      this.dialog = false
    },
    add () {
      this.$store.dispatch('startLoading')
      this.$http.post(`/orders/${this.order.id}`, {
        'product_id': this.product,
        'quantity': this.quantity
      }).then(({data}) => {
        this.$emit('updated', data)
      }).finally(() => {
        this.cancel()
        this.$store.dispatch('stopLoading')
      })
    }
  }
}
</script>

<style scoped>

</style>
