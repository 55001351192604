<template>
  <div>
    Agentul a inregistrat o discutie cu clientul <strong>
    <router-link :to="customerRouteLink">{{ values.customer.client_display_name }}</router-link>
  </strong>
  </div>
</template>

<script>
export default {
  name: 'ClientMessageCreated',
  props: {
    values: {}
  },
  computed: {
    customerRouteLink () {
      return {
        name: 'view-client',
        params: {
          id: this.values.customer_id
        }
      }
    }
  }
}
</script>
