<template>
  <v-select
    v-model="selectOption"
    :items="qualities"
    dense
    hide-details
    item-text="name"
    item-value="id"
    label="Selecteaza calitatile"
    outlined
  ></v-select>
</template>

<script>
export default {
  name: 'CitQualitySelector',
  props: {
    value: '',
    category: ''
  },
  data () {
    return {
      qualities: []
    }
  },
  computed: {
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || ''
        this.$emit('input', val)
      }
    }
  },
  watch: {
    category (value) {
      this.selectOption = ''
      this.$http.get(`product-qualities?filter[category]=${value}&page_size=all`)
        .then(({data}) => {
          this.qualities = data.data
        })
    }
  }
}
</script>

