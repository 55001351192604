<template>
  <v-file-input
    v-model="file"
    :accept="accept"
    :label="label"
    :prepend-inner-icon="prependInnerIcon"
    dense
    hide-details
    outlined
    prepend-icon=""
    @change="processFile"
  ></v-file-input>
</template>

<script>
export default {
  name: 'Base64FileUploader',
  props: {
    value: {},
    accept: {
      type: String,
      default: 'image/*'
    },
    label: {
      type: String,
      default: 'Imagine'
    },
    prependInnerIcon: {
      type: String,
      default: 'mdi-file-image'
    }
  },
  data () {
    return {
      file: null
    }
  },
  methods: {
    processFile (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.$emit('input', e.target.result)
      }
      reader.readAsDataURL(file)
    },
    clearFile () {
      this.file = null
    }
  }
}
</script>

