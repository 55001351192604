<template>
  <div class="select-filter">
    <v-autocomplete
      v-model="selectOption"
      :items="items"
      :label="placeholder"
      :loading="loading"
      :search-input.sync="search"
      cache-items
      class="filter-select text-sm"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="id"
      outlined
      single-line
    ></v-autocomplete>
  </div>
</template>

<script>

export default {
  name: 'ProductComponentGroupFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      paginatedData: [],
      loading: false,
      search: '',
      selectOption: ''
    }
  },
  computed: {
    items () {
      return this.paginatedData.data
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    },
    selectOption () {
      const option = this.selectOption || ''
      this.$emit('filter', option, this.filterName)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/product-component-groups?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>


<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>

