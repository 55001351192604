<template>
  <div class="button-wrapper">
    <vs-tooltip text="Muta slotul in stanga. Schimbarea va afecata modul in care se va genera numele produselor">
      <vs-button
          v-show="componentTypeSlot.display_order>1"
          icon="fa-arrow-to-left"
          icon-pack="fal" type="border"
          @click="moveLeft"/>
    </vs-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MoveLeftButton',
  props: {
    componentTypeSlot: {
      required: true
    }
  },
  computed: {
    slotsCount () {
      return this.$store.getters['productGenerator/slotsCount']
    }
  },
  methods: {
    moveLeft () {
      this.$store.dispatch('productGenerator/moveLeft', this.componentTypeSlot)
    }
  }
}
</script>

<style scoped>

</style>