<template>
  <v-autocomplete
    v-model="selectOption"
    :disabled="disabled"
    :item-text="itemText"
    :item-value="itemValue"
    :items="searchedList"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    class="filter-select text-sm"
    clearable
    dense
    hide-details
    no-filter
    outlined
  ></v-autocomplete>
</template>

<script>

export default {
  props: {
    value: {},
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    endpoint: {
      type: String,
      required: true
    },
    filterName: {
      type: String,
      default: 'name'
    },
    fixedFilters: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      paginatedData: {},
      loading: false,
      search: ''
    }
  },
  computed: {
    searchedList () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || ''
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''

      const filters = {
        [this.filterName]: search,
        ...this.fixedFilters
      }
      // ${filters.map((item, key) => `filter[${key}]=${item}`)}

      this.$http.get(`${this.endpoint}?${Object.keys(filters).map((key) => {
        return `filter[${key}]=${filters[key]}`
      }).join('&')}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
