<template>
  <div>
    <vs-tooltip position="right" text="Descarca factura proforma">
      <v-btn @click="downloadProforma" :disabled="loading" >
        <template v-if="loading">Se descarca...</template>
        <template v-else>Descarca Factura proforma</template>
      </v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
export default {
  name: 'DownloadProformaButton',
  props: {
    orderId: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    downloadProforma () {
      this.loading = true
      this.$http.get(`orders/${this.orderId}/proforma`, {
        responseType: 'arraybuffer'
      })
        .then(({data}) => {
          const blob = new Blob([data], {type: 'application/pdf'})
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
