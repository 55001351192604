<template>
  <action-add-new action-url="reseller-discounts" button-label="Adauga Prag de discount nou">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.start" dense persistent-hint hint="Vanzari lunare minimale pentru a se incadra la acest prag de discount"
                      label="De la" outlined
                      type="number"/>
        <error-messages :errors="errors.start"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.end" dense persistent-hint hint="Vanzari lunare maximale pentru a se incadra la acest prag de discount"
                      label="Pana la" outlined
                      type="number"/>
        <error-messages :errors="errors.end"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.discount_index" dense persistent-hint hint="Pragul de discount" label="Discount" outlined
                      type="number"/>
        <error-messages :errors="errors.discount_index"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.max_reseller_multiplier" dense persistent-hint hint="Coeficientul de multiplicare maximal pe care il poate aplica distribuitorul care se incadreaza la acest prag de discount"
                      label="Adaos maximal"
                      outlined type="number"/>
        <error-messages :errors="errors.max_reseller_multiplier"/>
      </div>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    Base64FileUploader,
    ErrorMessages,
    ActionAddNew
  }

}
</script>
