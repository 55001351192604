<template>
  <action-edit
    :action-url="`reseller-discounts/${item.id}`"
    :item="item"
    button-label="Editeaza Pragul de discount"
  >
    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field step="0.01" v-model="form.start" dense hint="Vanzari lunare minimale pentru a se incadra la acest prag de discount"
                      label="De la"
                      outlined persistent-hint
                      type="number"/>
        <error-messages :errors="errors.start"/>
      </div>
      <div class="mb-4">
        <v-text-field step="0.01" v-model="form.end" dense hint="Vanzari lunare maximale pentru a se incadra la acest prag de discount"
                      label="Pana la"
                      outlined persistent-hint
                      type="number"/>
        <error-messages :errors="errors.end"/>
      </div>
      <div class="mb-4">
        <v-text-field step="0.01" v-model="form.discount_index" dense hint="Pragul de discount" label="Discount" outlined
                      persistent-hint
                      type="number"/>
        <error-messages :errors="errors.discount_index"/>
      </div>
      <div class="mb-4">
        <v-text-field step="0.01" v-model="form.max_reseller_multiplier" dense hint="Coeficientul de multiplicare maximal pe care il poate aplica distribuitorul care se incadreaza la acest prag de discount"
                      label="Adaos maximal"
                      outlined
                      persistent-hint type="number"/>
        <error-messages :errors="errors.max_reseller_multiplier"/>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    Base64FileUploader,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
