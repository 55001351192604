<template>
  <action-add-new action-url="location/cities" button-label="Adauga oras nou">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
        <error-messages :errors="errors.name"/>
      </div>
      <div class="mb-4">
        <autocomplete-selector v-model="form.county_id" endpoint="location/counties" label="Judet"/>
        <error-messages :errors="errors.county_id"/>
      </div>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'

export default {
  components: {
    AutocompleteSelector,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
