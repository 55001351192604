<template>
  <div>
    <vs-tooltip text="Documente tehnice">
      <v-btn
        class="ml-2"
        icon
        x-small
        @click="openDialog"
      >
        <v-icon x-small>fal fa-file-pdf</v-icon>
      </v-btn>
    </vs-tooltip>

    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Documente tehnice produs
        </v-card-title>

        <v-card-text>
          <template v-if="documents.length">
            <v-data-table
              :headers="headers"
              :hide-default-footer="true"
              :items="documents"
              dense
              item-key="id"
            >
              <template v-slot:item.actions="{item}">
                <div class="d-flex items-center">
                  <BaseProductEditTechnicalDocItem :document-id="item.id" :base-product-id="baseProductId" @document-edited="getDocuments" />
                  <v-spacer />
                  <vs-tooltip text="Sterge">
                    <v-btn
                      icon
                      @click="deleteDocument(item.id)"
                      color="red"
                      x-small
                      class="mx-2"
                    >
                      <v-icon x-small>fal fa-trash</v-icon>
                    </v-btn>
                  </vs-tooltip>
                </div>
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <p class="text-center my-5 text-xl font-weight-bold">Nu exista documente.</p>
          </template>
          <base-product-technical-doc-item :base-product-id="baseProductId" @document-added="getDocuments"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Inchide
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ErrorMessages from "@/components/general-form/ErrorMessages";
import BaseProductTechnicalDocItem from "@/views/ProductManagement/BaseProductManager/BaseProductTechnicalDocItem";
import BaseProductEditTechnicalDocItem
  from "@/views/ProductManagement/BaseProductManager/BaseProductEditTechnicalDocItem";

export default {
  name: "BaseProductTechnicalDoc",
  components: {BaseProductEditTechnicalDocItem, BaseProductTechnicalDocItem, ErrorMessages},
  props: {
    baseProductId: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      documents: {},
      headers: [
        {
          text: 'Tip',
          value: 'type',
          width: '60px',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: '50px',
          sortable: false
        }
      ]
    }
  },
  methods: {
    getDocuments() {
      this.$store.dispatch('startLoading')
      this.$http.get(`base-products/${this.baseProductId}/links`)
        .then(({data}) => {
          this.dialog = true
          this.documents = data
          this.$store.dispatch('stopLoading')
        })
    },
    openDialog() {
      this.dialog = true
      this.getDocuments()
    },
    deleteDocument(id) {
      this.$store.dispatch('startLoading')
      this.$http.delete(`base-products/${this.baseProductId}/links/${id}`)
        .then(() => {
          this.getDocuments()
          this.$vs.notify({
            title: 'Success',
            text: 'Documentul a fost sters!',
            color: 'success'
          })
        })
        .catch((response) => {
          this.$vs.notify({
            title: 'Eroare',
            text: response,
            color: 'error'
          })
        })
      .finally(() => {
        this.$store.dispatch('stopLoading')
      })
    }
  }
}
</script>
