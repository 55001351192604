<template>
  <div class="bp-product-images-uploader">
    <VueFileAgent
        ref="vueFileAgent"
        v-model="fileRecords"
        :accept="'image/*'"
        :deletable="true"
        :errorText="errorText"
        :helpText="text"
        :maxFiles="maxFiles"
        :maxSize="'1MB'"
        :meta="true"
        :multiple="!single"
        :theme="'list'"
        :upload-headers="uploadHeaders"
        :upload-url="uploadUrl"
        sortable="handle"
    ></VueFileAgent>
  </div>
</template>

<script>
export default {
  name: 'BpProductImagesUploader',
  props: {
    single: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Choose an image'
    },
    existing: {
      default: false
    }
  },
  computed: {
    maxFiles () {
      if (this.single) {
        return 1
      } else {
        return 10
      }
    },
    token () {
      return this.$store.getters['auth/token']
    },
    uploadHeaders () {
      return {
        'Language': 'ro',
        'Authorization': `Bearer ${this.token}`
      }
    }
  },
  data () {
    return {
      fileRecords: [],
      uploadUrl: '',
      errorText: {
        type: 'Invalid file type. Only images Allowed',
        size: 'Files should not exceed 1MB in size'
      }
    }
  },
  watch: {
    fileRecords: {
      deep: true,
      handler (newValue) {
        if (typeof newValue !== 'undefined') {
          this.$emit('input', newValue)
        }
      }
    },
    existing () {
      if (this.existing) {
        if (this.single) {
          this.fileRecords = []
          this.fileRecords.push(this.existing)
        } else {
          this.fileRecords = this.existing
        }
      }
    }
  },
  created () {
    const baseURL = process.env.VUE_APP_BASE_URL_API
    this.uploadUrl = `${baseURL}/app/base-products/images`

    if (this.existing) {
      if (this.single) {
        this.fileRecords = []
        this.fileRecords.push(this.existing)
      } else {
        this.fileRecords = this.existing
      }
    }
  }
}
</script>

<style lang="scss">
.bp-product-images-uploader {
  .file-preview-wrapper {
    display: flex;

    .file-sortable-handle {
      position: relative;
    }

    .file-preview-wrapper-image {
      padding-left: 20px;
      width: 100%;
    }
  }
}
</style>
