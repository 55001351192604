<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="[
        'product_brand'
      ]"
      resource-path="feed-connectors/nod/manufacturers"
    >

      <template v-slot:actions>
        <import/>
      </template>

      <template v-slot:item.actions="{item}">
        <edit-form :item="item"/>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import Import from '@/views/FeedConnectors/Nod/NodBrands/Import'
import EditForm from '@/views/FeedConnectors/Nod/NodBrands/Edit'
import Edit from '@/views/Settings/LocationsManagement/CountyZones/Edit'

export default {
  components: {
    Edit,
    Import,
    ActionDelete,
    CitDataTable,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Brand NOD',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Brand CIT Grup',
          value: 'product_brand.name',
          filterType: 'text',
          sortable: true
        }
      ]
    }
  }
}
</script>
