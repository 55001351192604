<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <div class="mb-5">
          <v-text-field v-model="form.name" label="Nume"/>
          <error-messages :errors="errors.name"></error-messages>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.filter_display_order" label="Ordine afisare in filtru"/>
          <error-messages :errors="errors.filter_display_order"></error-messages>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import lodash from 'lodash'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'

export default {
  name: 'EditAttributeValue',
  components: {
    ProductCategorySelector,
    ErrorMessages
  },
  data () {
    return {
      form: {
        name: '',
        filter_display_order: 1,
        attribute_id: this.$route.params.id
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('attributeValues/updateItem', {
        id: this.form.id,
        form: this.form
      })
        .catch(({response}) => {
          this.errors = response.data.errors
        })
    }
  },
  mounted () {
    const item = this.$store.getters['attributeValues/getItemForEdit']
    this.form = lodash.cloneDeep(item)
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
