var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('stock-updater',{attrs:{"component":_vm.stockUpdater.item,"dialog":_vm.stockUpdater.isOpened},on:{"close":_vm.closeStockUpdater}}),_c('cit-data-table',{attrs:{"appends":[
      'has_attribute_values',
      'badges_ids'
    ],"headers":_vm.headers,"includes":[
      'categories.parent',
      'group'
    ],"resource-path":"product-components"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('create-component')]},proxy:true},{key:"item.categories",fn:function(ref){
    var item = ref.item;
return [(item.categories.length)?_c('div',{staticClass:"category-column"},_vm._l((item.categories),function(category){return _c('div',{key:category.id,staticClass:"category-name-list",domProps:{"innerHTML":_vm._s(_vm.getTitleWithParent(category))}})}),0):_vm._e()]}},{key:"item.display_order",fn:function(ref){
    var item = ref.item;
return [_c('text-inline-field',{attrs:{"action-url":("product-components/" + (item.id) + "/display-order"),"value":item.display_order,"field-name":"display_order","type":"number"}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('action-delete',{attrs:{"action-url":("product-components/" + (item.id))}}),_c('edit-component',{attrs:{"item":item}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","icon":""},on:{"click":function($event){return _vm.cloneItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-clone")])],1)]}}],null,true)},[_c('span',[_vm._v("Cloneaza componenta "),_c('strong',[_vm._v("(fara atribute)")])])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{name:'pm-component-attributes', params:{id:item.id}},"bottom":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-calculator-alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Vezi atributele acestei componente")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openStockUpdated(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-balance-scale")])],1)]}}],null,true)},[_c('span',[_vm._v("Actualizare stoc și uniformizare")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }