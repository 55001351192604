var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"fixed-filters":{
      category_id:_vm.$route.params.categoryId,
      attribute_id:_vm.$route.params.attributeId,
    },"headers":_vm.headers,"includes":[
      'product_attribute_value'
    ],"resource-path":"feed-connectors/nod/attribute-values"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('attribute-page-button',{attrs:{"category-id":_vm.$route.params.categoryId,"button-label":"Inapoi la atribute","icon":"fal fa-angle-left"}})]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('change',{attrs:{"item":item}}),_c('add-new',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }