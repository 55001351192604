<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <div class="mb-5">
          <v-text-field v-model="form.name" label="Denumire scurta reteta"/>
          <error-messages :errors="errors.name"></error-messages>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.internal_name" label="Denumire Gestiune/Site"/>
          <error-messages :errors="errors.internal_name"></error-messages>
        </div>
        <div class="mb-5">
          <product-component-group-selector v-model="form.group_id" add-new-button label="Grupa componentei"/>
          <error-messages :errors="errors.group_id"></error-messages>
        </div>
        <div class="mb-5">
          <product-component-selector v-model="form.component_type_id" label="Tipul componentei"/>
          <error-messages :errors="errors.component_type_id"></error-messages>
        </div>
        <div class="mb-5">
          <badge-selector v-model="form.badges"/>
          <error-messages :errors="errors.badges"/>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.price" label="Pret"></v-text-field>
          <error-messages :errors="errors.price"></error-messages>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.buying_price" label="Pret achizitie"></v-text-field>
          <error-messages :errors="errors.buying_price"></error-messages>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.stock" label="Stoc"></v-text-field>
          <error-messages :errors="errors.stock"></error-messages>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductComponentSelector from '@/components/autocompletes/ProductComponentSelector'
import ProductComponentGroupSelector from '@/components/autocompletes/ProductComponentGroupSelector'
import BadgeSelector from '@/components/autocompletes/BadgeSelector'

export default {
  name: 'AddComponent',
  components: {
    BadgeSelector,
    ProductComponentGroupSelector,
    ProductComponentSelector,
    ErrorMessages
  },
  data () {
    return {
      form: {
        name: '',
        internal_name: '',
        group_id: '',
        show_as_product: false,
        price: 0,
        buying_price: 0,
        stock: 0,
        component_type_id: 0,
        badges: []
      },
      errors: {}
    }
  },
  computed: {
    clonedItem () {
      return this.$store.getters['components/getClonedItem']
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('startLoading')
      this.$store.dispatch('components/storeItem', this.form)
        .catch(({response}) => {
          this.errors = response.data.errors
        })
    }
  },
  watch: {
    clonedItem: {
      deep: true,
      immediate: true,
      handler (item) {
        if (item === false) {
          this.form = {
            name: '',
            internal_name: '',
            group_id: '',
            show_as_product: false,
            price: 0,
            buying_price: 0,
            stock: 0,
            component_type_id: 0
          }
        } else {
          this.form = JSON.parse(JSON.stringify(item))
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
