<template>
  <div>
    <v-text-field v-model="name" dense hide-details label="Denumire Feed" outlined/>
  </div>
</template>

<script>
export default {
  name: 'FeedNameInput',
  props: {
    provider: {
      type: Number | Boolean,
      default: false
    },
    value: {}
  },
  computed: {
    name: {
      get () {
        return this.value
      },
      set (value) {
        value = value || ''
        this.$emit('input', value)
      }
    }
  },
  watch: {
    provider (value) {
      if (value) {
        this.$http.get(`/feed-connectors/general/providers/${value}`)
          .then(({data}) => {
            const name = data.name
            const date = new Date()
            const month = date.getUTCMonth() + 1 //months from 1-12
            const day = date.getUTCDate()
            const year = date.getUTCFullYear()
            this.name = `${name.substring(0, 2)}-${day}-${month}-${year}`
          })
          .finally(() => {
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
