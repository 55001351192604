<template>
  <v-dialog v-model="dialog" max-width="300px" @click:outside="cancel">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="blue" small dark>Alege un produs</v-btn>
    </template>
    <v-card>
      <v-card-title>Alege produsul</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <product-selector v-model="product" :return-object="true" :status="status"/>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="cancel">Anuleaza</v-btn>
        <v-btn color="blue darken-1" text @click="add">Adauga</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductSelector from '@/components/autocompletes/ProductSelector'

export default {
  name: 'productSelectorDialog',
  components: {ProductSelector},
  props: {
    status: {
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      product: ''
    }
  },
  methods: {
    cancel () {
      this.product = ''
      this.dialog = false
    },
    add () {
      this.$emit('added', this.product)
      this.cancel()
    }
  }
}
</script>

<style scoped>

</style>
