<template>
  <order-list
    :extra-filters="{
        customer_id: customerId
      }"
    :resource-path="`orders`"
  >
  </order-list>
</template>

<script>
import OrderList from '@/views/Orders/OrderList'

export default {
  components: {
    OrderList
  },
  props: {
    customerId: {
      required: true
    }
  }
}
</script>
