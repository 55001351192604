<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <basic-button
        v-bind="attrs"
        v-on="on"
        :loading="loading"
        color="black"
        icon="fal fa-save"
        is-icon
        tooltip="Salveaza produsul"
        @click="save"
      />
    </template>
    <span>Descarca structura</span>
  </v-tooltip>
</template>

<script>
import BasicButton from '@/components/general-form/BasicButton'

export default {
  components: {
    BasicButton
  },
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$http
        .post(`feed-connectors/general/integrations/row/${this.item.id}`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes',
            text: 'Produsul a fost salvat cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })

    }
  }

}
</script>
