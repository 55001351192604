export default {
  paginatedData: {
    data:[],
    last_page:1
  },
  sort: '',
  page: 1,
  filters: {},
  queryString: '',

  editItem: {}
}

