<template>
  <div>
    <v-text-field v-model="attr.name" label="Nume atribut" placeholder="ex. Tip carcasa"/>
    <error-messages :errors="errors.name"/>

    <attribute-group-selector v-model="attr.group_id" label="Grup"/>
    <error-messages :errors="errors.group_id"/>

    <v-btn :disabled="loading" class="d-block mt-5" @click="save">Creaza</v-btn>
  </div>
</template>

<script>
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AttributeGroupSelector from '@/components/autocompletes/AttributeGroupSelector'

export default {
  name: 'CreateAttribute',
  components: {
    AttributeGroupSelector,
    ErrorMessages,
    ProductCategorySelector
  },
  props: {
    category_id: {}
  },
  data () {
    return {
      attr: {
        name: '',
        group_id: ''
      },
      loading: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.$http.post('/product-attributes', {
        name: this.attr.name,
        group_id: this.attr.group_id
      })
        .then(({data}) => {
          this.attr = {
            name: '',
            product_category_id: ''
          }
          this.$emit('created', data)
          this.$vs.notify({
            title: 'Success',
            text: 'Tipul de atribut a fost adaugat in baza de date!',
            color: 'success'
          })
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
