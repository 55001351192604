<template>
  <v-sheet>
    <v-row>
      <v-col cols="2">
        <v-navigation-drawer permanent>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list nav>
            <v-list-item v-for="(item, index) in navigation" :key="index" :to="{name:item.routeName}" link>
              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10">
        <slot></slot>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    navigation: {
      type: Array,
      required: true

    }
  }
}
</script>
