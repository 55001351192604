<template>
  <action-add-new action-url="clients/company-clients" button-label="Adauga Persoana Juridica" dialog-width="750">

    <template v-slot:fields="{form, errors}">

      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Date client</v-card-title>
            <v-card-text>

              <div class="mb-4">
                <v-text-field v-model="form.client_first_name" dense hide-details label="Nume" outlined/>
                <error-messages :errors="errors.client_first_name"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.client_last_name" dense hide-details label="Prenume" outlined/>
                <error-messages :errors="errors.client_last_name"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.client_phone" dense hide-details label="Nr. Telefon" outlined/>
                <error-messages :errors="errors.client_phone"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.client_email" dense hide-details label="Email" outlined type="email"/>
                <error-messages :errors="errors.client_email"/>
              </div>

              <div class="mb-4">
                <autocomplete-selector v-model="form.client_zone_id" endpoint="location/zones" label="Zona"/>
                <error-messages :errors="errors.client_zone_id"/>
              </div>

              <div class="mb-4">
                <county-selector v-model="form.client_county_id" :include-all="true" hide-details label="Judetul"
                                 outlined/>
                <error-messages :errors="errors.client_county_id"/>
              </div>

              <div class="mb-4">
                <city-selector v-model="form.client_city_id" :county="form.client_county_id" dense hide-details
                               label="Localitatea" outlined/>
                <error-messages :errors="errors.client_city_id"/>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Date Firma</v-card-title>
            <v-card-text>

              <div class="mb-4">
                <v-text-field v-model="form.company_name" dense hide-details label="Nume" outlined/>
                <error-messages :errors="errors.company_name"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_vat" dense hide-details label="Cui" outlined/>
                <error-messages :errors="errors.company_vat"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_reg_number" dense hide-details label="Nr.Reg.Com." outlined/>
                <error-messages :errors="errors.company_reg_number"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_contact_person" dense hide-details label="Persoana de contact"
                              outlined/>
                <error-messages :errors="errors.company_contact_person"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_phone_mobile" dense hide-details label="Nr. de telefon" outlined/>
                <error-messages :errors="errors.company_phone_mobile"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_phone" dense hide-details label="Nr. de telefon (fix)" outlined/>
                <error-messages :errors="errors.company_phone"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_activity" dense hide-details label="Activitate" outlined/>
                <error-messages :errors="errors.company_activity"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_caen" dense hide-details label="Cod Caen" outlined/>
                <error-messages :errors="errors.company_caen"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_caen_explained" dense hide-details label="Explicatie Cod caen"
                              outlined/>
                <error-messages :errors="errors.company_caen_explained"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_net_worth" dense hide-details label="Cifra de afacere" outlined/>
                <error-messages :errors="errors.company_net_worth"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_number_of_employees" dense hide-details label="Numar angajati"
                              outlined/>
                <error-messages :errors="errors.company_number_of_employees"/>
              </div>

              <div class="mb-4">
                <v-text-field v-model="form.company_website" dense hide-details label="Website" outlined/>
                <error-messages :errors="errors.company_website"/>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ZoneSelector from '@/components/autocompletes/ZoneSelector'
import AutocompleteSelector from '@/components/autocompletes/AutocompleteSelector'
import CountySelector from '@/components/autocompletes/CountySelector'
import CitySelector from '@/components/autocompletes/CitySelector'

export default {
  components: {
    CitySelector,
    CountySelector,
    AutocompleteSelector,
    ZoneSelector,
    ErrorMessages,
    ActionAddNew
  }
}
</script>
