var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{ref:"table",attrs:{"appends":[
      'email_confirmed',
      'last_order_at',
      'birthday'
    ],"headers":_vm.headers,"resource-path":"clients/private-clients"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [_c('create-form'),_c('v-spacer'),_c('import-customers',{attrs:{"resource-path":"clients/private-clients"}})]}},{key:"item.birthday",fn:function(ref){
    var item = ref.item;
return [_c('birthday-updated',{attrs:{"item":item}})]}},{key:"filter.interest_lists",fn:function(ref){
    var header = ref.header;
    var updateFilter = ref.updateFilter;
return [_c('interest-list-filter',{attrs:{"filter-name":"interest_lists"},on:{"filter":updateFilter}})]}},{key:"item.confirmed",fn:function(ref){
    var item = ref.item;
return [_c('client-confirmed',{attrs:{"value":item.email_confirmed}}),(!item.email_confirmed)?_c('div',[_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.resendConfirmationEmail(item)}}},[_vm._v("Retrimite mail de confirmare")])],1):_vm._e()]}},{key:"item.agent",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('client-agent-replacer',{attrs:{"agent":value,"client":item},on:{"changed":_vm.reload}})]}},{key:"item.discount",fn:function(ref){
    var item = ref.item;
return [_c('client-discount-select',{attrs:{"client":item},on:{"changed":_vm.reload}})]}},{key:"item.interest_lists",fn:function(ref){
    var item = ref.item;
return [_c('client-interest-select',{attrs:{"client":item},on:{"changed":_vm.reload}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('view-button',{attrs:{"to":{name:'view-client', params:{id:item.id}},"target":"_self"}}),_c('action-delete',{attrs:{"action-url":("clients/" + (item.id))}}),_c('transform-button',{attrs:{"endpoint":("clients/" + (item.id) + "/make-it"),"tooltip":"Transforma in IT-ist"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }