<template>
  <div class="inline-block">
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-xl font-bold text-center">
          Esti sigur ca doresti sa stergi acest element?'
        </v-card-title>
        <v-card-text>
          <v-spacer></v-spacer>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog=false">Anuleaza</v-btn>
          <v-btn text @click="deleteItems">Sterge</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vs-tooltip text="Modificarea discounturilor pentru toate produsele selectate">
      <v-btn class="mx-2" small color="error" @click="dialog = true">
        <i class="fal fa-trash-alt mr-2"></i>Sterge
      </v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
import DeleteButton from '@/components/general-form/DeleteButton'

export default {
  name: 'DeleteSelected',
  components: {DeleteButton},
  props: {
    table: {
      required: true,
      default () {
        return {}
      }
    },
    selectedItems: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    selectedIds () {
      return this.selectedItems.map(item => item.id)
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    deleteItems () {
      this.dialog = false
      this.table.startLoading()
      this.table.calculateQueryString()

      this.$emit('start-loading')
      this.$http.post(`products/delete?${this.table.queryString}`, {
        all_selected: this.table.bulkAll,
        products: this.selectedIds,
        filter: this.table.allFilters()
      })
        .then(() => {
          this.table.loadItems()
        })
        .catch(() => {
          this.table.stopLoading()
        })
    }
  }
}
</script>
