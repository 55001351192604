<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      :includes="[
        'customer',
        'customer.agent',
        'customer.company'
      ]"
      resource-path="analytics"
    >
      <template v-slot:item.referrer_page="{item}">
        <a :href="item.full_referrer_page" target="_blank">{{ item.referrer_page }}</a>
      </template>

      <template v-slot:item.accessed_page="{item}">
        <a :href="item.full_accessed_page" target="_blank">{{ item.accessed_page }}</a>
      </template>

      <template v-slot:item.time="{item}">
        {{ item.time | toSeconds }}
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  name: 'ActivityList',
  components: {
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Nume Client',
          value: 'customer.client_display_name',
          filterType: 'customer',
          filterName: 'customer',
          sortable: false
        },
        {
          text: 'Agent',
          value: 'customer.agent.full_name',
          filterType: 'agent',
          filterName: 'agent',
          sortable: false
        },
        {
          text: 'Sursa',
          value: 'referrer_page',
          sortable: false,
          width: '250'
        },
        {
          text: 'Url',
          value: 'accessed_page',
          sortable: false,
          width: '250'
        },
        {
          text: 'Durata',
          value: 'time',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          sortable: true
        }

      ]
    }
  },
  filters: {
    toSeconds (value) {
      value = parseInt(value)
      return `${Math.floor(value / 1000)}s`
    }
  },
  mounted () {
    this.$store.dispatch('agents/loadItems')
  }
}
</script>
