var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('base-product-editor'),_c('cit-data-table',{attrs:{"appends":[
      'stock_value_color',
      'is_deleted',
      'absolute_permalink'
    ],"fixedFilters":{
      category: _vm.currentCategoryId
    },"headers":_vm.headers,"height":"55vh","resource-path":"products","show-select":"","show-before":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [(_vm.$store.getters['auth/currentUser'].is_admin)?_c('new-button',{attrs:{"to":{name:'generate-product'}}},[_vm._v("Adauga Produs")]):_vm._e(),_c('v-spacer'),_c('export-products')]}},{key:"before_table",fn:function(ref){
    var table = ref.table;
return [_c('v-col',[_c('extra-filters',{attrs:{"category-id":_vm.currentCategoryId},on:{"filter":table.updateFilter}})],1)]}},{key:"bulk_select_actions",fn:function(ref){
    var selectedItems = ref.selectedItems;
    var table = ref.table;
return [(selectedItems.length > 0)?[_c('delete-selected',{attrs:{"selected-items":selectedItems,"table":table}}),_c('update-selected-discount-end-user',{attrs:{"selected-items":selectedItems,"table":table}}),_c('update-selected-discount',{attrs:{"selected-items":selectedItems,"table":table}})]:_vm._e()]}},{key:"filter.category",fn:function(ref){
    var header = ref.header;
    var updateFilter = ref.updateFilter;
return [_c('product-subcategory-filter',{attrs:{"parent-category-id":_vm.currentCategoryId,"filter-name":"category.id"},on:{"filter":updateFilter}})]}},{key:"filter.base_product",fn:function(ref){
    var header = ref.header;
    var updateFilter = ref.updateFilter;
return [_c('base-product-filter',{attrs:{"parent-category-id":_vm.currentCategoryId,"filter-name":"base_product_id"},on:{"filter":updateFilter}})]}},{key:"filter.stock_value",fn:function(ref){
    var header = ref.header;
    var updateFilter = ref.updateFilter;
return [_c('stock-status-filter',{attrs:{"filter-name":"stock_value"},on:{"filter":updateFilter}})]}},{key:"filter.actions",fn:function(ref){
    var header = ref.header;
    var updateFilter = ref.updateFilter;
return [_c('trash-status-filter',{attrs:{"filter-name":"trashed"},on:{"filter":updateFilter}})]}},{key:"item.discount_end_users",fn:function(ref){
    var item = ref.item;
    var loadItems = ref.loadItems;
    var startLoading = ref.startLoading;
    var stopLoading = ref.stopLoading;
return [_c('div',{staticClass:"flex justify-around"},[_c('discount-changer',{attrs:{"item":item,"field":"discount_end_users"},on:{"reload":loadItems,"start-loading":startLoading,"stop-loading":stopLoading}})],1)]}},{key:"item.discount_resellers",fn:function(ref){
    var item = ref.item;
    var loadItems = ref.loadItems;
    var startLoading = ref.startLoading;
    var stopLoading = ref.stopLoading;
return [_c('div',{staticClass:"flex justify-around"},[_c('discount-changer',{attrs:{"item":item,"field":"discount_resellers"},on:{"reload":loadItems,"start-loading":startLoading,"stop-loading":stopLoading}})],1)]}},{key:"item.max_discount",fn:function(ref){
    var item = ref.item;
    var loadItems = ref.loadItems;
    var startLoading = ref.startLoading;
    var stopLoading = ref.stopLoading;
return [_c('div',{staticClass:"flex justify-around"},[_c('discount-changer',{attrs:{"item":item,"field":"max_discount"},on:{"reload":loadItems,"start-loading":startLoading,"stop-loading":stopLoading}})],1)]}},{key:"item.category",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('div',[_c('strong',[_vm._v(_vm._s(value.name))])]),(item.quality)?_c('div',[(value.name !== item.quality.name)?[_c('small',[_vm._v("Calitate: "),_c('strong',[_vm._v(_vm._s(item.quality.name))])])]:_vm._e()],2):_vm._e()]}},{key:"item.base_product",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('div',{staticClass:"flex justify-between items-center"},[_c('div',[_vm._v(_vm._s(value.name))]),(!item.is_external)?[_c('base-product-editor-trigger',{attrs:{"base-product-id":value.id}})]:_vm._e()],2)]}},{key:"item.stock_value",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.stock_value_color,"dark":""}},[_vm._v(_vm._s(item.stock_value))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
    var table = ref.table;
return [(item.config_id)?_c('edit-button',{attrs:{"to":_vm.getEditProductRoute(item)}}):_vm._e(),_c('view-button',{attrs:{"href":item.absolute_permalink}}),(item.is_deleted)?_c('action-restore',{attrs:{"action-url":("products/" + (item.id))}}):_c('action-delete',{attrs:{"action-url":("products/" + (item.id))}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }