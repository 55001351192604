<template>

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="{...attrs, ...attributes}"
        v-on="{...on,...listeners}"
        color="dark"
        icon
      >
        <v-icon>fal fa-clone</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'CloneButton',
  props: {
    text: {
      default: 'Clonare',
      type: String
    }
  },
  computed: {
    attributes () {
      return {
        ...this.$attrs
      }
    },
    listeners () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>
