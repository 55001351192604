var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cit-data-table',{attrs:{"fixed-filters":{
    has_parent:'yes'
  },"headers":_vm.headers,"includes":['parent', 'badges', 'qualities'],"height":"75vh","hide-filters":"","hide-pagination":"","per-page":"100","resource-path":"product-categories","show-expand":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('create-form')]},proxy:true},{key:"item.display_order",fn:function(ref){
  var item = ref.item;
  var value = ref.value;
return [_c('display-order-changer',{attrs:{"item":item}})]}},{key:"item.badges",fn:function(ref){
  var item = ref.item;
  var value = ref.value;
return [_c('div',{staticClass:"my-2"},_vm._l((item.badges),function(badge,index){return _c('v-chip',{key:index,staticClass:"mx-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(badge.name)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('edit-form',{attrs:{"item":item}}),_c('category-filter-access-button',{attrs:{"item":item}}),_c('featured-specs-filters-access-button',{attrs:{"item":item}}),_c('banners-access-button',{attrs:{"item":item}}),_c('action-delete',{attrs:{"action-url":("product-categories/" + (item.id))}})]}},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_c('children-list',{attrs:{"parent_category_id":item.id}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }