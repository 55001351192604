var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"data-list-container"},[_c('div',{staticClass:"mb-5"},[_c('new-button',{staticClass:"mr-4",attrs:{"to":{name:'generate-product'}}},[_vm._v("Adauga Produs")]),_c('new-button',{attrs:{"to":{name:'products-category', params:{categoryId:_vm.currentCategoryId}}}},[_vm._v(" Vezi Produsele ")])],1),_c('vx-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":true,"items":_vm.paginatedItems.data},on:{"update:sort-by":_vm.updateSortField,"update:sort-desc":_vm.updateSortType},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(header.filterable === true)?[_c('filter-input',{attrs:{"filter-name":header.value,"placeholder":"Filter..."},on:{"filter":_vm.updateFilter}})]:_vm._e()],2)}),0)]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(category){return _c('div',{key:category.id},[_c('i',{staticClass:"fal fa-check"}),_vm._v(" "+_vm._s(category.name)+" ")])})}},{key:"item.is_generating",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.is_generating),expression:"item.is_generating"}]},[_c('i',{staticClass:"fas fa-spinner fa-spin fa-2x text-warning"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.is_generating),expression:"!item.is_generating"}]},[_c('i',{staticClass:"fas fa-check-circle fa-2x text-success"})])]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.brand.name)+" ")]}},{key:"item.base_product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.base_product.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.paginatedItems.last_page,"total-visible":5,"value":_vm.currentPage,"color":"#76bd43"},on:{"input":_vm.changePage}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }