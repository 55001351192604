<template>
  <action-edit
    :action-url="`feed-connectors/general/integrations/${item.id}/upload`"
    :item="item"
    button-color="black"
    button-icon="fal fa-upload"
    button-label="Incarca XLSX"
    :button-loading="item.is_processing"
    @cancel="clearFile" @saved="clearFile"
  >
    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <base64-file-uploader
          ref="upload"
          v-model="form.file"
          accept=".xlsx"
          label="Upload Feed"
          prepend-inner-icon="fal fa-cloud-upload"
        />
      </div>
    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    Base64FileUploader,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  },
  methods: {
    clearFile () {
      this.$refs.upload.clearFile()
    }
  }

}

</script>
