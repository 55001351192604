<template>
  <div>
    Agentul a salvat configuratia cu ID-ul:
    <router-link :to="routeLink">
      {{ values.id }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProductsAdded',
  props: {
    values: {}
  },
  computed: {
    routeLink () {
      return {
        name: 'edit-generate-product',
        params: {
          categoryId: this.values.category_id,
          productConfigId: this.values.id
        }
      }
    }
  }
}
</script>
