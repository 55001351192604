<template>
  <section class="data-list-container">

    <div class="mb-5">
      <new-button :to="{name:'generate-product'}" class="mr-4">Adauga Produs</new-button>
      <new-button :to="{name:'products-category', params:{categoryId:currentCategoryId}}">
        Vezi Produsele
      </new-button>
    </div>

    <vx-card>
      <v-data-table
        :headers="headers"
        :hide-default-footer="true"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType"
      >

        <template v-slot:header="{props}">

          <tr>
            <th v-for="header in props.headers" :key="header.text">
              <template v-if="header.filterable === true">
                <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter"/>
              </template>
            </th>
          </tr>
        </template>


        <template v-slot:item.categories="{item}">
          <div v-for="category in item.categories" :key="category.id">
            <i class="fal fa-check"></i> {{ category.name }}
          </div>
        </template>

        <template v-slot:item.is_generating="{item}">

          <span v-show="item.is_generating">
            <i class="fas fa-spinner fa-spin fa-2x text-warning"></i>
          </span>
          <span v-show="!item.is_generating">
            <i class="fas fa-check-circle fa-2x text-success"></i>
          </span>
        </template>

        <template v-slot:item.brand="{item}">
          {{ item.brand.name }}
        </template>

        <template v-slot:item.base_product="{item}">
          {{ item.base_product.name }}
        </template>

        <!--        <template v-slot:item.discount="{item}">-->
        <!--          <div class="flex">-->
        <!--            <discount-changer field="discount_end_users" :item="item" :original-discount="item.discount_end_users" class="mr-1"-->
        <!--                              label="E.U."/>-->
        <!--            <discount-changer field="discount_resellers" :item="item" :original-discount="item.discount_resellers" class="ml-1"-->
        <!--                              label="Distr."/>-->
        <!--          </div>-->
        <!--        </template>-->

        <template v-slot:item.actions="{item}">
          <!--          <edit-button @click="editItem(item)"></edit-button>-->
          <!--          <delete-button @delete="deleteItem(item)"></delete-button>-->
        </template>


        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>

      </v-data-table>
    </vx-card>


  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FilterInput from '@/components/general-form/FilterInput'
import DiscountChanger from '@/views/Products/components/DiscountChanger'

export default {
  name: 'ProductConfigurators',
  components: {DiscountChanger, FilterInput, DeleteButton, EditButton, NewButton},
  data () {
    return {
      headers: [
        {text: 'id', value: 'id', sortable: false, width: '60'},
        {text: 'Discount', value: 'discount', sortable: false, width: '150'},
        {text: 'Categories', value: 'categories', sortable: false},
        {text: 'Brand', value: 'brand', sortable: false},
        {text: 'Produs de baza', value: 'base_product', sortable: false},
        {text: '', value: 'actions', width: '150', sortable: false}
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    currentCategoryId () {
      return this.$route.params.categoryId
    },
    paginatedItems () {
      return this.$store.getters['products/paginatedData']
    },
    currentPage () {
      return this.$store.getters['products/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage (newPage) {
      this.$store.dispatch('products/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('products/deleteFilter', filterName)
      } else {
        this.$store.dispatch('products/addFilter', {filterName, searchValue})
      }
    },
    loadItems () {
      this.$store.dispatch('products/loadItems')
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('products/changeSort', newValue)
      }
    }
  },
  created () {
    this.$store.dispatch('products/addFilter', {filterName: 'category', searchValue: this.currentCategoryId})
  }
}
</script>
