<template>
  <v-card flat>
    <v-btn v-if="!activated" color="primary" small @click="activateReseller">
      Confirma si trimite mail de confirmare
    </v-btn>

    <v-btn v-else-if="!client.email_confirmed" color="primary" small @click="resendConfirmationEmail">
      Trimite mail de confirmare
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'ResellerConfirmationButtons',
  props: {
    reseller: {
      required: true
    }
  },
  computed: {
    activated () {
      if (this.client) {
        return this.client.activated
      }
      return ''
    }
  },
  methods: {
    resendConfirmationEmail () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`clients/resellers/${this.reseller.id}/resend-activation`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    activateReseller () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`clients/resellers/${this.reseller.id}/activate`)
        .then(() => {
          this.loadItems()
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
