<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="290px"
      min-width="auto"
      offset-y
      transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="computedDateFormatted"
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :hide-details="hideDetails"
          :label="label"
          :outlined="outlined"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>

export default {
  name: 'DatePicker',
  props: {
    value: {},
    label: {
      type: String,
      default: 'Data estimata receptie'
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disableInternalFormatDate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    computedDateFormatted () {
      if (this.disableInternalFormatDate) {
        return this.date
      } else {
        return this.formatDate(this.date)
      }
    },
    date: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.replace(' 00:00:00', '').split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>
