<template>
  <div>
    Grupa clientului a fost schimbata <span v-html="changeVerbose"></span> de catre agentul <strong>{{ values.user.full_name }}</strong>
  </div>
</template>

<script>
export default {
  props: {
    values: {}
  },
  computed: {
    changeVerbose () {
      if (this.values.type === 'company_to_reseller') {
        return 'din <strong>Persoana Juridica</strong> in <strong>Distribuitor</strong> '
      }
      if (this.values.type === 'private_to_it') {
        return 'din <strong>Persoana Fizica</strong> in <strong>IT-ist</strong> '
      }
    }
  }
}
</script>
