<template>
  <span>
    <template v-if="includeAll || (!includeAll && county)">
      <v-autocomplete
        v-model="selectOption"
        :hide-details="hideDetails"
        :items="cities"
        :label="label"
        :dense="dense"
        :loading="loading"
        :outlined="outlined"
        autocomplete="fax"
        hide-no-data
        item-text="name"
        item-value="id"
      ></v-autocomplete>
    </template>
    <template v-else>
      <v-text-field :dense="dense" :disabled="true" :hide-details="hideDetails" :outlined="outlined" disabled
                    value="Va rugam selectati un judet inainte"/>
    </template>
  </span>
</template>

<script>
export default {
  name: 'CitySelector',
  props: {
    value: {},
    county: {},
    includeAll: {
      default: false
    },
    label: {
      default: 'Judet'
    },
    dense: {
      default: false,
      type: Boolean
    },
    hideDetails: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      loading: false
    }
  },
  computed: {
    cities () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    county: {
      immediate: true,
      handler (newCounty, oldCounty) {
        if (newCounty !== oldCounty) {
          if (typeof oldCounty !== 'undefined') {
            this.selectOption = ''
          }
          this.loadItems()
        }
      }
    }
  },
  methods: {
    loadItems () {
      const countyFilter = this.includeAll ? '' : `&filter[county]=${this.county}`
      this.$http.get(`/location/cities?order=name&page_size=all${countyFilter}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  }
}
</script>
