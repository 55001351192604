<template>
  <action-edit
    :action-url="`product-warranties/${item.id}`"
    :item="item"
    button-label="Editeaza ExtraGarantie"
  >

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.number_of_months" label="Numar de luni"/>
        <error-messages :errors="errors.number_of_months"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.percent_value" label="Procent"/>
        <error-messages :errors="errors.percent_value"></error-messages>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
