<template>
  <v-sheet>

    <cit-data-table
      :appends="[
        'no_of_opened_emails',
        'no_of_error_sent_emails',
        'no_of_successfully_sent_emails',
        'no_of_sales_made',
        'value_of_sales_made',
        'post_send_preview',
        'target_audience'
      ]"
      :headers="headers"
      :includes="[

      ]"
      disable-sort
      resource-path="newsletter"
      show-expand
    >
      <template v-slot:actions="{}">
        <new-button :to="{name:'send-newsletter'}" class="mr-5">Adauga newsletter nou</new-button>
      </template>

      <template v-slot:expanded-item="{item}">
        <strong>Id Produse:</strong> {{ item.products.join(', ') }}
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <v-btn :href="item.post_send_preview" target="_blank" text>Previzualizeaza</v-btn>
        <!--        <clone-button :to="{name:'send-newsletter', query:{cloned:item.id}}"/>-->
      </template>

      <template v-slot:[`item.batch_createdAt`]="{item}">
        <template v-if="item.batch">
          {{ item.batch.createdAt | moment('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-else>
          <div v-for="(batch, index) in item.newsletter_batches" :key="index">
            {{ batch.batch.createdAt | moment('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
      </template>


      <template v-slot:[`item.batch_finishedAt`]="{item}">

        <template v-if="item.batch">
          {{ item.batch.finishedAt | moment('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-else>
          <div v-for="(batch, index) in item.newsletter_batches" :key="index">
            {{ batch.batch.finishedAt | moment('YYYY-MM-DD HH:mm') }}
          </div>
        </template>

      </template>

      <template v-slot:[`item.batch_progress`]="{item}">

        <template v-if="item.batch">
          {{ item.batch.progress }} %
        </template>
        <template v-else>
          <div v-for="(batch, index) in item.newsletter_batches" :key="index">
            {{ batch.batch.progress }} %
          </div>
        </template>

      </template>

      <template v-slot:[`item.batch_totalJobs`]="{item}">
        <template v-if="item.batch">
          {{ item.batch.totalJobs }}
        </template>
        <template v-else>

          <div v-for="(batch, index) in item.newsletter_batches" :key="index">
            {{ batch.batch.totalJobs }}
          </div>
        </template>

      </template>

      <template v-slot:[`item.batch_name`]="{item}">
        <div v-for="(batch, index) in item.newsletter_batches" :key="index">
          {{ batch.batch_name }}
        </div>
      </template>


    </cit-data-table>

  </v-sheet>
</template>

<script>

import CitDataTable from '@/components/layout/CitDataTable'
import NewButton from '@/components/general-form/NewButton'
import CloneButton from '@/components/general-form/CloneButton'

export default {
  name: 'NewsletterList',
  components: {
    CloneButton,
    NewButton,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Se Trimite',
          value: 'is_sending',
          display: 'boolean',
          align: 'center',
          width: '50'
        },
        {
          text: 'Subiect',
          value: 'subject',
          width: '250'
        },
        {
          text: 'Audienta',
          value: 'target_audience',
          width: '150'
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          width: '150'
        },
        {
          text: 'Programat',
          value: 'sending_at',
          display: 'date',
          width: '150'
        },
        {
          text: 'Nr. Emailuri de trimis',
          value: 'total_number_of_emails',
          width: '100',
          align: 'center',
          sortable: false
        },
        {
          text: 'Nr. Emailuri Trimise cu success',
          value: 'no_of_successfully_sent_emails',
          width: '100',
          align: 'center',
          sortable: false
        },
        {
          text: 'Nr. Emailuri cu eroare',
          value: 'no_of_error_sent_emails',
          width: '100',
          align: 'center',
          sortable: false
        },
        {
          text: 'Nr. Emailuri Deschise',
          value: 'no_of_opened_emails',
          width: '100',
          align: 'center',
          sortable: false
        },
        {
          text: 'Nr. Vanzari realizate',
          value: 'no_of_sales_made',
          width: '100',
          align: 'center',
          sortable: false
        },
        {
          text: 'Valoare Vanzari realizate',
          value: 'value_of_sales_made',
          width: '100',
          align: 'center',
          sortable: false
        }
      ]
    }
  }
}
</script>
