<template>
  <v-sheet class="my-4">
    <div v-if="cart.items.length">

    <v-data-table
      :disable-pagination="true"
      :headers="productTableHeaders"
      :hide-default-footer="true"
      :items="cart.items"
    >
      <template v-slot:item.featured_image="{item}">
        <img :src="item.item.featured_image.src" alt="" width="50">
      </template>

      <template v-slot:footer>

        <table class="ml-auto text-right ">
          <tr>
            <td>Total Produse:</td>
            <td><strong>{{ cart.item_total }}</strong>Lei</td>
          </tr>
          <tr>
            <td>TVA:</td>
            <td><strong>{{ cart.total_vat }}</strong>Lei</td>
          </tr>
          <tr>
            <td>Taxa verde:</td>
            <td><strong>{{ cart.total_green_tax }}</strong>Lei</td>
          </tr>
          <tr>
            <td>Total Cos:</td>
            <td><strong>{{ cart.total }}</strong>Lei</td>
          </tr>
        </table>

      </template>
    </v-data-table>

    </div>
    <div v-else>
      Nu exista produse in cos.
    </div>
    <v-divider/>

    <v-simple-table v-if="cart.cart_logs.length">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Istoric de activitate
          </th>
          <th class="text-left">
            Data
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(log, index) in cart.cart_logs" :key="index">
          <td>{{ log.action }}</td>
          <td>{{ log.created_at | moment('HH:mm in DD.MM.YYYY') }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-sheet>
</template>

<script>
export default {
  name: 'CartDetails',
  props: {
    cart: {
      required: true
    }
  },
  data () {
    return {
      productTableHeaders: [
        {
          align: 'start',
          text: 'Imagine',
          value: 'featured_image',
          sortable: false
        },
        {
          align: 'start',
          text: 'Produs',
          value: 'item.full_name',
          sortable: false
        },
        {
          align: 'start',
          text: 'Pret',
          value: 'total_item',
          sortable: false
        },
        {
          align: 'start',
          text: 'Taxa verde',
          value: 'total_green_tax',
          sortable: false
        },
        {
          align: 'start',
          text: 'Cantitate',
          value: 'quantity',
          sortable: false
        },
        {
          align: 'start',
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    }
  }
}
</script>

