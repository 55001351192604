<template>
  <v-autocomplete
    v-model="selectOption"
    :items="products"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :return-object="returnObject"
    :search-input.sync="search"
    class="filter-select text-sm"
    clearable
    hide-details
    hide-no-data
    item-text="internal_name"
    item-value="id"
    :disabled="disabled"
    no-filter
  >
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseProductSelector',
  props: {
    categoryId: {
      default: false
    },
    value: {},
    multiple: {
      default: false
    },
    label: {
      default: 'Produs de baza'
    },
    returnObject: {
      default: false
    },
    disabled:{
      default:false
    }
  },
  data () {
    return {
      paginatedProducts: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    products () {
      return this.paginatedProducts.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    },
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loading = true
        this.loadItems(this.search)
      }
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      let extraQuery = ''
      if (this.categoryId !== false) {
        extraQuery = `&filter[category]=${this.categoryId}`
      }
      this.$http.get(`/base-products?filter[internal_name]=${search}&filter[id]=${this.selectOption}${extraQuery}`)
        .then(({data}) => {
          this.paginatedProducts = data
          this.loading = false
        })
    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
