<template>
  <div>
    Comanda cu numarul <strong>
    <router-link :to="orderRouteLink">#{{ values.id }}</router-link>
  </strong> a fost marcata ca fiind finalizata
  </div>
</template>

<script>
export default {
  props: {
    values: {}
  },
  computed: {
    orderRouteLink () {
      return {
        name: 'orders-page',
        params: {
          id: this.values.id
        }
      }
    }
  }
}
</script>
