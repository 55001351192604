<template>
  <div>
    Agentul a confirmat comanda
    <router-link :to="routeLink">#{{ values.id }}</router-link>
    plasata de clientul <strong>
    <router-link :to="clientRouteLink">{{ values.customer.client_display_name }}</router-link>
  </strong>
  </div>
</template>

<script>
export default {
  name: 'ClientOrderFinished',
  props: {
    values: {}
  },
  computed: {
    routeLink () {
      return {
        name: 'orders-page',
        params: {
          id: this.values.id
        }
      }
    },
    clientRouteLink () {
      return {
        name: 'view-client',
        params: {
          id: this.values.customer.id
        }
      }
    }
  }
}
</script>
