<template>
  <section class="data-list-container">

    <v-card>
      <v-card-text>
        <v-data-table
          :expanded.sync="expanded"
          :headers="headers"
          :hide-default-footer="true"
          :items="paginatedItems"
          :server-items-length="totalItems"
          :single-expand="true"
          show-expand
          @update:sort-by="updateSortField"
          @update:sort-desc="updateSortType"
        >


          <template v-slot:item.customer="{item}">
            {{ item.first_name }} {{ item.last_name }}
          </template>

          <template v-slot:item.order="{item}">
              <template v-if="item.order">
                <router-link :to="{name:'orders-page', params:{id:item.id}}">
                  {{ item.order.order_number }}
                </router-link>
              </template>

          </template>


          <template v-slot:item.rating_agent="{item}">
            <template v-if="item.has_not_interacted_with_agent">
              Fara interactiune
            </template>
            <template v-else>
              {{ item.rating_agent }}
            </template>
          </template>

          <template v-slot:item.actions="{item}">
            <delete-button @delete="deleteItem(item)"></delete-button>
            <div v-if="!item.approved_at" class="inline-block mr-2">
              <vs-tooltip text="Accepta">
                <v-btn color="success" icon x-small @click="approveItem(item)">
                  <v-icon>
                    fal fa-thumbs-up
                  </v-icon>
                </v-btn>
              </vs-tooltip>
            </div>


            <div class="inline-block">
              <div v-show="item.featured">
                <vs-tooltip text="Elimina din favorite">
                  <v-btn color="yellow" icon x-small @click="toggleFeatured(item)">
                    <v-icon>
                      fa fa-star
                    </v-icon>
                  </v-btn>
                </vs-tooltip>
              </div>
            </div>

            <div class="inline-block">
              <div v-show="!item.featured">

                <vs-tooltip text="Adauga la favorite">
                  <v-btn color="yellow" icon x-small @click="toggleFeatured(item)">
                    <v-icon>
                      fal fa-star
                    </v-icon>
                  </v-btn>
                </vs-tooltip>
              </div>
            </div>

          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div>
                <strong>Nume:</strong> {{ item.first_name }} {{ item.last_name }}
              </div>
              <div>
                <strong>Telefon:</strong> {{ item.phone }}
              </div>
              <div>
                <div>
                  <strong>Review:</strong>
                </div>
                {{ item.body }}
              </div>

            </td>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-pagination
          :length="pagination.last_page"
          :total-visible="5"
          :value="currentPage"
          color="#76bd43"
          @input="changePage"
        ></v-pagination>
      </v-card-actions>
    </v-card>


  </section>
</template>

<script>
import FilteredListMixin from '@/Mixins/FilteredListMixin'
import DeleteButton from '@/components/general-form/DeleteButton'

export default {
  name: 'TestimonialsList',
  components: {
    DeleteButton
  },
  mixins: [FilteredListMixin],
  data () {
    return {
      expanded: [],
      headers: [
        {
          text: 'Comanda',
          value: 'order',
          width: '150px'
        },
        {
          text: 'Client',
          value: 'customer',
          width: '150px'
        },
        {
          text: 'Nota Finala',
          value: 'score',
          width: '100px',
          align: 'center'
        },
        {
          text: 'Nota Comanda',
          value: 'rating',
          width: '100px',
          align: 'center'
        },
        {
          text: 'Nota Agent',
          value: 'rating_agent',
          width: '100px',
          align: 'center'
        },
        {
          text: '',
          value: 'actions',
          width: '150',
          sortable: false
        }
      ],

      storeName: 'testimonials'

    }
  },
  methods: {
    approveItem (item) {
      this.$store.dispatch('startLoading')
      this.$http.patch(`/testimonials/${item.id}`)
        .then(() => {
          this.$store.dispatch('testimonials/loadItems')
          this.$store.dispatch('loadNavigation')
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la aprobare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    toggleFeatured (item) {
      this.$store.dispatch('startLoading')
      this.$http.put(`/testimonials/${item.id}`)
        .then(() => {
          this.$store.dispatch('testimonials/loadItems')
          this.$store.dispatch('loadNavigation')
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la modificare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    deleteItem (item) {
      this.$store.dispatch(`testimonials/deleteItem`, item.id)
        .then(() => {
          this.$store.dispatch('loadNavigation')
        })
    }
  }

}
</script>
