var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"350","nudge-left":"50","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"text-filter"},[_c('v-text-field',_vm._g(_vm._b({staticClass:"text-sm date-select",attrs:{"label":_vm.placeholder,"clearable":"","dense":"","hide-details":"","outlined":"","readonly":"","single-line":""},model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-sheet',{staticClass:"p-8"},[_c('v-row',[_c('v-col',[_vm._v(" Cauta clientul ... ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"filter-select text-sm",attrs:{"items":_vm.customerFirstNameItems,"loading":_vm.customerFirstNameLoading,"search-input":_vm.customerFirstNameSearch,"clearable":"","dense":"","hide-details":"","item-text":"first_name","item-value":"id","label":"in functie de Nume","outlined":""},on:{"update:searchInput":function($event){_vm.customerFirstNameSearch=$event},"update:search-input":function($event){_vm.customerFirstNameSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex flex-column"},[_vm._v(" "+_vm._s(item.full_name)+" "),(item.company)?_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v("("+_vm._s(item.company.name)+")")]):_vm._e()])]}}]),model:{value:(_vm.customerFirstNameSelection),callback:function ($$v) {_vm.customerFirstNameSelection=$$v},expression:"customerFirstNameSelection"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"filter-select text-sm",attrs:{"items":_vm.customerLastNameItems,"loading":_vm.customerLastNameLoading,"search-input":_vm.customerLastNameSearch,"clearable":"","dense":"","hide-details":"","item-text":"last_name","item-value":"id","label":"in functie de Prenume","outlined":""},on:{"update:searchInput":function($event){_vm.customerLastNameSearch=$event},"update:search-input":function($event){_vm.customerLastNameSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex flex-column"},[_vm._v(" "+_vm._s(item.full_name)+" "),(item.company)?_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v("("+_vm._s(item.company.name)+")")]):_vm._e()])]}}]),model:{value:(_vm.customerLastNameSelection),callback:function ($$v) {_vm.customerLastNameSelection=$$v},expression:"customerLastNameSelection"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"filter-select text-sm",attrs:{"items":_vm.customerCompanyItems,"loading":_vm.customerCompanyLoading,"search-input":_vm.customerCompanySearch,"clearable":"","dense":"","hide-details":"","item-text":"company.name","item-value":"id","label":"in functie de Numele companiei","outlined":""},on:{"update:searchInput":function($event){_vm.customerCompanySearch=$event},"update:search-input":function($event){_vm.customerCompanySearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex flex-column"},[_vm._v(" "+_vm._s(item.full_name)+" "),(item.company)?_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v("("+_vm._s(item.company.name)+")")]):_vm._e()])]}}]),model:{value:(_vm.customerCompanySelection),callback:function ($$v) {_vm.customerCompanySelection=$$v},expression:"customerCompanySelection"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }