<template>
  <v-tabs class="general-settings" left vertical>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-user-tie</v-icon>
      <span class="ml-2 font-weight-bold">Agent implicit</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-address-book</v-icon>
      <span class="ml-2 font-weight-bold">Date de contact</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-cog</v-icon>
      <span class="ml-2 font-weight-bold">Scoring</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-user-circle</v-icon>
      <span class="ml-2 font-weight-bold">Pagina de conturi</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-bells</v-icon>
      <span class="ml-2 font-weight-bold">Notificari stoc</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-badge-percent</v-icon>
      <span class="ml-2 font-weight-bold">Black Friday</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <v-icon>fal fa-birthday-cake</v-icon>
      <span class="ml-2 font-weight-bold">Reducere zi nastere</span>
    </v-tab>


    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <default-agent/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <contact-data/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <client-scoring/>
      </v-card>
    </v-tab-item>

    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <account-text/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <notifications-agent/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <black-friday/>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <general-sale-settings/>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import NotificationsAgent from '@/views/Settings/GeneralSettings/components/NotificationsAgent'
import DefaultAgent from '@/views/Settings/GeneralSettings/components/defaultAgent'
import ContactData from '@/views/Settings/GeneralSettings/components/contactData'
import ClientScoring from '@/views/Settings/GeneralSettings/components/clientScoring'
import PageBody from '@/views/PageManagement/components/PageBody'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AccountText from '@/views/Settings/GeneralSettings/components/accountText'
import BlackFriday from '@/views/Settings/GeneralSettings/components/BlackFriday'
import GeneralSaleSettings from '@/views/Settings/GeneralSettings/components/GeneralSaleSettings'

export default {
  name: 'index',
  components: {
    GeneralSaleSettings,
    BlackFriday,
    NotificationsAgent,
    AccountText,
    ErrorMessages,
    PageBody,
    ClientScoring,
    ContactData,
    DefaultAgent
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.general-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
