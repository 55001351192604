<template>
  <action-add-new
    :action-url="`product-categories/${categoryId}/banners`"
    button-label="Adauga Banner nou"
  >
    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.link" dense hide-details label="Link" outlined/>
        <error-messages :errors="errors.link"></error-messages>
      </div>

      <div class="mb-4">
        <v-select
          v-model="form.position"
          :items="[
            {
              label: 'Top',
              value:'top'
            },
            {
              label: 'Sidebar',
              value:'sidebar'
            },
          ]"
          item-text="label"
          item-value="value"
          label="Pozitie"
          dense
          outlined
          hide-details
        />
        <error-messages :errors="errors.position"></error-messages>
      </div>

      <div class="mb-4">
        <image-uploader ref="banner" v-model="form.image_id" label-idle="Incarca bannerul"/>
        <error-messages :errors="errors.image_id"/>
      </div>

    </template>
  </action-add-new>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ImageUploader from '@/components/general-form/ImageUploader'
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'

export default {
  name: 'AddNewCategoryBanner',
  components: {
    ActionAddNew,
    ImageUploader,
    ErrorMessages
  },
  props: {
    categoryId: {
      required: true
    }
  }
}
</script>
