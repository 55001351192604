<template>
  <div>
    Agentul a finalizat comanda <strong><router-link :to="routeLink">#{{ values.order.id }}</router-link> </strong> plasata de clientul
    <router-link :to="clientRouteLink">{{ values.customer.client_display_name }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    values: {}
  },
  computed: {
    routeLink () {
      return {
        name: 'orders-page',
        params: {
          id: this.values.order.id
        }
      }
    },
    clientRouteLink () {
      return {
        name: 'view-client',
        params: {
          id: this.values.customer.id
        }
      }
    }
  }
}
</script>
