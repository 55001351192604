<template>
  <v-sheet>
    <cit-data-table
      ref="table"
      :appends="['status_details']"
      :fixed-filters="extraFilters"
      :headers="headers"
      :includes="[
        'voucherLog',
        'items',
        'products',
        ]"
      :resource-path="resourcePath"
    >
      <template v-slot:actions>
        <slot name="actions"></slot>
      </template>


      <template v-slot:[`item.actions`]="{item}">
        <view-button :to="{name:'orders-page', params:{id:item.id}}" target="_self"/>
      </template>

      <template v-slot:[`item.product_categories_in_order`]="{item}">
        <order-category-list :order="item"/>
      </template>

      <template v-slot:[`item.products`]="{item}">

        <div v-for="(product,index) in item.products" :key="index" class="product-name mb-2">
          <v-tooltip left max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                - <strong>{{ product.product_id }} :</strong> {{ product.full_name }}
              </span>
            </template>
            <span>{{ product.product_id }} : {{ product.full_name }}</span>
          </v-tooltip>
        </div>

      </template>


      <template v-slot:item.status_name="{item}">
        <template v-if="item.status === 'paid'">
          <inline-confirm-payment-button :order="item"/>
        </template>
        <template v-else>
          <v-chip :color="item.status_details.color">{{ item.status_details.name }}</v-chip>
          <div v-if="item.status === 'payment_confirmed'">
            {{ item.confirmed_at | moment('DD-MM-YYYY HH:mm:ss') }}
          </div>
        </template>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import ViewButton from '@/components/general-form/ViewButton'
import OrderStatusFilter from '@/views/Orders/components/Filters/OrderStatusFilter'
import ConfirmPaymentButton from '@/views/Orders/components/ConfirmPaymentButton'
import CitDataTable from '@/components/layout/CitDataTable'
import InlineConfirmPaymentButton from '@/views/Orders/components/InlineConfirmPaymentButton'
import OrderCategoryList from '@/views/Orders/components/OrderCategoryList'

export default {
  name: 'OrderList',
  components: {
    OrderCategoryList,
    InlineConfirmPaymentButton,
    CitDataTable,
    ConfirmPaymentButton,
    OrderStatusFilter,
    ViewButton
  },
  data () {
    return {
      headers: [
        {
          align: 'start',
          text: 'Nr. Comanda',
          value: 'order_number',
          sortable: false,
          filterType: 'text',
          width: '200'
        },
        {
          align: 'start',
          text: 'Data comenzii',
          value: 'created_at',
          display: 'date',
          sortable: true,
          filterType: 'date-range',
          width: '200'
        },
        {
          align: 'start',
          text: 'Client',
          value: 'customer.client_display_name',
          sortable: false,
          filterType: 'customer',
          filterName: 'customer_id',
          width: '200'
        },
        {
          align: 'center',
          text: 'Valoare',
          value: 'total',
          sortable: false,
          width: '100'
        },
        {
          align: 'center',
          text: 'Status',
          value: 'status_name',
          sortable: false,
          width: '200'
        },
        {
          align: 'center',
          text: 'Apartine Voucher',
          value: 'voucher_log',
          display: 'checkmarks',
          sortable: false,
          width: '90'
        },
        {
          align: 'center',
          text: 'Puncte Voucher',
          value: 'voucher_log.points',
          sortable: false,
          width: '90'
        },
        {
          text: 'Categorii de produse',
          value: 'product_categories_in_order',
          sortable: false,
          filterType: 'main-product-categories',
          filterName: 'product_categories',
          width: '200'
        },
        {
          text: 'Produse',
          value: 'products',
          filterType: 'products',
          filterName: 'products',
          sortable: false,
          width: '300'
        },
        {
          align: 'center',
          text: 'Agent',
          value: 'customer.agent.full_name',
          sortable: false,
          filterType: 'agent',
          type: 'agent',
          width: '200'
        }
      ]
    }
  },
  props: {
    extraFilters: {},
    resourcePath: {
      required: true,
      type: String
    }
  },
  created () {
    if (!this.$store.getters['agents/isLoaded']) {
      this.$store.dispatch('agents/loadItems')
    }
  }
}
</script>

<style>
.product-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 280px;
}
</style>
