<template>
  <div>
    <div>
      O discutie din data de <strong>{{ values.contacted_at | moment('DD-MM-YYYY HH:mm:ss') }}</strong> cu clientul a fost inregistrata
    </div>
    <div>
      {{ values.message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: {}
  }
}
</script>
