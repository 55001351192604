<template>
  <v-sheet>
    <v-row>
      <v-col cols="3">
        <v-navigation-drawer permanent>
          <v-list-item>
            <v-list-item-content>
              Task Management
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="$store.getters['auth/currentUser'].is_admin"/>

          <v-list nav>
            <v-subheader>Filtre</v-subheader>
            <v-list-item :to="{name:'task-manager', params:{filter:'all'}}" link>
              <v-list-item-icon>
                <v-icon>fal fa-layer-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Toate</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name:'task-manager', params:{filter:'completed'}}" link>
              <v-list-item-icon>
                <v-icon>fal fa-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Terminate</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name:'task-manager', params:{filter:'overdue'}}" link>
              <v-list-item-icon>
                <v-icon>fal fa-exclamation-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Depasite</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-navigation-drawer>
      </v-col>
      <v-col cols="9">
        <cit-data-table
          :appends="['is_completed']"
          :fixed-filters="fixedFilters"
          :headers="headers"
          :includes="['agent']"
          resource-path="task-management/tasks"
          show-expand
        >

          <template v-slot:actions="{}">
            <create-form v-if="$store.getters['auth/currentUser'].is_admin"/>
            <create-for-all v-if="$store.getters['auth/currentUser'].is_admin"/>
          </template>

          <template v-slot:expanded-item="{item}">
            {{ item.task }}
          </template>


          <template v-slot:[`item.actions`]="{item}">
            <approve v-if="!item.is_completed" :task="item"/>
          </template>

        </cit-data-table>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import CreateForm from './Create'
import CreateForAll from './CreateForAll'
import Approve from './components/Approve'
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  components: {
    CitDataTable,
    Approve,
    CreateForm,
    CreateForAll
  },
  data () {
    return {
      headers: [
        {
          align: 'center',
          text: 'Finalizat',
          value: 'finished_at',
          display: 'boolean',
          width: '50'
        },
        {
          text: 'Task',
          value: 'title',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          text: 'Termen',
          value: 'deadline_at',
          sortable: true,
          display: 'date-no-time',
          width: '200'
        },
        {
          text: 'Agent',
          value: 'agent.full_name',
          sortable: false,
          filterType: 'agent',
          filterName: 'agent_id',
          width: '200'
        }
      ]
    }
  },
  computed: {
    fixedFilters () {
      const filters = {
        filter: this.$route.params.filter
      }
      if (!this.$store.getters['auth/currentUser'].is_admin) {
        filters.agent_id = this.$store.getters['auth/currentUser'].id
      }
      return filters
    }
  },
  created () {
    this.$store.dispatch('agents/loadItems')
  }
}

</script>
