var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{ref:"table",attrs:{"appends":[
      'is_orders_last_month_higher',
      'orders_last_month',
      'orders_total',
      'activated',
      'last_order_at',
      'email_confirmed',
      'birthday'
    ],"headers":_vm.headers,"includes":['website'],"resource-path":"clients/resellers"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [_c('create-form'),_c('v-spacer'),_c('import-customers',{attrs:{"resource-path":"clients/resellers"}}),_c('export-client-list',{attrs:{"type":"resellers"}})]}},{key:"filter.interest_lists",fn:function(ref){
    var header = ref.header;
    var updateFilter = ref.updateFilter;
return [_c('interest-list-filter',{attrs:{"filter-name":"interest_lists"},on:{"filter":updateFilter}})]}},{key:"item.company.caen",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [(value)?[_vm._v(_vm._s(value))]:[_vm._v("-")],(item.company.caen_explained)?_c('help-icon',{attrs:{"text":item.company.caen_explained,"position":"bottom"}}):_vm._e()]}},{key:"item.last_contacted_at",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('last-contacted-at',{attrs:{"client":item,"last_contacted_at":item.last_contacted_at},on:{"changed":_vm.reload}})]}},{key:"item.agent",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('client-agent-replacer',{attrs:{"agent":value,"client":item},on:{"changed":_vm.reload}})]}},{key:"item.orders_last_month",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [(item.is_orders_last_month_higher)?_c('v-chip',{attrs:{"color":"#60a629","dark":""}},[_vm._v(" "+_vm._s(item.orders_last_month)+" ")]):_c('v-chip',{attrs:{"color":"#fb4739","dark":""}},[_vm._v(" "+_vm._s(item.orders_last_month)+" ")])]}},{key:"item.orders_total",fn:function(ref){
    var value = ref.value;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.birthday",fn:function(ref){
    var item = ref.item;
return [_c('birthday-updated',{attrs:{"item":item}})]}},{key:"item.discount",fn:function(ref){
    var item = ref.item;
return [_c('client-discount-select',{attrs:{"client":item},on:{"changed":_vm.reload}})]}},{key:"item.score",fn:function(ref){
    var item = ref.item;
return [_c('client-add-extra-points',{attrs:{"customer":item},on:{"changed":_vm.reload}})]}},{key:"item.interest_lists",fn:function(ref){
    var item = ref.item;
return [_c('client-interest-select',{attrs:{"client":item}})]}},{key:"item.confirmed",fn:function(ref){
    var item = ref.item;
return [(!item.activated)?[_c('span',{staticClass:"text-danger"},[_vm._v("Neactivat")]),_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.activateReseller(item)}}},[_vm._v("Confirma si trimite mail de confirmare")])]:(!item.email_confirmed)?[_c('span',{staticClass:"text-warning"},[_vm._v(" Neconfirmat ")]),_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.resendConfirmationEmail(item)}}},[_vm._v("Retrimite mail de confirmare")])]:[_c('span',{staticClass:"text-success"},[_vm._v(" Confirmat ")])]]}},{key:"item.meta_data.wished_products_from_cit",fn:function(ref){
    var item = ref.item;
return [_c('client-meta-wished-products-from-cit',{attrs:{"customer":item},on:{"change":_vm.reload}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('view-button',{attrs:{"to":{name:'view-client', params:{id:item.id}},"target":"_self"}}),_c('action-delete',{attrs:{"action-url":("clients/" + (item.id))}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }