export default {
  getItemForEdit (state) {
    return state.editItem
  },
  getClonedItem (state) {
    if (state.clonedItem && Object.keys(state.clonedItem).length === 0 && state.clonedItem.constructor === Object) {
      return false
    }
    return state.clonedItem
  }
}
