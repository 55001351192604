var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"appends":[
      'customer.display_name'
    ],"fixed-filters":_vm.fixedFilters,"headers":_vm.headers,"disable-actions":"","resource-path":"form-submissions","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('inline-edit-activity-status',{attrs:{"item":item,"value":value}})]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('v-simple-table',{staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.fields),function(field,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(field.field_label))]),_c('td',{domProps:{"innerHTML":_vm._s(field.field_value)}})])}),0)]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }