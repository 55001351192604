<template>
  <base-locations-management subtitle="Tari">
    <cit-data-table
      :headers="headers"
      resource-path="location/countries"
    >

      <template v-slot:actions>
        <create-form/>
      </template>

      <template v-slot:item.actions="{item}">
        <edit-form :item="item"/>
      </template>

    </cit-data-table>
  </base-locations-management>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import CreateForm from './Create'
import EditForm from './Edit'
import BaseLocationsManagement from '@/views/Settings/LocationsManagement/BaseLocationsManagement'

export default {
  components: {
    BaseLocationsManagement,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '80',
          sortable: true
        },
        {
          text: 'Activ',
          value: 'is_active',
          display: 'boolean',
          width: '80',
          sortable: true
        },
        {
          text: 'Cod',
          value: 'code',
          width: '80',
          sortable: true
        },
        {
          text: 'Nume',
          value: 'name',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          align: 'center',
          width: '140',
          sortable: true
        }
      ]
    }
  }
}
</script>
