<template>
  <list-layout>
    <cit-data-table
      :appends="[
        'product_name',
        'product_price',
        'product_stock',
      ]"
      :headers="headers"
      :resource-path="`feed-connectors/general/integrations/${integrationId}`"
    >

      <template v-slot:actions>

      </template>

      <template v-slot:item.actions="{item}">
        <edit-images v-if="!item.published_at" :item="item"/>
        <edit-description v-if="!item.published_at" :item="item"/>
        <save v-if="!item.published_at" :item="item"/>

      </template>

    </cit-data-table>
  </list-layout>
</template>

<script>
import ListLayout from '@/views/FeedConnectors/Generals/ListLayout'
import CitDataTable from '@/components/layout/CitDataTable'
import EditDescription from '@/views/FeedConnectors/Generals/Integrations/Rows/EditDescription'
import Save from '@/views/FeedConnectors/Generals/Integrations/Rows/Save'
import EditImages from './EditImages'

export default {
  components: {
    EditImages,
    Save,
    EditDescription,
    ListLayout,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID Produs',
          value: 'product_id',
          width: '65'
        },
        {
          text: 'Nume produs',
          value: 'product_name',
          filterType: 'text'
        },
        {
          text: 'Pret',
          value: 'product_price',
          sortable: false
        },
        {
          text: 'Cantitate',
          value: 'product_stock',
          sortable: false
        },
        {
          align: 'center',
          text: 'Publicat',
          value: 'published_at',
          width: '50',
          display: 'boolean',
          sortable: false
        }
      ]
    }
  },
  computed: {
    categoryId () {
      return this.$route.params.categoryId
    },
    integrationId () {
      return this.$route.params.integrationId
    }
  }
}
</script>
