<template>
  <action-add-new action-url="product-brands" button-label="Adauga Brand nou" @created="created">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <div v-if="form.logo">
          <v-img
            :src="form.logo"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>
        <base64-file-uploader
          v-model="form.logo_file"
          label="Logo"
        />
      </div>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    ErrorMessages,
    ActionAddNew,
    Base64FileUploader
  }
}
</script>
