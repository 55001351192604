export default {
  loggedIn (state) {
    return !!state.token
  },
  token (state) {
    return state.token
  },
  currentUser (state) {
    return state.user
  }
}
