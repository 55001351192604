export default {

  isSidebarActive (state) {
    return state.isSidebarActive
  },
  currentActiveSidebarComponent (state) {
    return state.currentActiveSidebarComponent
  },
  sidebarTitle (state) {
    return state.sidebarTitle
  },
  confirmationButton (state) {
    return state.sidebarConfirmationButton
  }

}
