
export default {
  openDialog ({commit}, item) {
    commit('OPEN_DIALOG')
    commit('SET_EDIT_ITEM', JSON.parse(JSON.stringify(item)))
  },
  closeDialog ({commit}) {
    commit('CLOSE_DIALOG')
  }
}
