<template>
  <v-sheet>
    <cit-data-table
      :fixed-filters="{
        customer:customerId
      }"
      :headers="headers"
      :includes="[
        'customer',
        'customer.agent',
        'customer.company'
      ]"
      hide-filters
      resource-path="analytics"
    >
      <template v-slot:item.referrer_page="{item}">
        <a :href="item.full_referrer_page" target="_blank">{{ item.referrer_page }}</a>
      </template>

      <template v-slot:item.accessed_page="{item}">
        <a :href="item.full_accessed_page" target="_blank">{{ item.accessed_page }}</a>
      </template>

      <template v-slot:item.time="{item}">
        {{ item.time | toSeconds }}
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'

export default {
  components: {
    CitDataTable
  },
  props: {
    customerId: {
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Sursa',
          value: 'referrer_page',
          sortable: false,
          width: 300
        },
        {
          text: 'Url',
          value: 'accessed_page',
          sortable: false,
          width: 300
        },
        {
          text: 'Durata',
          value: 'time',
          sortable: true,
          width: '80'
        },
        {
          text: 'Data',
          value: 'created_at',
          display: 'date',
          sortable: true,
          width: '250'
        }

      ]
    }
  },
  filters: {
    toSeconds (value) {
      value = parseInt(value)
      return `${Math.floor(value / 1000)}s`
    }
  }
}
</script>
