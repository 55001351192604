<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      resource-path="newsletter/subscribers"
    >
      <template v-slot:item.actions="{item}">
        <edit :item="item"/>
      </template>
    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import Edit from '@/views/Newsletter/Subscribers/Edit'

export default {
  components: {
    Edit,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email',
          filterType: 'text',
          sortable: false
        },
        {
          text: 'Email Status',
          value: 'email_validation_status',
          filterType: 'simple-select',
          filterOptions: [
            {
              text: 'Deliverable',
              value: 'deliverable'
            },
            {
              text: 'Undeliverable',
              value: 'undeliverable'
            },
            {
              text: 'Unknown',
              value: 'unknown'
            },
            {
              text: 'Do Not Send',
              value: 'do_not_send'
            },
            {
              text: 'Catch All',
              value: 'catch_all'
            }
          ],
          sortable: false
        },
        {
          text: 'Data',
          display: 'date',
          filterType: 'date-range',
          sortable: true,
          value: 'created_at'
        }
      ]
    }
  }

}
</script>
