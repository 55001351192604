<template>
  <span>
    <span v-if="!client.activated" class="text-danger">Neactivat</span>
    <span v-else-if="!client.email_confirmed" class="text-warning">Neconfirmat</span>
    <span v-else class="text-success">Confirmat</span>
  </span>
</template>

<script>
export default {
  name: 'ResellerStatus',
  props: {
    client: {
      required: true
    }
  }
}
</script>