<template>
  <div>
    <v-row>
      <v-col cols="9">
        <product-selector action-instead-of-selected return-object @input="addNewProduct" outlined dense/>
      </v-col>
      <v-col align="center" align-self="center" cols="3">
        <bulk-add-products-by-id ref="bulkAddProducts" @added="AddMultipleProducts"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :hide-default-footer="true"
          :items="products"
        >
          <template v-slot:body="props">
            <draggable v-model="products" tag="tbody">
              <tr v-for="(item, index) in products" :key="index" class="custom-tr children cursor-move">
                <td style="width: 65px">
                  <v-icon>fal fa-bars</v-icon>
                </td>
                <td><strong>{{ item.product_id }}</strong></td>
                <td>{{ item.full_name }}</td>
                <td>{{ item.price }} lei</td>
                <td>{{ item.discount_end_users }}</td>
                <td>{{ item.discount_resellers }}</td>
                <td class="text-right" style="width: 100px">
                  <delete-button without-confirmation @delete="deleteItem(item)"></delete-button>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import DeleteButton from '@/components/general-form/DeleteButton'
import ProductSelector from '../../../components/autocompletes/ProductSelector'
import BulkAddProductsById from './BulkAddProductsById'

export default {
  name: 'ProductsSelect',
  components: {
    BulkAddProductsById,
    ProductSelector,
    DeleteButton,
    draggable
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'handler',
          width: '65',
          sortable: false
        },
        {
          text: 'Id',
          value: 'id',
          sortable: false,
          width: '5%'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Price',
          value: 'price',
          sortable: false,
          width: '10%'
        },
        {
          text: 'Discount End Users',
          value: 'discount_end_users',
          sortable: false,
          width: '10%'
        },
        {
          text: 'Discount Resellers',
          value: 'discount_resellers',
          sortable: false,
          width: '10%'
        },
        {
          text: '',
          value: 'actions',
          width: '100',
          sortable: false,
          align: 'right'
        }
      ],
      products: []
    }
  },
  methods: {
    deleteItem (item) {
      const index = this.products.map(x => {
        return x.id
      }).indexOf(item.id)

      this.products.splice(index, 1)

    },
    addNewProduct (item) {
      this.products.push(item)
    },
    AddMultipleProducts (items) {
      this.products = this.products.concat(items)
    },
    addBulkProductsById (ids) {
      const idList = ids.join(',')
      this.$refs.bulkAddProducts.manualAdd(idList)
    }
  },
  watch: {
    products: {
      deep: true,
      handler (newValue) {
        this.$emit('input', newValue.map(x => {
          return x.id
        }))
      }
    }
  }

}
</script>
