<template>
  <action-add-new
    :action-url="`clients/reminders`"
    :static-form-values="{customer_id:customerId}"
    button-label="Adauga Reminder"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>

      <div class="mb-4">
        <v-textarea v-model="form.body" dense hide-details label="Continut" outlined/>
        <error-messages :errors="errors.body"/>
      </div>

      <div class="mb-4">
        <date-picker v-model="form.reminder_at" dense hide-details label="Selecteaza data reminderului"
                     outlined/>
        <error-messages :errors="errors.reminder_at"/>
      </div>

      <div class="mb-4">
        <v-switch v-model="form.send_to_customer" :false-value="false" :true-value="true" label="Trimite si la client"/>
        <error-messages :errors="errors.send_to_customer"/>
      </div>

      <error-messages :errors="errors.customer_id"/>
      <error-messages :errors="errors.agent_id"/>

    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import DatePicker from '@/components/general-form/DatePicker'

export default {
  components: {
    DatePicker,
    ErrorMessages,
    ActionAddNew
  },
  props: {
    customerId: {
      required: true
    }
  }
}
</script>
