<template>
  <vs-tooltip text="Editeaza produsul de baza">
    <v-btn icon @click="openEditor">
      <v-icon x-small>fal fa-edit</v-icon>
    </v-btn>
  </vs-tooltip>
</template>

<script>
export default {
  name: 'BaseProductEditorTrigger',
  props: {
    baseProductId: {
      required: true
    }
  },
  methods:{
    openEditor () {
      this.$store.dispatch('startLoading')
      this.$http.get(`base-products/${this.baseProductId}`)
      .then(({data}) => {
        this.$store.dispatch('baseProductEditor/openDialog', data)
        this.$store.dispatch('stopLoading')
      })
    }
  }
}
</script>
