<template>
  <action-edit
    :action-url="`feed-connectors/general/integrations/row/${item.id}/description`"
    :item="item"
    button-color="black"
    button-icon="fal fa-images"
    button-label="Adauga Imagini"
    dialog-width="700px"
  >
    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <div>
          <div class="flex">
            <span class="image-upload-label mr-2">
              Imagine principala
            </span>
            <help-icon text="Imaginea care va fi afisata in toate listarile si prima din galerie"/>
          </div>
          <bp-product-images-uploader
            ref="featuredImageUploader"
            :existing="existingFeaturedImage"
            single
            text="Alegeti imaginea principala"
            @input="featuredImageUpdated"
          />
          <error-messages :errors="errors.featured_image"/>
        </div>
        <div class="mt-5">
          <div class="flex">
                      <span class="image-upload-label mr-2">
                        Imagini Galerie
                      </span>
            <help-icon text="Nu este necesara incarcarea imaginii principale. Aceasta va fi preluata automat"/>
          </div>
          <bp-product-images-uploader
            ref="imageUploader"
            :existing="existingGallery"
            text="Alegeti imagini pentru galeria produsului"
            @input="galleryUpdated"
          />
          <error-messages :errors="errors.images"/>
        </div>
      </div>
    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from "../../../../../components/general-form/ErrorMessages";
import ActionEdit from "../../../../../components/layout/ActionButtons/ActionEdit";
import BpProductImagesUploader
  from "../../../../ProductManagement/BaseProductManager/components/BpProductImagesUploader";
import HelpIcon from "../../../../../components/HelpIcon";

export default {
  name: "EditImages",
  components: {HelpIcon, BpProductImagesUploader, ActionEdit, ErrorMessages},
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      existingFeaturedImage: '',
      existingGallery: [],
    }
  },
  methods: {
    galleryUpdated(items) {
      let images = []
      if (items.length > 0) {
        images = items.filter(item => (!!item.id || !!item.upload.data)).map((item) => {
          if (item.id) {
            return item.id
          } else if (item.upload.data) {
            return item.upload.data.id
          }
        })
      }
      this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
        key: 'images',
        value: images
      })
    },
    featuredImageUpdated(item) {
      let featured_image = false
      if (item.length > 0) {
        if (item[0].id) {
          featured_image = item[0].id
        } else if (item[0].upload.data) {
          featured_image = item[0].upload.data.id
        }
      }
      this.$store.commit('baseProductEditor/SET_ITEM_PROP', {
        key: 'featured_image',
        value: featured_image
      })
    },
  }
}
</script>
