<template>
  <action-edit
    :action-url="`feed-connectors/nod/category-importer/${item.id}`"
    :item="item"
    button-label="Editeaza Integrarea"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.multiplier" dense hide-details label="Adaos" outlined type="number"/>
        <error-messages :errors="errors.multiplier"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.delivery_period" dense hide-details label="Termen de livrare" outlined/>
        <error-messages :errors="errors.delivery_period"/>
      </div>

    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
