<template>
  <action-edit
    :action-url="`feeds/${item.id}`"
    :item="item"
    button-label="Editeaza Feedul"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" dense hide-details label="Descriere" outlined/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.discount" dense hide-details label="Discount" outlined type="number"/>
        <error-messages :errors="errors.discount"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.ignored_products_id" dense hide-details label="Produse ignorate (ex. 123,521,23)"
                      outlined type="number"/>
        <error-messages :errors="errors.ignored_products_id"></error-messages>
      </div>

      <div class="mb-4">
        <all-product-category-selector v-model="form.categories" label="Catoegorii" multiple/>
        <error-messages :errors="errors.categories"></error-messages>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import AllProductCategorySelector from '@/components/autocompletes/AllProductCategorySelector'

export default {
  components: {
    AllProductCategorySelector,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
