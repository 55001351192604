var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('cit-data-table',{attrs:{"appends":[
      'no_of_opened_emails',
      'no_of_error_sent_emails',
      'no_of_successfully_sent_emails',
      'no_of_sales_made',
      'value_of_sales_made',
      'post_send_preview',
      'target_audience'
    ],"headers":_vm.headers,"includes":[

    ],"disable-sort":"","resource-path":"newsletter","show-expand":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [_c('new-button',{staticClass:"mr-5",attrs:{"to":{name:'send-newsletter'}}},[_vm._v("Adauga newsletter nou")])]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('strong',[_vm._v("Id Produse:")]),_vm._v(" "+_vm._s(item.products.join(', '))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"href":item.post_send_preview,"target":"_blank","text":""}},[_vm._v("Previzualizeaza")])]}},{key:"item.batch_createdAt",fn:function(ref){
    var item = ref.item;
return [(item.batch)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.batch.createdAt,'YYYY-MM-DD HH:mm'))+" ")]:_vm._l((item.newsletter_batches),function(batch,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("moment")(batch.batch.createdAt,'YYYY-MM-DD HH:mm'))+" ")])})]}},{key:"item.batch_finishedAt",fn:function(ref){
    var item = ref.item;
return [(item.batch)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.batch.finishedAt,'YYYY-MM-DD HH:mm'))+" ")]:_vm._l((item.newsletter_batches),function(batch,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("moment")(batch.batch.finishedAt,'YYYY-MM-DD HH:mm'))+" ")])})]}},{key:"item.batch_progress",fn:function(ref){
    var item = ref.item;
return [(item.batch)?[_vm._v(" "+_vm._s(item.batch.progress)+" % ")]:_vm._l((item.newsletter_batches),function(batch,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(batch.batch.progress)+" % ")])})]}},{key:"item.batch_totalJobs",fn:function(ref){
    var item = ref.item;
return [(item.batch)?[_vm._v(" "+_vm._s(item.batch.totalJobs)+" ")]:_vm._l((item.newsletter_batches),function(batch,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(batch.batch.totalJobs)+" ")])})]}},{key:"item.batch_name",fn:function(ref){
    var item = ref.item;
return _vm._l((item.newsletter_batches),function(batch,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(batch.batch_name)+" ")])})}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }