<template>
  <v-card>
    <template slot="progress">
      <v-progress-linear
        absolute
        bottom
        color="#01a7e3"
        height="5"
        indeterminate
        rounded
      ></v-progress-linear>
    </template>
    <v-card-text>
      <div class="mb-4">
        <v-switch
          v-model="website.is_active"
          dense
          hint="Activeaza integrarea cu website-ul acestui reseller. Daca nu este bifata website-ul nu se va putea connecta la cit grup"
          label="Activare website Reseller"
          persistent-hint
        ></v-switch>
        <error-messages :errors="errors.site_name"/>
      </div>
      <div class="mb-4">
        <v-text-field
          v-model="website.site_url"
          dense
          hint="ex. https://reseller-website.com"
          label="Link Website"
          outlined
          persistent-hint
        />
        <error-messages :errors="errors.site_url"/>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="loading" :loading="loading" color="primary" @click="save">Salveaza</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'ResellerWebsite',
  components: {ErrorMessages},
  props: {
    client: {
      required: true
    }
  },
  data () {
    return {
      website: {
        is_active: false,
        site_url: ''
      },
      errors: {},
      loading: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http
        .get(`clients/${this.client.id}/website`)
        .then(({data}) => {
          if (data.site_url) {
            this.website.site_url = data.site_url
          }
          if (data.is_active) {
            this.website.is_active = data.is_active
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: 'Eroare',
            text: error.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    save () {
      this.loading = true
      this.errors = {}
      this.$http
        .put(`clients/${this.client.id}/website`, this.website)
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.$vs.notify({
            title: 'Eroare',
            text: error.response.data.message,
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
