import axios from '@/axios'

export default {
  deleteItem ({dispatch}, id) {
    dispatch('startLoading', null, {root: true})
    return axios.delete(`/base-products/${  id}`)
      .then(() => {
        dispatch('loadItems')
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la stergere. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })
      .finally(() => {
        dispatch('stopLoading', null, {root: true})
      })
  },
  storeItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/base-products', itemData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  },
  updateItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post(`/base-products/${  itemData.id}`, itemData.form, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  }
}
